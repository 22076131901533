import { AnyAaaaRecord } from "dns";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { ALL_ROUTES } from "../../Routes/all_routes";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "../API";
import { EndorsementDTO } from "../DTO/EndorsementDTO";

const get_endorsement_list = (
  url: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};
const get_endorsement_of_client = (
  url: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};
const get_endorsement_of_policy = (
  url: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};
const get_dropdown_data = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

const member_detail = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

const create_endorsement = (
  url: string,
  dto: EndorsementDTO,
  onSuccess: any,
  onError: any
) => {
  PostAPI.call(url, dto, onSuccess, {}, onError);
};

const updateAddress = (
  url: any,
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PutAPI.call(url, dto, onSuccess, {}, onError);
};

const get_endorsement_details_view = (
  url: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

const endorsement_individual_edit = (
  url: any,
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PutAPI.call(url, dto, onSuccess, {}, onError);
};

const upoad_attachment_endorsement = (
  url: string,
  dto: EndorsementDTO,
  onSuccess: any,
  onError: any
) => {
  PostAPI.call(url, dto, onSuccess, {}, onError);
};

const endorsement_status_change = (
  url: string,
  dto: {
    _id: string;
    status: string;
  },
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(url, dto, onSuccess, {}, onError);
};

const endorsement_notes = (
  dto: any,
  onSuccess: any,
  onError: any,
  user: string
) => {
  PostAPI.call(
    URL_CONSTANTS.endorsement_notes(user),
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_status_endorsement, onSuccess, {}, onError);
};
const get_Hr_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_status_hr, onSuccess, {}, onError);
};

const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.endorsement_status_change}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const hr_update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.hr_endorsement_status_change}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const getDetaisForMemberNomineeAdditionDeletion = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  memberId: string,
  policyId: string,
  user: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.getDetaisForMemberNomineeAdditionDeletion(
      user
    )}${id}&policyId=${policyId}&memberId=${memberId}`,
    onSuccess,
    {},
    onError
  );
};

const get_broker_path = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType: string
) => {
  PostAPI.call(
    `${URL_CONSTANTS.broker_path(userType)}`,
    dto,
    onSuccess,
    onError
  );
};

const get_member_dependent = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, onError);
};

const get_Endorsement_supp_docs = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType: string
) => {
  PostAPI.call(
    `${URL_CONSTANTS.endorsement_suppoting_docs_post(userType)}`,
    dto,
    onSuccess,
    onError
  );
};

const get_addition_deletion_notes = (
  url: string,
  onSuccess: (res: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

const delete_attachment_endorsement = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  endorsementId: any,
  attachmentId: any,
  userType: string
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_attachment_endorsement(userType)}`,
    { endorsementId, attachmentId },
    onSuccess,
    {},
    onError
  );
};
const updateEndorsementMassmodification = (
  dto: any,
  onSuccess: (data: any) => void,
  {},
  onError: (err: any) => void
) => {
  PostAPI.call(
    URL_CONSTANTS.updateEndorsementMassmodification,
    dto,
    onSuccess,
    {},
    onError
  );
};

export const ENDORSEMENT_SERVICES = {
  get_endorsement_list,
  get_dropdown_data,
  member_detail,
  create_endorsement,
  get_endorsement_details_view,
  endorsement_individual_edit,
  upoad_attachment_endorsement,
  endorsement_status_change,
  endorsement_notes,
  get_status,
  get_Hr_status,
  update_status,
  hr_update_status,
  getDetaisForMemberNomineeAdditionDeletion,
  get_broker_path,
  updateAddress,
  get_member_dependent,
  get_Endorsement_supp_docs,
  get_addition_deletion_notes,
  delete_attachment_endorsement,
  updateEndorsementMassmodification,
  get_endorsement_of_policy,
  get_endorsement_of_client,
};
