import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../../API";
import { assignPolicyDTO } from "../../DTO/Employee/EmployeePoliciesDTO";

const get_employee_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: any,
  skip: any,
  startDate: any,
  endDate: any,
  searchBarFilter: any
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employee_list}limit=${limit}&skip=${skip}&startDate=${startDate}&endDate=${endDate}&searchValue=${searchBarFilter}`,
    onSuccess,
    {},
    onError
  );
};

// detail view page api
const get_employee_detail_view = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

// Header data for the employee page api
const get_header_tag = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.get_employee_header,
    onSuccess,
    {},
    onError
  );
};

// get employe detail view information
const employee_detail_view_layout = (
  id: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employee_detail_layout}${id}`,
    onSuccess,
    onError
  );
};

const get_assigned_policies = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
  empId: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_assigned_policies}${empId}`,
    onSuccess,
    {},
    onError
  );
};

const get_dependent_assigned_policies = (
  onSuccess: any,
  onError: any,
  empId: string,
  depId: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_dependent_assigned_policies}employeeId=${empId}&memberId=${depId}`,
    onSuccess,
    {},
    onError
  );
};

const get_assignable_policies = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
  clientId: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employee_assignable_policies}${clientId}`,
    onSuccess,
    {},
    onError
  );
};




const get_client_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(EMPLOYER_URL_CONSTANTS.get_client_list, onSuccess, onError);
};

// get nominee list
const get_nominee_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(`${EMPLOYER_URL_CONSTANTS.get_nominee}${id}`, onSuccess, onError);
};

const get_dep_designation_salary = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  type: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employer_designation_department_salary_list}?code=${type}`,
    onSuccess,
    onError
  );
};

const get_dropdown_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employees_by_clientId}clientId=${clientId}`,
    onSuccess,
    onError
  );
};

// get client location list
const get_location_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_client_location_list}${id}`,
    onSuccess,
    onError
  );
};

const get_employee_assigned_policies_for_dependants = (
  onSucces: any,
  onError: any,
  empId: string
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_employee_assigned_policies}${empId}`,
    onSucces,
    {},
    onError
  );
};

const create_employee = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    EMPLOYER_URL_CONSTANTS.create_employee,
    dto,
    onSuccess,
    {},
    onError
  );
};

// update status
const update_status = (dto: any, onSuccess: any, onError: any) => {
  PatchAPI.call(
    `${EMPLOYER_URL_CONSTANTS.update_status}`,
    dto,
    onSuccess,
    {},
    onError
  );
};


const get_endorsement_list = (
  onSucces: any,
  onError: any,
  empId: string,
  limit:number,
  skip:number,
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_endorsement_list(limit,skip,empId)}`,
    onSucces,
    {},
    onError
  );
};
const get_claims_list = (
  onSucces: any,
  onError: any,
  empId: string,
  limit:number,
  skip:number,
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.get_claims_list(limit,skip,empId)}`,
    onSucces,
    {},
    onError
  );
};
const save_assigned_policies = (
  dto: assignPolicyDTO,
  onSuccess: any,
  onError: any,
  empId: string
) => {
  PatchAPI.call(
    `${EMPLOYER_URL_CONSTANTS.assign_employee_policies(empId)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_member_to_policy = (
  onSuccess: any,
  onError: any,
  dto: any,
  empId: string,
  code: number,
  isDep: string,
) => {
  PostAPI.call(
    `${EMPLOYER_URL_CONSTANTS.add_member_to_policy(empId, code, isDep)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};


export const EMPLOYER_EMPLOYEE_SERVICES = {
  get_employee_list,
  get_employee_detail_view,
  get_header_tag,
  employee_detail_view_layout,
  get_assigned_policies,
  get_dependent_assigned_policies,
  get_assignable_policies,
  get_client_list,
  get_nominee_list,
  get_dep_designation_salary,
  get_dropdown_data,
  get_location_list,
  get_employee_assigned_policies_for_dependants,
  create_employee,
  get_endorsement_list,
  update_status,
  get_claims_list,
  save_assigned_policies,
  add_member_to_policy,
};
