import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_client_list,
  get_country_list,
  get_state_list,
  validateEmail,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../../formLayout.scss";
import ChangePassword from "../../../../../Desktop/Broker/Settings/GeneralSettings/PersonalSettings/ChangePassword/ChangePassword";
function EditCreateUsers() {
  const navigate = useNavigate();
  const [usersRole, setUsersRole] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const [id, setId] = useState<string>("");
  const [searchList, setSearchList] = useState<boolean>(false);
  const [priorityStatus, setPriorityStatus] = useState(13);
  const [changePassword, setChangePassword] = useState(false);
  const [editUser, setEditUser] = useState<any>({
    name: "",
    email: "",
    gender: "",
    dob: "",
    role: "",
    branch: "",
    department: "",
    clientName: "",
    addressDetails: {
      state: "",
      country: "",
      city: "",
      pinCode: "",
      mobileNumber: "",
      address1: "",
      address2: "",
    },
    client: { label: "", id: "" },
    warnName: false,
    warnEmail: false,
    warnRole: false,
    warnBranch: false,
    warnDepartment: false,
  });
  const [userEditPermission, setuserEditPermission] = useState(false);
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const [department_data, setDepartment_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const [branch_data, setBranch_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [clientListData, setClientListData] = useState<
    Array<{
      checkbox_status: boolean;
      label: string;
      id: string;
    }>
  >([]);

  const selectClient_data = [
    { label: "Custom", value: "Custom" },
    { label: "All", value: "All" },
  ];
  // for scrolling
  const userInformation = useRef(null);
  const basics = useRef(null);
  const clientInfo = useRef(null);
  const personalDetails = useRef(null);
  const addressDetails = useRef(null);

  useEffect(() => {
    client_list();
    user_role_data();
    get_status();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    setuserEditPermission(
      !EMPLOYER
        ? true
        : EMPLOYER.Users.remove === undefined
        ? false
        : EMPLOYER.Users.remove
    );
    setUpdatePermission(
      EMPLOYER?.Users?.edit === undefined ? false : EMPLOYER?.Users?.edit
    );
  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status(onSuccess, onError);
  };

  useEffect(() => {
    get_state_list(selectedCountry, (cb: any) => {
      setStateData(cb);
    });

    get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
      setCityData(cb);
    });
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    user_data(id ?? "");
    get_Dropdown_branch_list(EMPLOYER_URL_CONSTANTS.get_employer_location_list);
    get_Dropdown_branch_list(
      EMPLOYER_URL_CONSTANTS.get_employer_department_list
    );
  }, []);

  const get_Dropdown_branch_list = (url: string) => {
    const onSuccess = (res: any) => {
      if (url === EMPLOYER_URL_CONSTANTS.get_employer_location_list) {
        let data = res.data.data?.map((item: any) => ({
          label: item._id,
          value: item.name,
        }));
        setBranch_data(data);
      } else if (url === EMPLOYER_URL_CONSTANTS.get_employer_department_list) {
        let data = res.data.data[0]?.value?.map((item: any) => ({
          label: item._id,
          value: item.label,
        }));
        setDepartment_data(data);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      url,
      onSuccess,
      onError
    );
  };

  const searchHandler = (event: any) => {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = clientListData.filter((el) => {
        let searchValue = el.label.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    setClientListData(displayedContacts);
    setSearchList(true);
  };

  const selectClientsFromList = (index: number, check_status: boolean) => {
    let data = clientListData;

    data[index].checkbox_status = check_status;

    setClientListData(data);
  };

  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      setEditUser(res.data.data[0]);
      setClientListData(res.data.data[0].clientName);
      setPriorityStatus(res.data.data[0]?.employer_active_status);
    };
    const onError = (err: any) => {
      console.log(".......,,", err);
    };
    ACCESS_LEVEL_SERVICES.employer_user_data_by_id(id, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let data = { ...editUser };
    let key = attrName[1];
    let section = attrName.pop();
    if (mainState === "editUser") {
      if (section === "addressDetails") {
        data = {
          ...data,
          addressDetails: {
            ...data.addressDetails,
            [key]: value,
            [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
          },
        };
        if (key === "country") {
          data = {
            ...data,
            addressDetails: {
              ...data.addressDetails,
              city: "",
              state: "",
              pinCode: "",
            },
          };
        }
        if (key === "state") {
          data = {
            ...data,
            addressDetails: { ...data.addressDetails, city: "", pinCode: "" },
          };
        }
        if (key === "city") {
          data = {
            ...data,
            addressDetails: { ...data.addressDetails, pinCode: "" },
          };
        }
        setEditUser(data);
      } else {
        setEditUser({
          ...editUser,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      }

      if (key === "role" || key === "branch" || key === "Department") {
        setEditUser({
          ...editUser,
          [key]: value.label,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      }
      if (key === "country") {
        setSelectedCountry(value);
      } else if (key === "state") {
        setSelectedState(value);
      }
    } else {
      const onSuccess = (res: any) => {};
      const onError = (err: any) => {
        console.log("ppp", err);
      };
      attrName(value);
      let params = {
        status: value,
      };
      ACCESS_LEVEL_SERVICES.employer_update_status(
        params,
        onSuccess,
        onError,
        id
      );
    }
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const client_list = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data?.forEach((e: any) => {
        data.push({
          ...e,
          checkbox_status: false,
        });
      });
      setClientListData(data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };

    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  // user role data
  const user_role_data = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { value: data.id, label: data.label };
      });
      setUsersRole(statusData);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ACCESS_LEVEL_SERVICES.get_user_role_data_employer("", onSuccess, onError);
  };

  const detail_form_submit = () => {
    let data: any = editUser;
    data.warnName = editUser?.name.length === 0 ? true : false;
    data.warnEmail = !validateEmail(editUser.email);
    data.warnRole = editUser.role === "" ? true : false;
    data.warnBranch = editUser.branch === "" ? true : false;
    data.warnDepartment = editUser.Department === "" ? true : false;
    setEditUser({ ...data });
    if (
      data.warnName === false &&
      data.warnEmail === false &&
      data.warnRole === false &&
      data.warnDepartment === false
    ) {
      let params = {
        _id: id,
        name: editUser.name,
        email: editUser.email,
        role: editUser?.employer_role?._id
          ? editUser?.employer_role._id
          : editUser.rollName,
        branch: editUser.branch?.id ? editUser.branch?.id : editUser.branch,
        Department: editUser.department?.id
          ? editUser.department?.id
          : editUser.department,
        client: "All",
        gender: editUser.gender.value ? editUser.gender.value : editUser.gender,
        dob: editUser.dob,
        // mobileNumber:editUser?.mobileNumber,
        clientName: [],
        addressDetails: {
          state:
            editUser.addressDetails.state?.label ||
            editUser.addressDetails.state,
          country:
            editUser.addressDetails.country?.label ||
            editUser.addressDetails.country,
          city:
            editUser.addressDetails.city?.label || editUser.addressDetails.city,
          pinCode: editUser.addressDetails.pinCode,
          mobileNumber: editUser.addressDetails.mobileNumber,
          address1: editUser.addressDetails.address1,
          address2: editUser.addressDetails.address2,
        },
      };
      const onSuccess = (res: any) => {
        navigate(-1);
      };
      const onError = (err: any) => {
        console.log("false", err);
      };
      ACCESS_LEVEL_SERVICES.employer_user_update_data_by_id(
        params,
        onSuccess,
        onError
      );
    }
  };

  return (
    <Box className="layoutWrapper">
      <ChangePassword
        attrName={setChangePassword}
        open_status={changePassword}
        value_update={updateMasterState}
        update_api_url={ACCESS_LEVEL_SERVICES.update_password_employer}
        employerUserId={id}
      />

      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Edit - {editUser.name}</h3>
          </div>
        </Grid>
        <Grid
          xs={6}
          textAlign={"right"}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          {updatePermission && (
            <Button
              className="updatePassword mr-4"
              onClick={() => {
                setChangePassword(true);
              }}
            >
              <AdminPanelSettingsIcon /> Update Password
            </Button>
          )}

          {/* Status Dropdown */}

          <div id={`_${priorityStatus.toString()}`}>
            <div
              className="status_dropdown"
              id={`_${priorityStatus.toString()}`}
            >
              {priorityStatus === 13 ? (
                <CheckCircleIcon id={`_${priorityStatus?.toString()}`} />
              ) : priorityStatus === 14 ? (
                <HighlightOffIcon id={`_${priorityStatus?.toString()}`} />
              ) : null}

              <SelectDropdown
                class_name="inputFieldd"
                title=""
                value={priorityStatus}
                attrName={setPriorityStatus}
                value_update={updateMasterState}
                dropdown_data={prioritystatusData}
                warn_status={false}
                disabled={!userEditPermission}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "userInformation"
                    ? "active sectionLink userInformation"
                    : "sectionLink userInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(userInformation);
                  SetSectionStatus("userInformation");
                }}
              >
                <span> User Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basics"
                        ? "inner_link active basics"
                        : "inner_link  basics"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(basics);
                      setSubSection("basics");
                    }}
                  >
                    Basic Info
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "personalDetails"
                        ? "inner_link active personalDetails"
                        : "inner_link  personalDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(personalDetails);
                      setSubSection("personalDetails");
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "addressDetails"
                        ? "inner_link active addressDetails"
                        : "inner_link  addressDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(addressDetails);
                      setSubSection("addressDetails");
                    }}
                  >
                    Address Details
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={
                      subSection === "clientInfo"
                        ? "inner_link active clientInfo"
                        : "inner_link clientInfo"
                    }
                    onClick={() => {
                      setSubSection("clientInfo");
                      handleClicks(subSection);
                      scrollToSection(clientInfo);
                    }}
                  >
                    Client Info
                  </Link>
                </li> */}
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={userInformation}>
                <div ref={basics}>
                  <div className="sectionTitle">
                    <h4>User Information</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Basic Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Email"}
                        value={editUser?.email}
                        attrName={["editUser", "email"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnEmail}
                      />
                    </Grid>

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Role"
                        value={editUser?.employer_role?.roleName}
                        attrName={["editUser", "role"]}
                        value_update={updateMasterState}
                        data={usersRole}
                        warn_status={editUser?.warnRole}
                        error_message="Please select Role"
                      />
                    </Grid>
                    <Grid sm={4} md={4} lg={3} xl={2}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Branch"
                        value={editUser.branch}
                        attrName={["editUser", "branch"]}
                        value_update={updateMasterState}
                        dropdown_data={branch_data}
                        warn_status={editUser.warnBranch}
                        error_messg="Please select Branch"
                        info_status={true}
                        info_message="This is the branch list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Department"
                        value={editUser.department}
                        attrName={["editUser", "Department"]}
                        value_update={updateMasterState}
                        dropdown_data={department_data}
                        warn_status={editUser.warnDepartment}
                        error_messg="Please select department"
                        info_status={true}
                        info_message="This is the department list that you have created in the Organisation Settings"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div ref={personalDetails}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Personal Details</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <RKTextField
                        validation_type="name"
                        class_name="inputField"
                        title={"Full Name"}
                        value={editUser?.name}
                        attrName={["editUser", "name"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnName}
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Gender"
                        value={editUser?.gender}
                        attrName={["editUser", "gender", "label_field"]}
                        value_update={updateMasterState}
                        data={gender_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <DatePicker
                        class_name="inputField"
                        title="Date of Birth"
                        value={editUser?.dob}
                        attrName={["editUser", "dob"]}
                        value_update={updateMasterState}
                        error_message="Please enter Date of Birth"
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Mobile Number"}
                        value={editUser?.addressDetails?.mobileNumber}
                        attrName={[
                          "editUser",
                          "mobileNumber",
                          "addressDetails",
                        ]}
                        value_update={updateMasterState}
                        warn_status={false}
                        error_messg="Mobile Number should be valid"
                        validation_type="numeric"
                        charcterlimit={10}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div ref={addressDetails}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Address Details</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={6} lg={6} xl={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Street Name"}
                        value={editUser?.addressDetails?.address1}
                        attrName={["editUser", "address1", "addressDetails"]}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={6} md={6} lg={6} xl={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Area Name"}
                        value={editUser?.addressDetails?.address2}
                        attrName={["editUser", "address2", "addressDetails"]}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Country"
                        value={editUser?.addressDetails?.country}
                        attrName={[
                          "editUser",
                          "country",
                          "label_field",
                          "addressDetails",
                        ]}
                        value_update={updateMasterState}
                        data={countryData}
                        warn_status={false}
                        error_message="Please select country"
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="State"
                        value={editUser?.addressDetails?.state}
                        attrName={[
                          "editUser",
                          "state",
                          "label_field",
                          "addressDetails",
                        ]}
                        value_update={updateMasterState}
                        data={stateData}
                        warn_status={false}
                        error_message="Please select state"
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="City"
                        value={editUser?.addressDetails?.city}
                        attrName={[
                          "editUser",
                          "city",
                          "label_field",
                          "addressDetails",
                        ]}
                        value_update={updateMasterState}
                        data={cityData}
                        warn_status={false}
                        error_message="Please select city"
                      />
                    </Grid>
                    <Grid sm={3} md={3} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Pincode"}
                        value={editUser?.addressDetails?.pinCode}
                        attrName={["editUser", "pinCode", "addressDetails"]}
                        value_update={updateMasterState}
                        warn_status={false}
                        error_messg="Pincode should be valid"
                        validation_type="numeric"
                        charcterlimit={6}
                      />
                    </Grid>
                  </Grid>
                </div>
                {/* <div ref={clientInfo}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Client Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Select Client"
                        value={selectClient}
                        attrName={setSelectClient}
                        value_update={updateMasterState}
                        data={selectClient_data}
                        warn_status={false}
                        error_message="Please select client"
                      />
                    </Grid>
                    <Grid sm={12}>
                      <div className="custom_dropdown">
                        <div
                          className="custom_input"
                          onClick={() => {
                            setSearchList(true);
                          }}
                        >
                          {contacts.map((data, index) => (
                            <div className="custom_field">
                              {data.checkbox_status === true ? (
                                <span>
                                  {data.name}
                                  <IconButton
                                    onClick={(e) =>
                                      selectClientsFromList(index, false)
                                    }
                                  >
                                    <CloseOutlinedIcon />
                                  </IconButton>
                                </span>
                              ) : null}
                            </div>
                          ))}
                          <input
                            type="text"
                            className="search"
                            onChange={searchHandler}
                          />

                          <label>Client List</label>
                        </div>
                        {searchList === true ? (
                          <div className="customDropdownList">
                            <div className="innerContainer">
                              <Link
                                className="cross"
                                onClick={() => {
                                  setSearchList(false);
                                }}
                              ></Link>
                              <ul>
                                {contacts.map((data, index) => {
                                  return (
                                    <li>
                                      <div>
                                        <Checkbox
                                          defaultChecked={data.checkbox_status}
                                          value={data.checkbox_status}
                                          onClick={(e) =>
                                            selectClientsFromList(
                                              index,
                                              !data.checkbox_status
                                            )
                                          }
                                        />
                                        <label className="employee"></label>
                                      </div>
                                      {` ${data.name}`}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </div> */}
                {/* {role === "Custom" ? (
                  <ModulePermissionAccessControl
                    value_update={updateMasterState}
                  />
                ) : null} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}

      {updatePermission && (
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="submitBtn"
              onClick={detail_form_submit}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default EditCreateUsers;
