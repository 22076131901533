import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { useAppSelector } from "../../../../Store/hooks";
import "./NewSettings.scss";
// import OrganisationDetails from "./OrganisationSettings/OrganisationDetails/OrganisationDetails";

function Settings() {
  const navigate = useNavigate();
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const [personalSettingsViewPermission, setPersonalSettingsViewPermission] =
    useState(false);
  const [accessControlsViewPermission, setAccessControlsViewPermission] =
    useState(false);
  const [userControlsViewPermission, setUserControlsViewPermission] =
    useState(false);
  const [companyDetailViewPermission, setCompanyDetailViewPermission] =
    useState(false);
  const [branchViewPermission, setBranchViewPermission] = useState(false);
  const [departmentViewPermission, setDepartmentViewPermission] =
    useState(false);
  const [modulesViewPermission, setModulesViewPermission] = useState(false);
  const [taxViewPermission, setTaxViewPermission] = useState(false);
  const [currencyViewPermission, setCurrencyViewPermission] = useState(false);
  const [usersViewPermission, setUsersViewPermission] = useState(false);
  const [EmailSettingViewPermission, setEmailSettingViewPermission] =
    useState(false);
  const [appearanceViewPermission, setAppearanceViewPermission] =
    useState(false);

  useEffect(() => {
    setPersonalSettingsViewPermission(
      BROKER?.personalSetting?.view === undefined
        ? false
        : BROKER?.personalSetting?.view
    );
    setAccessControlsViewPermission(
      BROKER?.AccessControl?.view === undefined
        ? false
        : BROKER?.AccessControl?.view
    );
    setUserControlsViewPermission(
      BROKER?.Users?.view === undefined ? false : BROKER?.Users?.view
    );
    setCompanyDetailViewPermission(
      BROKER?.CompanyDetails?.view === undefined
        ? false
        : BROKER?.CompanyDetails?.view
    );
    setAppearanceViewPermission(
      BROKER?.Appearance?.view === undefined ? false : BROKER?.Appearance?.view
    );
    setEmailSettingViewPermission(
      BROKER?.EmailSetting?.view === undefined
        ? false
        : BROKER?.EmailSetting?.view
    );
    setBranchViewPermission(
      BROKER?.Branches?.view === undefined ? false : BROKER?.Branches?.view
    );
    setDepartmentViewPermission(
      BROKER?.Department?.view === undefined ? false : BROKER?.Department?.view
    );
    setModulesViewPermission(
      BROKER?.Module?.view === undefined ? false : BROKER?.Module?.view
    );
    setTaxViewPermission(
      BROKER?.Taxation?.view === undefined ? false : BROKER?.Taxation?.view
    );
    setCurrencyViewPermission(
      BROKER?.Currencies?.view === undefined ? false : BROKER?.Currencies?.view
    );
    setUsersViewPermission(
      BROKER?.Users?.view === undefined ? false : BROKER?.Users?.view
    );
  }, []);

  return (
    <Box className="newSettingWrapper">
      <Grid container padding={0}>
        <Grid xs={12}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate(ALL_ROUTES.DASHBOARD);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            Settings
          </h3>
        </Grid>
        <Grid
          xs={12}
          sx={{ maxWidth: "1600px", margin: "auto" }}
          className="innerSection"
        >
          <>
            <Grid container spacing={3}>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>General</h4>
                  <div className="menulistBox">
                    <span className="general"></span>
                    <ul className="menulist">
                      {!userDetails.parentsId ? (
                        ""
                      ) : (
                        <>
                          {personalSettingsViewPermission && (
                            <li>
                              <Link
                                underline="none"
                                className="listItem "
                                onClick={() => {
                                  navigate(
                                    `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`
                                  );
                                }}
                              >
                                Personal Settings
                              </Link>
                            </li>
                          )}
                        </>
                      )}

                      {EmailSettingViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=emailSettings&section=general`
                              );
                            }}
                          >
                            Email Settings
                          </Link>
                        </li>
                      )}
                      {/* <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=storageSettings&section=general`
                            );
                          }}
                        >
                          Storage Settings
                        </Link> }
                      </li>*/}
                      {appearanceViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=appearance&section=general`
                              );
                            }}
                          >
                            Appearance
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>Organisation</h4>
                  <div className="menulistBox">
                    <span className="organisation"></span>
                    <ul className="menulist">
                      {companyDetailViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=companyDetails&section=org`
                              );
                            }}
                          >
                            Company Details
                          </Link>
                        </li>
                      )}
                      {branchViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=branches&section=org`
                              );
                            }}
                          >
                            Branches
                          </Link>
                        </li>
                      )}
                      {departmentViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=departments&section=org`
                              );
                            }}
                          >
                            Departments
                          </Link>
                        </li>
                      )}
                      {taxViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=taxation&section=org`
                              );
                            }}
                          >
                            Taxation
                          </Link>
                        </li>
                      )}
                      {currencyViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=currencies&section=org`
                              );
                            }}
                          >
                            Currencies
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>

              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>User And Controls</h4>
                  <div className="menulistBox">
                    <span className="userControl"></span>
                    <ul className="menulist">
                      {usersViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem users"
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=users&section=userControl`
                              );
                            }}
                          >
                            Users
                          </Link>
                        </li>
                      )}
                      {accessControlsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem access_control"
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`
                              );
                            }}
                          >
                            Access Control
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>

              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <div className="menuBoxHeading">
                    <h4>Customisation</h4>
                  </div>
                  <div className="menulistBox">
                    <span className="customisation"></span>
                    <ul className="menulist">
                      {modulesViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem module active"
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=modules&section=custom`
                              );
                            }}
                          >
                            Modules
                          </Link>
                        </li>
                      )}
                      {/* <li>
                        <Link
                          underline="none"
                          className="listItem templates"
                          onClick={() => {
                            navigate(ALL_ROUTES.SETTING_MODULE_FORM);
                          }}
                        >
                          Templates
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Grid>
              {
                <Grid lg={3} xs={6}>
                  <div className="MenuBox">
                    <div className="menuBoxHeading">
                      <h4>Portal Setup</h4>
                    </div>
                    <div className="menulistBox">
                      <span className="portalSetup"></span>
                      <ul className="menulist">
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {}}
                          >
                            Employee Portal
                          </Link>
                        </li>
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {}}
                          >
                            Employer Portal
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Grid>
              }
              {
                <Grid lg={3} xs={6}>
                  <div className="MenuBox">
                    <h4>Data Admin</h4>
                    <div className="menulistBox">
                      <span className="dataAdmin"></span>
                      <ul className="menulist">
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=storageSettings&section=dataAdmin`
                              );
                            }}
                          >
                            Storage
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {}}
                          >
                            Export
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {}}
                          >
                            Activity Log
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {}}
                          >
                            Audit Log
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </Grid>
              }
              {/*
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <div className="menuBoxHeading">
                    <h4>Developer Space</h4>
                  </div>
                  <div className="menulistBox">
                    <span className="developerSpace"></span>
                    <ul className="menulist">
                      //  <li>
                      //   <Link
                      //     underline="none"
                      //     className="listItem integartions"
                      //     onClick={() => { }}
                      //   >
                      //     Integrations
                      //   </Link>
                      // </li>
                      // <li>
                      //   <Link
                      //     underline="none"
                      //     className="listItem webhook"
                      //     onClick={() => { }}
                      //   >
                      //     API & Webhooks
                      //   </Link>
                      // </li> 
                      <li>
                        <Link
                          underline="none"
                          className="listItem webhook"
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=retail&section=developer`
                            );
                          }}
                        >
                          Retail Insurance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              */}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Settings;
