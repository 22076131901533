import Active from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeDetailPolicies from "../../../../../Components/Broker/EmployeeDetailPolicies/EmployeeDetailPolicies";
import LogsTable from "../../../../../Components/Broker/LogsTable/LogsTable";
import ClaimList from "../../../../../Components/Common/ClaimList/ClaimList";
import EndorsementList from "../../../../../Components/Common/EndorsementList/EndorsementList";
import Nominee from "../../../../../Components/Common/Nominee/NomineDetail/Nominee";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../../../Services/EmployerServices/Employee/EmployeeServices";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import "../../../../../formLayout.scss";
import EmployeeDetailProfile from "./EmployeeDetailProfile";
import OverViewTabNew from "./OverViewTabNew";
import { employeeDTO } from "../../../../../Services/DTO/Employee/EmployeeDTO";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { permissionSlice } from "../../../../../Store/Slice_Reducer/Permission/PermissionSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
interface TStatusCode {
  key: string;
  value: string;
  color: string;
}

function EmployeeDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [employeeId, setEmployeeId] = useState("");
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [selectedMemberType, setselectedMemberType] = useState<any>("");
  const [statusCode, setstatusCode] = useState<Array<TStatusCode>>([]);
  const [priorityStatus, setPriorityStatus] = useState(32);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [dateData, setDateData] = useState({
    createdAt: "",
    updatedAt: "",
    employeeId: "",
    prefix: "",
    _id: "",
  });
  const [firstNameChar, setFirstNameChar] = useState("");
  const [selectedClient, setSelectedClient] = useState<{
    id: string;
    label: string;
  }>({ id: "", label: "" });
  const [empSalary, setEmpSalary] = useState<string>("");
  const [empSalaryBracket, setEmpSalaryBracket] = useState<string>("");
  const [empDepartment, setEmpDepartment] = useState<string>("");
  const [empDesignation, setEmpDesignation] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [dob, setDOB] = useState<string>("");
  const [employementStatus, setemployementStatus] = useState(37);
  const [employeeBaseLocationId, setemployeeBaseLocationId] = useState("");
  const [selectedMemberDOB, setselectedMemberDOB] = useState<any>("");
  const [memberId, setMemberId] = useState<string>("");
  const [relatedEmpId, setRelatedEmpId] = useState<string>("");
  const [policyMemberRelationId, setPolicyMemberRelationId] =
    useState<string>("");
  const [priorityStatusPrimaryEmp, setPriorityStatusPrimaryEmp] = useState(32);
  const [employementStatusPrimaryEmp, setemployementStatusPrimaryEmp] =
    useState(37);
  const [changeStatusPermission, setChangeStatusPermission] = useState(false);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "_id") {
      getStatusCode(value);
    } else if (attrName === "Member Type") {
      setselectedMemberType(value);
    } else if (attrName === setPriorityStatus) {
      if (priorityStatus === 34) {
        return toast.warn("Void status is not Reversible");
      }
      dispatch(permissionSlice.actions.updateMemberStatus(priorityStatus));
      setPriorityStatus(value);
      update_status(value, "MEMBER");
    } else {
      attrName(value);
    }
  };
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  useEffect(() => {
    dispatch(permissionSlice.actions.updateMemberStatus(priorityStatus));
    setChangeStatusPermission(
      EMPLOYER?.Employees?.status === undefined
        ? false
        : EMPLOYER?.Employees?.status
    );
  }, [priorityStatus]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setEmployeeId(id ?? "");

    let timeoutId: any;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_employee_layout(id ?? "");
      get_status();
    };

    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const getStatusCode = (id: string) => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      setstatusCode(
        data.map((item: TStatusCode) => {
          return {
            ...item,
            key: item.key.toUpperCase(),
            value: item.value.toUpperCase(),
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log("Status list", err);
    };

    EMPLOYEE_SERVICES.get_status_data(onSuccess, onError, id);
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, type: string) => {
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id ? id : "",
      type,
      usertypeinfo.toLowerCase()
    );
  };

  const get_employee_layout = (id: string) => {
    const onSuccess = (res: any) => {
      setPolicyMemberRelationId(res.data.data[0]?.memberId);
      setemployementStatus(res.data.data[0]?.employement_status.value);
      setemployementStatusPrimaryEmp(
        res.data.data[0]?.primaryEmployeeEmploymentStatus
      );
      setPriorityStatusPrimaryEmp(res.data.data[0]?.primaryEmployeeStatus);
      getStatusCode(res.data.data[0]._id);
      setDateData({
        createdAt: res.data.data[0].createdAt,
        updatedAt: res.data.data[0].updatedAt,
        employeeId: res.data.data[0].employeeId,
        _id: res.data.data[0]._id,
        prefix: res.data.data[0].preFix + res.data.data[0].employeeId,
      });
      setPriorityStatus(res.data.data[0]?.status?.value || 32);
      let newData = res.data.data[0].layout.map(
        (dataMainsection: employeeDTO) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection: any) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow: any) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map((dataColumn: any) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children;
                    newColumn.children = dataColumn.children.map(
                      (dataInput: any) => {
                        if (dataInput.field_type === "searchLocation") {
                          let temp: any = dataInput;
                          setemployeeBaseLocationId(temp.value_id);
                        }
                        if (dataInput?.field_lable === "Member Type") {
                          setselectedMemberType(dataInput.value);
                        }
                        if (dataInput?.field_lable === "First Name") {
                          setFirstNameChar(dataInput.value);
                        }
                        if (dataInput?.field_lable === "Last Name") {
                          setLastName(dataInput.value);
                        }
                        if (dataInput?.field_lable === "Date Of Birth") {
                          setselectedMemberDOB(dataInput.value);
                          setDOB(dataInput.value);
                        }
                        if (dataInput.field_type === "hideid") {
                          return {
                            ...dataInput,
                            field_lable: "hide",
                            field_type: "hide",
                          };
                        } else {
                          return { ...dataInput };
                        }
                      }
                    );
                    return newColumn;
                  });
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );
    };
    const onError = (err: any) => {};
    EMPLOYER_EMPLOYEE_SERVICES.employee_detail_view_layout(
      id,
      onSuccess,
      onError
    );
  };

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(ALL_ROUTES.EMPLOYEE);
            }}
          >
            <ChevronLeftIcon />
          </Link>
          <span className="detailIcon">{firstNameChar.charAt(0)}</span>
          <div>
            <h3>{firstNameChar}</h3>
            <p>
              {dateData.prefix}
              {" - Created On "}
              {date_time_format(dateData.createdAt, user_type_dateFormat, tz)}
            </p>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div id={`_${priorityStatus.toString()}`}>
            <div
              className="status_dropdown"
              id={`_${priorityStatus.toString()}`}
            >
              {priorityStatus === 32 ? (
                <Active id={`_${priorityStatus.toString()}`} />
              ) : priorityStatus === 33 ? (
                <Inactive id={`_${priorityStatus.toString()}`} />
              ) : priorityStatus === 34 ? (
                <Void id={`_${priorityStatus.toString()}`} />
              ) : null}
              <SelectDropdown
                class_name="inputFieldd"
                title=""
                value={priorityStatus}
                attrName={setPriorityStatus}
                value_update={updateMasterState}
                dropdown_data={prioritystatusData}
                warn_status={false}
                disabled={!changeStatusPermission}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection h-100">
              <Grid container spacing={3} alignItems="center">
                <Grid xs maxWidth="260px"></Grid>
                <Grid xl={7} lg={8} md={8} xs={12} style={{ padding: "20px" }}>
                  <div className="productTabs ">
                    <Button
                      className={
                        viewTabsStatus === "overview"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "overview");
                      }}
                    >
                      Overview
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "policies"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "policies");
                      }}
                    >
                      Policies
                    </Button>
                    {selectedMemberType === "Employee" ? (
                      <>
                        <Button
                          className={
                            viewTabsStatus === "nominees"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "nominees");
                          }}
                        >
                          Nominees
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "profile"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            updateMasterState(setViewTabsStatus, "profile");
                          }}
                        >
                          Profile
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      className={
                        viewTabsStatus === "endorsement"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "endorsement");
                      }}
                    >
                      Endorsement
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "claims"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "claims");
                      }}
                    >
                      Claims
                    </Button>
                  </div>
                </Grid>
                <Grid
                  xs
                  style={{ textAlign: "right" }}
                  className="text-sm-center"
                >
                  <p className="lastupdate">
                    Last Updated On:{" "}
                    {date_time_format(
                      dateData.updatedAt,
                      user_type_dateFormat,
                      tz
                    )}
                  </p>
                </Grid>
              </Grid>

              {/* this file is common in broker and employer */}
              {viewTabsStatus === "overview" ? (
                <OverViewTabNew
                  value_update={updateMasterState}
                  setSelectedClient={setSelectedClient}
                  setEmpSalary={setEmpSalary}
                  setEmpSalaryBracket={setEmpSalaryBracket}
                  setEmpDepartment={setEmpDepartment}
                  setEmpDesignation={setEmpDesignation}
                  setPropsMemberId={setMemberId}
                  setRelatedEmpId={setRelatedEmpId}
                  setPolicyMemberRelationId={setPolicyMemberRelationId}
                />
              ) : null}
              {viewTabsStatus === "policies" ? (
                <>
                  <EmployeeDetailPolicies
                    selectedClient={selectedClient}
                    employeeId={employeeId}
                    empSalary={empSalary}
                    empSalaryBracket={empSalaryBracket}
                    empDepartment={empDepartment}
                    empDesignation={empDesignation}
                    empFirstName={firstNameChar}
                    empLastName={lastName}
                    dob={dob}
                    memberId={memberId}
                    selectedMemberType={selectedMemberType}
                    relatedEmpId={relatedEmpId}
                    policyMemberRelationId={policyMemberRelationId}
                    employementStatus={
                      employementStatusPrimaryEmp ?? employementStatus
                    }
                    mainStatus={priorityStatusPrimaryEmp ?? priorityStatus}
                    selectedMemberDOB={selectedMemberDOB}
                    employeeBaseLocationId={employeeBaseLocationId}
                  />
                </>
              ) : null}
              {viewTabsStatus === "nominees" ? (
                <Nominee
                  id={employeeId === undefined ? "" : employeeId}
                  selectedClient={selectedClient}
                />
              ) : null}
              {viewTabsStatus === "profile" ? <EmployeeDetailProfile /> : null}
              {viewTabsStatus === "logs" ? (
                <Box>
                  <LogsTable />
                </Box>
              ) : null}
              {viewTabsStatus === "claims" ? <ClaimList id={id} /> : null}
              {viewTabsStatus === "endorsement" ? (
                <EndorsementList id={id} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default EmployeeDetails;
