/* eslint-disable @typescript-eslint/no-unused-vars */
//used
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { TUserData } from "../../../Services/Types/TUserData";
import { RootState } from "../../../Store/Store";
import { useAppSelector } from "../../../Store/hooks";
import BulkUploadForm from "../../Common/BulkUploadFormSP/BulkUploadForm";
import "../Attachment/Attachment.scss";
import { toast } from "react-toastify";
import {
  downloadAttachement,
  getFileSignedUrl,
} from "../../../Supporting files/HelpingFunction";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  dataArray?: Array<any>;
  additionalDoc?: any;
  firStatus?: boolean;
  deleteurl?: any;
  childOf?: string;
  disabled?: boolean;
  downloadPermission?: boolean;
}

const Attachment: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  dataArray,
  additionalDoc,
  deleteurl,
  childOf = "",
  disabled,
  firStatus,
  downloadPermission,
}) => {
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [removeAttachment, setRemoveAttachment] = useState(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [indexDelete, setIndexDelete] = useState<number>();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );

  useEffect(() => {
    setTableData(dataArray === undefined ? [] : dataArray);
  }, [open_status, value_update]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName === "attachment_new_data") {
      console.log(value, "value");
      value_update("attachment_updated", value);
    } else {
      attrName(value);
    }
  };

  const delete_attachment_api = (attachmentId: any) => {
    const onSuccess = (res: any) => {
      value_update(
        "attachment_deleted",
        childOf === "QUOTEINSURER" ? attachmentId : true
      );
    };
    const onError = (err: any) => {};
    deleteurl(onSuccess, onError, id, attachmentId);
  };

  useEffect(() => {
    setUploadFile(open_status);
  }, [open_status]);

  useEffect(() => {
    value_update(attrName, uploadFile);
  }, [uploadFile]);

  return (
    <Box>
      <Modal open={removeAttachment} className="modalWrapper">
        <Box className="modalInner deletepopup">
          <Grid container spacing={1}>
            <Grid xs={12} sx={{ width: "100%" }}>
              <Link
                className="close-button"
                onClick={() => {
                  setRemoveAttachment(false);
                }}
              />
              <div className="img-prt">
                <img
                  src="./images/deletepopup_deleteicon.svg"
                  alt=""
                  className="img"
                />
              </div>

              <h5 className="popup-heading">
                Are you sure you want
                <br /> to delete this?
              </h5>
            </Grid>
            <Grid
              xs={12}
              className="ctaBtn"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => {
                  delete_attachment_api(indexDelete);
                  setRemoveAttachment(false);
                }}
              >
                Yes, Delete
              </Button>
              <Button
                variant="contained"
                className="cancelBtn"
                onClick={() => {
                  setRemoveAttachment(false);
                }}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {childOf != "QUOTEINSURER" && (
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
      )}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="attatchment_table" style={{ overflow: "hidden" }}>
              <ul className="heading">
                <li>File Name</li>
                <li>Description</li>
                <li>Date</li>
                {/* <li>Time</li> */}
                <li>Actions</li>
              </ul>

              {tableData?.length > 0 &&
                tableData?.map((data, index) => (
                  <ul key={data.id} className="subheading">
                    <li>{data?.doc_name || data?.docName || data?.doc_name}</li>
                    <li>{data?.description}</li>
                    {/* <li>jabba</li> */}
                    <li>
                      <p className="date">
                        {moment(data?.date).format(
                          userState.localInformation.dateFormat
                        )}
                      </p>
                    </li>
                    {/* <li>
                      <p className="time">
                        {moment(data?.time)
                          .utcOffset("+05:30")
                          .format("hh:mm A")}
                      </p>
                    </li> */}
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {downloadPermission && (
                        <Link
                          className="blueBtn download ml-0"
                          onClick={() => {
                            downloadAttachement(
                              data.downloadLink,
                              userState.userType
                            );
                          }}
                          sx={{
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        ></Link>
                      )}
                      {userState.userType?.toLowerCase() === "broker" && (
                        <Link
                          className="redBtn delete"
                          onClick={() => {
                            if (disabled) {
                              return toast.error(
                                "Edit Permissions Not Allowed"
                              );
                            }
                            setRemoveAttachment(true);
                            setIndexDelete(data._id);
                          }}
                          sx={{
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        ></Link>
                      )}
                    </li>
                  </ul>
                ))}
              {/* {firStatus && (
                <ul className="subheading">
                  <li>{additionalDoc?.docName}</li>
                  <li> -- </li>
                  <li > {moment(additionalDoc?.date).format(
                          userState.localInformation.dateFormat
                        )}</li>
                  
                  <li style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  >
                    <Link
                      className="blueBtn download ml-0"
                      onClick={() => {
                        downloadAttachement(additionalDoc, userState.userType)
                      }}
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>
                  </li>
                </ul>
              )} */}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Attachment;
