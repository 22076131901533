import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, Tooltip } from "@mui/material";

import { titleCase } from "../HelpingFunction";

const TextAreaField = ({
  title = "",
  value = "",
  attrName = "",
  value_update = () => {},
  warn_status = false,
  info_status = false,
  class_name = "inputField",
  error_messg = "", //`Enter valid ${title}`,
  info_message = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  specialcharacter,
  charcterlimit,
  alphanumeric,
  required,
  disable,
  minRows,
  maxRows,
}: {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  validation_type?: string;
  required?: boolean;
  charcterlimit?: number;
  alphanumeric?: boolean;
  specialcharacter?: boolean;
  disable?: boolean;
  minRows?: number;
  maxRows?: number;
}) => {



  const onChangeText = (updatedValue: string) => {

    if (specialcharacter === true && alphanumeric === true) {
      if (charcterlimit && updatedValue.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, updatedValue);
    } else if (alphanumeric === true) {
      let valid_value = updatedValue.replace(/[^a-zA-Z0-9 ]/g, "");
      valid_value = valid_value.toString();
      valid_value = titleCase(valid_value);
      if (charcterlimit && valid_value.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, valid_value);
    } else if (specialcharacter === true) {
      if (charcterlimit && updatedValue.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, updatedValue);
    } else {
      let valid_value = updatedValue.replace(/[^a-zA-Z ]/g, "");
      valid_value = valid_value.toString();
      valid_value = titleCase(valid_value);
      if (charcterlimit && valid_value.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, valid_value);
    }
  };

  return (
    <div className={class_name}>
      <TextField
        fullWidth
        style={{height:"50px !important"}}
        label={title}
        variant="outlined"
        value={value}
        minRows={minRows ?? 10}
        maxRows={maxRows ?? 40}
        onChange={(e) => {
          onChangeText(e.target.value);
        }}
        required={required}
        multiline
        disabled={disable}
        onKeyDownCapture={(e) => {
          if (e.key === "<" || e.key === ">") {
            e.preventDefault();
          }
      
        }}
      />

      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

export default TextAreaField;
