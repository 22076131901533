import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import { date_time_format } from "../../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import "../../OrganisationSettings/Currencies/Currencies.scss";
import "./../../../../../../index.scss";

const Users = () => {
  const { dateFormat, timeZone } = useAppSelector(
    (state) => state.userDetailsSlice.localInformation
  );
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");
  const [userData, setUserData] = useState<any[]>([]);
  const [arraySelect, setArraySelect] = useState<Array<{ id: number }>>([]);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const navigate = useNavigate();
  const { BROKER } = useAppSelector((state) => state.permissionSlice);

  const { companyCode, parentsId } = useAppSelector(
    (state) => state.userDetailsSlice
  );
  const [userCreatePermission, setUserCreatePermission] = useState<any>(null);
  const [userEiditPermission, setUserEiditPermission] = useState<any>(null);
  const [statusPermission, setStatusPermission] = useState<any>(null);
  const [userRemovePermission, setUserRemovePermission] = useState<any>(null);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  useEffect(() => {
    setShowLoader(true);
    get_status(
      BROKER?.Users?.status === undefined ? false : BROKER?.Users?.status
    );
    setUserCreatePermission(
      BROKER?.Users?.add === undefined ? false : BROKER?.Users?.add
    );
    setUserEiditPermission(
      BROKER?.Users?.edit === undefined ? false : BROKER?.Users?.edit
    );
    setUserRemovePermission(
      BROKER?.Users?.remove === undefined ? false : BROKER?.Users?.remove
    );
    setStatusPermission(
      BROKER?.Users?.status === undefined ? false : BROKER?.Users?.status
    );
  }, []);

  //  get user data
  useEffect(() => {
    get_users();
  }, [deleteStatus]);

  const delete_employee = (attrName?: any, step?: any) => {
    let ids = {
      ids: arraySelect,
    };

    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    ACCESS_LEVEL_SERVICES.delete_user(ids, onSuccess, {}, onError);
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const get_users = () => {
    let params = {
      companyCode: parentsId ? parentsId : companyCode,
    };
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let data: any[] = [];
      res.data.data.map((e: any) => {
        data.push({
          ...e,
          id: e._id,
        });
      });
      data = data.map((item: any) => {
        for (let key in item) {
          if (!item[key]) {
            item[key] = "--";
          }
        }
        return item;
      });
      setUserData(data);
    };
    const onError = (err: any) => {};
    ACCESS_LEVEL_SERVICES.get_user_data(params, onSuccess, onError);
  };

  const edit_navigate = () => {
    if (arraySelect.length === 0) {
      setEditPopupTitle("Please select the user for edit");
      setEditStatus(true);
      return;
    } else if (arraySelect.length > 1) {
      setEditPopupTitle("Only one user allow at a time");
      setEditStatus(true);
      return;
    } else {
      navigate(`${ALL_ROUTES.EDIT_USER}?id=${arraySelect[0]}`);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArraySelect(value);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      update_status(value, attrName[1]._id);
    } else {
      attrName(value);
    }
  };

  const get_status = (statusPermissions: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);

      setColumns([
        {
          field: "userid",
          headerName: "User ID",
          width: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            console.log("row", row);
            return <p>{row.userCode}</p>;
          },
        },
        {
          field: "email",
          headerName: "Email",
          width: 250,

          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                href={`mailto:${row.email}`}
              >
                {row.email}
              </Link>
            );
          },
        },
        {
          field: "fullname",
          headerName: "Full Name",
          width: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.EDIT_USER}?id=${row._id}`);
                }}
              >
                {row.name}
              </Link>
            );
          },
        },
        {
          field: "role",
          headerName: "Role",
          width: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.broker_role?.roleName}</p>;
          },
        },
        {
          field: "department",
          headerName: "Department",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.department?.label ?? row?.department}</p>;
          },
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.broker_active_status}`}>
                <div className="status_dropdown" id={`_${row.broker_active_status}`}>
                  {row.status === 13 ? (
                    <CheckCircleIcon id={`_${row.broker_active_status.toString()}`} />
                  ) : row.broker_active_status === 14 ? (
                    <HighlightOffIcon id={`_${row.broker_active_status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.broker_active_status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPermissions}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Creation Date",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row?.createdAt, dateFormat, timeZone)}</p>
            );
          },
        },
        {
          field: "updatedDate",
          headerName: "Updation Date",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row?.updatedAt, dateFormat, timeZone)}</p>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_users();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={2} className="currunciesWrapper">
        <Grid xs={4}>
          <h3 className="mb-0">Users</h3>
        </Grid>

        <Grid xs={8} className="text-right">
          <div className="buttons">
            {userCreatePermission && (
              <Link
                underline="none"
                className="greenBtn addfile"
                onClick={() => {
                  navigate(ALL_ROUTES.CREATE_USER);
                }}
              >
                Create New
              </Link>
            )}
            {userEiditPermission && (
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={edit_navigate}
              >
                Edit
              </Link>
            )}
            {/* {userRemovePermission && (
              <Link
                underline="none"
                className="redBtn delete"
                onClick={() => {
                  setDeleteStatus(true);
                }}
              >
                Remove
              </Link>
            )} */}
          </div>
        </Grid>
        <Grid xs={12}>
          <div style={{ height: 400 }}>
            <UGTable
              header_data={columns}
              data={userData}
              value_update={updateMasterState}
              attrName={"UGTable"}
              BE_filter_code={E_BE_FILTER_CODE.USERS}
              class_Name={"th-setting"}
              refetch_data={get_users}
              setTableLimit={setTableLimit}
              setTableSkip={setTableSkip}
              paginationMode="client"
            />
          </div>
        </Grid>
        <GlobalDeletePopup
          open_status={deleteStatus}
          value_update={updateMasterState}
          attrName={setDeleteStatus}
          delete_step={deleteStatusStep}
          delete_item={() => {
            delete_employee("data", deleteStatusStep);
          }}
          ids={arraySelect}
          title="User Data"
        />
        <GlobelEditPopup
          popupStatus={editStatus}
          setPopupStatus={setEditStatus}
          title={editPopupTitle}
        />
      </Grid>
    </>
  );
};

export default Users;
