import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYER_ALL_ROUTES } from "../../../../../Routes/employer_all_routes";
import { useAppSelector } from "../../../../../Store/hooks";
import "./MainSettingMenu.scss";

const MainSettingMenu = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("appearance");
  const [section, setSection] = useState("");
  const user_details = useAppSelector((state) => state.userDetailsSlice);
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [companyDetailViewPermission, setCompanyDetailViewPermission] =
    useState(false);
  const [branchViewPermission, setBranchViewPermission] = useState(false);
  const [departmentViewPermission, setDepartmentViewPermission] =
    useState(false);
  const [modulesViewPermission, setModulesViewPermission] = useState(false);
  const [taxViewPermission, setTaxViewPermission] = useState(false);
  const [currencyViewPermission, setCurrencyViewPermission] = useState(false);
  const [usersViewPermission, setUsersViewPermission] = useState(false);
  const [personalSettingView, setPersonalSettingViewPermission] =
    useState(false);
  const [EmailSettingViewPermission, setEmailSettingViewPermission] =
    useState(false);
  const [appearanceViewPermission, setAppearanceViewPermission] =
    useState(false);
  console.log("personalSettingView", personalSettingView);
  const [accessControlsViewPermission, setAccessControlsViewPermission] =
    useState(false);

  const handleChange =
    (panel: string, subPanel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setActivePage(subPanel);
      navigate(
        `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=${subPanel}&section=${panel}`,
        { replace: true }
      );
    };

  useEffect(() => {
    setAccessControlsViewPermission(
      EMPLOYER?.AccessControl?.view === undefined
        ? false
        : EMPLOYER?.AccessControl?.view
    );
    setAppearanceViewPermission(
      EMPLOYER?.Appearance === undefined ? false : EMPLOYER?.Appearance?.view
    );
    setPersonalSettingViewPermission(
      EMPLOYER?.personalSetting === undefined
        ? false
        : EMPLOYER?.personalSetting?.view
    );
    setEmailSettingViewPermission(
      EMPLOYER?.EmailSetting?.view === undefined
        ? false
        : EMPLOYER?.EmailSetting?.view
    );
    setUsersViewPermission(
      EMPLOYER?.Users?.view === undefined ? false : EMPLOYER?.Users?.view
    );
    setCompanyDetailViewPermission(
      EMPLOYER?.CompanyDetails?.view === undefined
        ? false
        : EMPLOYER?.CompanyDetails?.view
    );
    setBranchViewPermission(
      EMPLOYER?.Branches?.view === undefined ? false : EMPLOYER?.Branches?.view
    );
    setDepartmentViewPermission(
      EMPLOYER?.Department?.view === undefined
        ? false
        : EMPLOYER?.Department?.view
    );
    setModulesViewPermission(
      EMPLOYER?.Module?.view === undefined ? false : EMPLOYER?.Module?.view
    );
    setTaxViewPermission(
      EMPLOYER?.Taxation?.view === undefined ? false : EMPLOYER?.Taxation?.view
    );
    setCurrencyViewPermission(
      EMPLOYER?.Currencies?.view === undefined
        ? false
        : EMPLOYER?.Currencies?.view
    );
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    let section = params.get("section");
    let page = params.get("page");
    setSection(section ?? "");
    setActivePage(page ?? "");
  }, [window.location.search]);

  return (
    <Box className="mainSettingWrapper" sx={{ paddingTop: "90px" }}>
      <Grid container spacing={3}>
        {/* <Grid xs={12}>
          <h3 className="pageTitle mb-0 ">
            <Link
              
              onClick={() => {
                navigate("/NewSettings");
              }}>
              <ChevronLeftIcon />
            </Link>
            Modules
          </h3>
        </Grid> */}

        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="accordian-section">
              <Accordion
                className="accordian"
                expanded={section === "general"}
                onChange={handleChange(
                  "general",
                  appearanceViewPermission ? "appearance" : ""
                )}
              >
                <AccordionSummary
                  aria-controls="1-content"
                  id="1-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "general"
                        ? "heading general active"
                        : "heading general "
                    }
                  >
                    General
                  </Typography>
                </AccordionSummary>
                {(appearanceViewPermission ||
                  personalSettingView ||
                  EmailSettingViewPermission) && (
                  <AccordionDetails className="accordian-detail-section">
                    <Typography>
                      <ul>
                        {/* {personalSettingView && ( */}
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "personalSettings"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`,
                                { replace: true }
                              );
                            }}
                          >
                            Personal Settings
                          </Link>
                        </li>
                        {/* )} */}
                        {EmailSettingViewPermission && (
                          <li>
                            <Link
                              underline="none"
                              className={
                                activePage === "emailSettings"
                                  ? "tab-button active"
                                  : "tab-button"
                              }
                              onClick={() => {
                                navigate(
                                  `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=emailSettings&section=general`,
                                  { replace: true }
                                );
                              }}
                            >
                              Email Settings
                            </Link>
                          </li>
                        )}
                        {appearanceViewPermission && (
                          <li>
                            <Link
                              underline="none"
                              className={
                                activePage === "appearance"
                                  ? "tab-button active"
                                  : "tab-button"
                              }
                              onClick={() => {
                                navigate(
                                  `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=appearance&section=general`,
                                  { replace: true }
                                );
                              }}
                            >
                              Appearance
                            </Link>
                          </li>
                        )}
                      </ul>
                    </Typography>
                  </AccordionDetails>
                )}
              </Accordion>

              <Accordion
                className="accordian"
                expanded={section === "org"}
                onChange={handleChange(
                  "org",
                  companyDetailViewPermission ? "companyDetails" : ""
                )}
              >
                <AccordionSummary
                  aria-controls="2-content"
                  id="2-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "org"
                        ? "heading orgnaisation active"
                        : "heading orgnaisation "
                    }
                  >
                    Organisation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {companyDetailViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "companyDetails"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=companyDetails&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Company Details
                          </Link>
                        </li>
                      )}
                      {branchViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "branches"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=branches&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Branches/Location
                          </Link>
                        </li>
                      )}
                      {departmentViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "departments"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=departments&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Departments
                          </Link>
                        </li>
                      )}
                      {
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "designations"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=designations&section=org`,
                                { replace: true }
                              );
                            }}
                          >
                            Designations
                          </Link>
                        </li>
                      }
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "salary"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=salary&section=org`,
                              { replace: true }
                            );
                          }}
                        >
                          Salary Bracket
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                className="accordian"
                expanded={section === "dataAdmin"}
                onChange={handleChange("dataAdmin", "activity")}>
                <AccordionSummary
                  aria-controls="3-content"
                  id="3-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "dataAdmin"
                        ? "heading dataadmin active"
                        : "heading dataadmin "
                    }
                  >
                    Data Admin
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {/* <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "storage"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=storage&section=dataAdmin`,
                              { replace: true }
                            );
                          }}>
                          Storage
                        </Link>
                      </li>

                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "export"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=export&section=dataAdmin`,
                              { replace: true }
                            );
                          }}>
                          Export
                        </Link>
                      </li> */}
              {/*<li>
                        <Link
                          underline="none"
                          className={
                            activePage === "activity"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=activity&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Activity Log
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "audit"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=audit&section=dataAdmin`,
                              { replace: true }
                            );
                          }}
                        >
                          Audit Log
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                className="accordian"
                expanded={section === "userControl"}
                onChange={handleChange(
                  "userControl",
                  accessControlsViewPermission ? "users" : ""
                )}
              >
                <AccordionSummary
                  aria-controls="4-content"
                  id="4-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "userControl"
                        ? "heading userimg active"
                        : "heading userimg "
                    }
                  >
                    Users & Controls
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      {usersViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "users"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=users&section=userControl`,
                                { replace: true }
                              );
                            }}
                          >
                            Users
                          </Link>
                        </li>
                      )}
                      {accessControlsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className={
                              activePage === "accessControl"
                                ? "tab-button active"
                                : "tab-button"
                            }
                            onClick={() => {
                              navigate(
                                `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`,
                                { replace: true }
                              );
                            }}
                          >
                            Access Control
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                className="accordian"
                expanded={section === "custom"}
                onChange={handleChange("custom")}
              >
                <AccordionSummary
                  aria-controls="5-content"
                  id="5-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "custom"
                        ? "heading customisation active"
                        : "heading customisation "
                    }
                  >
                    Customisation
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "modules"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=modules&section=custom`,
                              { replace: true }
                            );
                          }}
                        >
                          Modules
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "templates"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=templates&section=custom`,
                              { replace: true }
                            );
                          }}
                        >
                          Templates
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="accordian"
                expanded={section === "portalSetup"}
                onChange={handleChange("portalSetup")}
              >
                <AccordionSummary
                  aria-controls="6-content"
                  id="6-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "portalSetup"
                        ? "heading portalspace active"
                        : "heading portalspace "
                    }
                  >
                    Portal Setup
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "employee"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=employee&section=portalSetup`,
                              { replace: true }
                            );
                          }}
                        >
                          Employee Portal
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "employer"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=employer&section=portalSetup`,
                              { replace: true }
                            );
                          }}
                        >
                          Employer Portal
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}

              {/* <Accordion
                className="accordian"
                expanded={section === "developer"}
                onChange={handleChange("developer", "integrations")}>
                <AccordionSummary
                  aria-controls="7-content"
                  id="7-header"
                  className="accordion-header"
                >
                  <Typography
                    className={
                      section === "developer"
                        ? "heading devspace active"
                        : "heading devspace "
                    }
                  >
                    Developer Space
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordian-detail-section">
                  <Typography>
                    <ul>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "integrations"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=integrations&section=developer`,
                              { replace: true }
                            );
                          }}
                        >
                          Integrations
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className={
                            activePage === "webhooks"
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => {
                            navigate(
                              `${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=webhooks&section=developer`,
                              { replace: true }
                            );
                          }}
                        >
                          API & Webhooks
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainSettingMenu;
