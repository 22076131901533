import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";

import Rejected from "@mui/icons-material/Cancel";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import PartialApproval from "@mui/icons-material/Rule";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../Store/hooks";
import { RootState } from "../../../../../Store/Store";
import { Claim_SERVICES } from "../../../../../Services/Claim/ClaimServices";
import { EMPLOYER_ALL_ROUTES } from "../../../../../Routes/employer_all_routes";
import {
  date_time_format,
  isIntegratedTpa,
} from "../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../APIVar/EmployerUrlConstant";
import ConfirmationPopup from "../../../../../Components/Broker/ConfirmationPopUp/ConfirmationPopup";
import InitiateAClaim from "../../../../../Components/Broker/Claim/InitiateAClaimSp/InitiateAClaim";
import ViewDocuments from "../../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import MassUpdate from "../../../../../Components/Common/MassUpdate/MassUpdate";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";

interface Props {
  intiateClaim?: boolean;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}

const ClientClaimTab: React.FC<Props> = ({
  intiateClaim,
  shortcutDisable,
  setShortcutDisable,
}) => {
  const userType = useAppSelector(
    (state: any) => state.userDetailsSlice.userType
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const clientId = params.get("id");
  const navigate = useNavigate();
  const [addFilter, setAddFilter] = useState(false);
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [relation, setRelation] = useState("");
  const [selectDocument, setSelectDocument] = useState("");
  const [InitiateACliam, setInitiateACliam] = useState(intiateClaim);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [doc_data, setDoc_data] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [claimAssistData, setClaimAssistData] = useState<any>([]);
  console.log("claimAssistantData", claimAssistData);
  let claimAssistDataCopy: Array<any> = [];
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );

  const [showLoader, setShowLoader] = useState(true);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setAddPermission] = useState(false);
  const [csvPermission, setCsvPermission] = useState(false);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [statusPermission, setStatusPermission] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setselectedRow] = useState<any>({});
  const [selectedClaimValue, setselectedClaimValue] = useState<any>({});
  const [statusWarningPopoup, setstatusWarningPopoup] =
    useState<boolean>(false);
  const [selectedClaimID, setSelectedClaimID] = useState<Array<{ id: number }>>(
    []
  );
  console.log("selectedClaimID", selectedClaimID);
  const [massupdateopen, setmassupdateopen] = React.useState(false);
  console.log("selectedClaimId", selectedClaimID);
  console.log("selectedRow", selectedRow);
  console.log("totalRecords", totalRecords);
  const currentRoute = E_BE_FILTER_CODE.CLIENTDETAILCLAIM.toString();
  const rowPerPage = sessionStorage.getItem("rowPerPage");
  const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
  const existingEntryIndex = parsedData.find(
    (entry: any) => entry.route === currentRoute
  );
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    // downloadCsv(claimAssistData);
    get_claim(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit > claimAssistData.length) {
      csvData = claimAssistData;
    } else {
      console.log("claimAssistantData", claimAssistData);

      // csvData = claimAssistData.slice(
      //   pageNumber ? pageNumber * tableLimit : 0,
      //   (pageNumber + 1) * tableLimit
      // );
      csvData = claimAssistData;

      console.log(
        "number",
        (pageNumber - 1) * tableLimit,
        pageNumber * tableLimit
      );
    }
    console.log("csss", csvData);
    downloadCsv(csvData);
    handleClose();
  };
  let timeout: any;

  useEffect(() => {
    setAddPermission(
      BROKER?.Claims?.add === undefined ? false : BROKER.Claims.add
    );
    setCsvPermission(
      BROKER?.Claims?.csv === undefined ? false : BROKER.Claims.csv
    );
    setStatusPermission(
      BROKER?.Claims?.status === undefined ? false : BROKER.Claims.status
    );
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_claim();
      get_status(
        BROKER?.Claims?.status === undefined ? false : BROKER.Claims.status
      );
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_claim();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const get_status = (statusPermissions: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "claimId",
          headerName: "Claim ID",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <>
                <Link
                  onClick={() =>
                    navigate(
                      `${EMPLOYER_ALL_ROUTES.CLAIM_DETAIL}?id=${row._id}`
                    )
                  }
                >
                  {row.claimId}
                </Link>
              </>
            );
          },
        },
        {
          field: "clientId",
          headerName: "Client ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "memberId",
          headerName: "Member ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "memberName",
          headerName: "Member Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "insurer",
          headerName: "Insurer Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "tpaName",
          headerName: "TPA Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "designation",
          headerName: "Designation",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "department",
          headerName: "Department",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "status",
          headerName: "Claim Status",
          minWidth: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 47 ? (
                    <Requested id={`_${row.status.toString()}`} />
                  ) : row.status === 48 ? (
                    <SentToTPA id={`_${row.status.toString()}`} />
                  ) : row.status === 49 ? (
                    <Required id={`_${row.status.toString()}`} />
                  ) : row.status === 50 ? (
                    <Approved id={`_${row.status.toString()}`} />
                  ) : row.status === 51 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 52 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 53 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 65 ? ( //Claim Settled
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 66 ? ( //in progress
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 67 ? ( //cancelled
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 68 ? ( //Request Closed
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 69 ? ( //Payment Bounced
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 70 ? ( //other
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    title=""
                    class_name="inputFieldd"
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    // disabled={
                    //   !statusPermissions || isIntegratedTpa(row?.tpaName)
                    // }
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "TpaClaimStatus",
          headerName: "TPA Claim Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return row.tpa_status ? (
              <>
                <div id={`_${row.status}`}>
                  <div className="status_dropdown" id={`_${row.status}`}>
                    {row.status === 47 ? (
                      <Requested id={`_${row.status.toString()}`} />
                    ) : row.status === 48 ? (
                      <SentToTPA id={`_${row.status.toString()}`} />
                    ) : row.status === 49 ? (
                      <Required id={`_${row.status.toString()}`} />
                    ) : row.status === 50 ? (
                      <Approved id={`_${row.status.toString()}`} />
                    ) : row.status === 51 ? (
                      <Rejected id={`_${row.status.toString()}`} />
                    ) : row.status === 52 ? (
                      <Expired id={`_${row.status.toString()}`} />
                    ) : row.status === 53 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 65 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 66 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 67 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 68 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 69 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 70 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : null}
                    <SelectDropdown
                      class_name="inputFieldd"
                      title=""
                      value={row.tpa_status}
                      attrName={["updatestatus", row, index]}
                      value_update={updateMasterState}
                      dropdown_data={[
                        { label: row?.tpa_status, id: row?.tpa_status },
                      ]}
                      warn_status={false}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              "--"
            );
          },
        },
        {
          field: "requestType",
          headerName: "Request Type",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;

            return (
              <>
                <p>
                  {row.requestType.toLowerCase() === "intimation"
                    ? "Reimbursement"
                    : row.requestType}
                </p>
              </>
            );
          },
        },
        {
          field: "insured_person",
          headerName: "Insured Person",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            let insuredPerson: any[] = [];
            let insureData = "";
            if (Array.isArray(row.insuredPerson)) {
              insuredPerson =
                row.insuredPerson &&
                row.insuredPerson.length > 0 &&
                row.insuredPerson.map((e: any) => {
                  return delete e._id, e.value;
                });
              insureData = insuredPerson.toString();
            } else {
              insureData = row.insuredPerson;
            }
            return (
              <>
                <p>{insureData}</p>
              </>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Created at",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.createdAt, dateFormat, timeZone)}</p>
            );
          },
        },
        {
          field: "updatedAt",
          headerName: "Updated at",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.updatedAt, dateFormat, timeZone)}</p>
            );
          },
        },
        {
          field: "document",
          headerName: "Document",
          minWidth: 100,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                sx={{ textDecoration: "none" }}
                onClick={() => {
                  get_doc_list(row._id);
                }}
              >
                <span
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    marginRight: "10px",
                  }}
                >
                  {row.docCount}
                </span>
                <img
                  src="images/visibility-icon.svg"
                  alt=""
                  style={{
                    zIndex: 99,
                    position: "relative",
                    cursor: "pointer",
                  }}
                />
              </Link>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    Claim_SERVICES.get_status(onSuccess, onError);
  };

  // useEffect(() => {
  //   if(InitiateACliam){
  //   // attach the event listener
  //   window.addEventListener("keydown", handleKeyPress);
  //   get_claim();
  //   }
  // }, [InitiateACliam]);

  const get_doc_list = (id: string) => {
    const onSuccess = (res: any) => {
      setViewDocuments(true);
      setDoc_data(res.data.data.upload);
    };
    const onError = (err: any) => {
      // console.log("err", err);
      toast.error(err.response.data.errors[0].message);
    };
    let api_url =
      userType === "BROKER"
        ? `${URL_CONSTANTS.get_claim_document}${id}`
        : `${EMPLOYER_URL_CONSTANTS.get_claim_document}${id}`;

    Claim_SERVICES.get_claim_document_list(api_url, onSuccess, onError);
  };

  const get_claim = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      let data: any = [];
      const allData = res.data.data.claimData;
      res.data.data.claimData.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      if (!csv) {
        setClaimAssistData(data);
        setTotalRecords(res.data.data.countData);
        claimAssistDataCopy = data;
      }
      if (csv) {
        downloadCsv(allData);
      }
    };
    const onError = (err: any) => {
      setShowLoader(false);
    };
    let api_url = URL_CONSTANTS.get_claim_list_of_client(
      userType?.toLowerCase(),
      limit ? limit : existingEntryIndex?.pageNumber ?? 25,
      skip ?? 0,
      clientId ?? ""
    );
    Claim_SERVICES.get_claim_list_of_policy(api_url, onSuccess, onError);
  };

  const handleChange = (event: SelectChangeEvent, attrName: string) => {};

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    if (event.target.tagName !== "INPUT") {
      if (event.shiftKey && event.keyCode === 76) {
        setInitiateACliam(true);
      }
    }
  }, []);

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      if (searchBarFilter) {
        get_claim();
      }
    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  const updateMasterState = (attrName: any, value: any) => {
    console.log("value", value);
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === setViewDocuments) {
      return setViewDocuments(value);
    } else if (attrName === setInitiateACliam) {
      return setInitiateACliam(value);
    } else if (attrName[0] === "updatestatus") {
      if (value === 20 || value === 21 || value === 22 || value === 23) {
        return toast.warning("This status is Automated");
      }
      if (value === 51 || value === 67) {
        setstatusWarningPopoup(true);
        setselectedRow(attrName[1]);
        setselectedClaimValue(value);
        return;
      }
      update_status(value, attrName[1]._id);
    } else if (attrName === "selected_data") {
      setSelectedClaimID(value);
    } else if (attrName === "massupdateopen") {
      setmassupdateopen(false);
    } else if (attrName === "getClaimListMassUpdate") {
      get_claim();
    } else {
      // attrName(value);
    }
  };

  const downloadCsv = (CsvData: any) => {
    console.log("csvData", CsvData);
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "claims.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const updatedArr = CsvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          "Claim ID": item?.claimId,
          "Client ID": item?.clientId,
          "Client Name": item?.clientName,
          "Member ID": item?.memberId,
          "Member Name": item?.memberName,
          "Insurer Name": item?.insurer,
          "TPA Name": item?.tpaName,
          Designation: item?.designation,
          Department: item?.department,
          "Policy Number": item?.policyNumber,
          "Policy Type": item?.policyType,
          "Claim Status": statusObj?.value,
          "TPA Claim Status": "--",
          "Request Type":
            item?.requestType.toLowerCase() === "intimation"
              ? "Reimbursement"
              : item?.requestType,
          "Insured Person": item?.insuredPerson,
        };
      }
      return item;
    });
    console.log("updatedArr", updatedArr);
    columns.forEach((item: any, index: number) => {
      if (item.headerName === "Document") columns.splice(index, 1);
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType.toLowerCase(),
      "claims"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}

      <Grid container padding={0} marginTop={"20px"}>
        {/* <Members /> */}

        <Grid style={{ height: "600px", width: "100%" }}>
          <UGTable
            header_data={columns}
            data={claimAssistData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.CLIENTDETAILCLAIM}
            refetch_data={get_claim}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            // paginationMode="client"
            setPageNumberData={setPageNumber}
            disableCheckboxKey={"tpaType"}
            disableCheckboxValue={"INTEGRATED"}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default ClientClaimTab;
