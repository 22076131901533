import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { GROUP_TERM_SERVICES } from "../../../../../Services/ProductConfigurator/GroupTermServices";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";
import { GROUP_HEALTH_SERVICES } from "../../../../../Services/ProductConfigurator/GroupHealthServices";
import { getProductCode } from "../../../../../Supporting files/HelpingFunction";
import { toast } from "react-toastify";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  table_data: any;
  api_url: string;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
  refetchData?: Function;
}

const EditCategory: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  table_data,
  api_url,
  shortcutDisable,
  setShortcutDisable,
  refetchData,
}) => {
  const [editCategory, setEditCategory] = React.useState<{
    category: {
      name: string;
      description: string;
    };
  }>(table_data);
  const [categoryWarn, setcategoryWarn] = useState(false)
  useEffect(() => {
    setEditCategory({
      category: {
        name: table_data.name,
        description: table_data.description,
      },
    });
  }, [table_data]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[1] === "name") {
      attrName[0].name = value;
    }
    if (attrName[1] === "description") {
      attrName[0].description = value;
    }
    setEditCategory({ ...editCategory });
  };

  // edit catageory
  const updateCategory = () => {
    if (categoryWarn ||!editCategory.category.name || !editCategory.category.description) {
      return;
    }

    const onSuccess = (res: any) => {
      value_update(attrName, false);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      toast.error(err?.response?.data?.errors[0]?.message)
      console.log("false", err);
    };

    GROUP_SUPER_TOPUP.updateCatageory(
      api_url,
      {
        category: {
          name: editCategory.category.name,
          description: editCategory.category.description,
        },
      },
      onSuccess,
      {},
      onError,
      table_data?.id
    );
  };

  const removePopup = () => {
    value_update(attrName, false);
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
  };

  const fullUrl = window.location.href; // Get the full URL

  const checkTittleDuplicacy = () => {
    let dto = {
      title: editCategory.category.name,
      module: "CATEGORY",
      code:1,
      _id: table_data?.id
    }
    const onSuccess = (res: any) => {
      setcategoryWarn(res.data.data.error); 
    };
    const onError = (err: any) => {
    };
    GROUP_HEALTH_SERVICES.checkTittleDuplicacy(dto, getProductCode(fullUrl), onSuccess, onError);
  };


  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      // backdropClicked={empty_state_close_panel}
    >
      <div>
        <Grid container spacing={3} alignItems="center" className="mt-0">
          <Grid xs={10}>
            <h4 className="mb-0">Edit Category</h4>
          </Grid>
          <Grid xs={2} className="pt-3 text-right">
            <CloseIcon onClick={removePopup} />
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={8}>
              <RKTextField
                class_name="inputField"
                title={"Name"}
                value={editCategory?.category?.name}
                attrName={[editCategory?.category, "name"]}
                value_update={updateMasterState}
                warn_status={categoryWarn || 
                  editCategory.category?.name?.length === 0 ? true : false
                }
                error_messg={
                  editCategory.category?.name?.length === 0
                    ? "Enter Name"
                    : editCategory.category?.name?.length < 2
                      ? "Enter Min. 2 characters"
                      : "Category already exist"
                }
                onKeyUp={checkTittleDuplicacy}
              />
            </Grid>
            <Grid xs={12}>
              <TextAreaField
                class_name="inputField multiline"
                title={"Brief Description"}
                value={editCategory?.category?.description}
                attrName={[editCategory?.category, "description"]}
                value_update={updateMasterState}
                warn_status={
                  editCategory.category?.description?.length === 0
                    ? true
                    : false
                }
                error_messg={"Please describe the category"}
                alphanumeric={true}
                specialcharacter={true}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={updateCategory}
              disabled={categoryWarn}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default EditCategory;
