import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EMPLOYER_ALL_ROUTES } from "../../../../src/Routes/employer_all_routes";
import TabList from "../../Broker/Home/TabList/Tablist";
import "./SideNavBar.scss";
import { useAppSelector } from "../../../Store/hooks";

function SideNavBar() {
  const location = useLocation();
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [dashboardViewPermisison, setDasboardViewPermission] = useState(false);
  const [policiesViewPermission, setPoliciesViewPermission] = useState(false);
  const [memberViewPermission, setMemberViewPermission] = useState(false);
  const [claimViewPermission, setClaimViewPermission] = useState(false);
  const [endorsementViewPermission, setEndorsementViewPermission] =
    useState(false);
  const [supportViewPermission, setSupportViewPermission] = useState(false);
  console.log("EMPLOYER", EMPLOYER);
  useEffect(() => {
    setDasboardViewPermission(
      EMPLOYER?.Dashboard?.view === undefined
        ? false
        : EMPLOYER?.Dashboard?.view
    );
    setPoliciesViewPermission(
      EMPLOYER?.Policies?.view === undefined ? false : EMPLOYER?.Policies?.view
    );
    setClaimViewPermission(
      EMPLOYER?.Claims?.view === undefined ? false : EMPLOYER?.Claims?.view
    );
    setMemberViewPermission(
      EMPLOYER?.Employees?.view === undefined
        ? false
        : EMPLOYER?.Employees?.view
    );
    setEndorsementViewPermission(
      EMPLOYER?.Endorsement?.view === undefined
        ? false
        : EMPLOYER?.Endorsement?.view
    );
    setSupportViewPermission(
      EMPLOYER?.Support?.view === undefined ? false : EMPLOYER?.Support?.view
    );
  }, [EMPLOYER]);

  return (
    <Box className="menus">
      {/* Dashboard Tab */}
      {dashboardViewPermisison && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.DASHBOARD
              ? "dashboard active"
              : "dashboard"
          }
          buttonText={
            <p>
              <span>D</span>ashboard
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.DASHBOARD}
        />
      )}

      {/* Policies Tab */}
      {policiesViewPermission && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.POLICIES
              ? "policies active"
              : "policies"
          }
          buttonText={
            <p>
              <span>P</span>olicies
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.POLICIES}
        />
      )}
      {/* Employee Tab */}

      {memberViewPermission && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.EMPLOYEE
              ? "employe active"
              : "employe"
          }
          buttonText={
            <p>
              <span>M</span>ember
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.EMPLOYEE}
        />
      )}

      {/* Report Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.REPORT
              ? "report active"
              : "report"
          }
          buttonText={
            <p>
              <span>R</span>eport
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.REPORT}
        /> */}

      {/* Claim Assistant Tab */}
      {claimViewPermission && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.CLIAM_ASSISTANT
              ? "claimassistant active"
              : "claimassistant"
          }
          buttonText={
            <p>
              C<span>l</span>aim
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.CLIAM_ASSISTANT}
        />
      )}

      {/* Endorsement Tab */}
      {endorsementViewPermission && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.ENDORSEMENT
              ? "endorsement active"
              : "endorsement"
          }
          buttonText={
            <p>
              Endorsemen<span>t</span>
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.ENDORSEMENT}
        />
      )}

      {/* Trending Questions Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.TRENDING_QUESTION
              ? "trendingquestions active"
              : "trendingquestions"
          }
          buttonText={
            <p>
              Trendin<span>g</span> Questions
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.TRENDING_QUESTION}
        /> */}

      {/* Community Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.COMMUNITY
              ? "community active"
              : "community"
          }
          buttonText={
            <p>
              Co<span>m</span>munity
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.COMMUNITY}
        /> */}

      {/* Support Tab */}
      {supportViewPermission && (
        <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.SUPPORT
              ? "support active"
              : "support"
          }
          buttonText={
            <p>
              S<span>u</span>pport
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.SUPPORT}
        />
      )}

      {/* Settings Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.SETTING
              ? "settings active"
              : "settings"
          }
          buttonText={
            <p>
              <span>S</span>etting
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.SETTING}
        /> */}
    </Box>
  );
}

export default SideNavBar;
