import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import { useState } from "react";
import ARMultiInputField from "./ARMultiInputField";
import { toast } from "react-toastify";

const ARMultiInputFieldDisable = ({
    class_name,
    title = "",
    value = [],
    attrName = "",
    value_update = () => { },
    warn_status = false,
    error_messg = "Enter valid value",
    disabled,
    required,
    params,
    stopEdit,
}: {
    class_name: string;
    title: string;
    value: Array<string>;
    attrName: any;
    value_update: any;
    warn_status: boolean | undefined;
    error_messg?: string;
    required?: boolean;
    disabled?: boolean;
    params?: any;
    stopEdit?:boolean;
}) => {
    const labels = value?.map((item: any) => item.label).join(', ');
    const [disbaleToggle, setDisableToggle] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [currentValue, setcurrentValue] = useState<string>("");
    const edit = () => {
        if(disbaleToggle && currentValue){
          return toast.warn(`Please press ENTER to add this ${title}`) 
        }
        setDisableToggle(!disbaleToggle);
        setFocus(!focus);
        if (disbaleToggle === true) {
            if (attrName) return params(attrName);
            params();
        }
    };


    return (
        <Box className="individualEdit">
            {disbaleToggle === false ? (
                <div
                    className={
                        value != null
                            ? value?.length === 0
                                ? "staticField nullValue"
                                : "staticField"
                            : "staticField"
                    }
                >
                    <label>{title}</label>
                    <p className="truncate_text">{labels}</p>
                </div>
            ) : (
                <ARMultiInputField
                    class_name={class_name}
                    title={title}
                    value={value}
                    attrName={attrName}
                    onchange={(a:any)=>{
                        setcurrentValue(a)
                    }}
                    value_update={value_update}
                    warn_status={warn_status}
                    error_messg={error_messg}
                    required={required}
                    disabled={disabled}
                    stopEdit={stopEdit}
                />
            )}
            {!disabled && (
                <Link
                    className={
                        disbaleToggle === true ? "editUpdate visible" : "editUpdate"
                    }
                    onClick={edit}
                >
                    {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
                </Link>
            )}
        </Box>
    );
};

export default ARMultiInputFieldDisable;
