import { createSlice } from "@reduxjs/toolkit";
import { TUserData } from "../../../Services/Types/TUserData";
import {
  UserDetailsReducer,
  USER_DETAILS_REDUCERS,
} from "./UserDetailsReducers";

export const userDetailsSlice = createSlice<TUserData, UserDetailsReducer>({
  name: "user_detail",
  initialState: {
    _id: "",
    companyCode: "",
    "Company Id": "",
    createdAt: "",
    expireIn: "",
    isVerified: false,
    roleName: "",
    updatedAt: "",
    username: "",
    userType: "",
    support_email: "",
    createdBy: "",
    ACCESS_TOKE: "",
    USER_TOKEN: "",
    localInformation: {
      country: "",
      currency: "",
      dateFormat: "",
      reportBasis: "",
      timeZone: "",
      countryCurrencyCode: "",
    },
    portals: {
      broker_portal: false,
      client_portal: false,
      employee_portal: false,
    },
    appearance: { theme: 0 },
    addressDetails: {
      address1: "",
      address2: "",
      city: "",
      mobileNumber: "",
      phoneNumber: "",
      pinCode: "",
      state: "",
    },
    logo: "",
    "Organization Email": "",
    organizationDetails: {
      companyId: "",
      fiscalyear: "",
      industry: "",
      orgnizationName: "",
      subIndustry: "",
      taxId: "",
      url: "",
    },
    userId: "",
    parentsId: "",
    status: "",
    Organisation: "",
    clientId: "",
    id: "",
  },
  reducers: USER_DETAILS_REDUCERS,
});
