//used
import { Button, Grid, Link, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import GlobalDeletePopup from "../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import MassUpdate from "../../../../Components/Common/MassUpdate/MassUpdate";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { CONTACTS_SERVICES } from "../../../../Services/Contact/ContactService";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { TUserData } from "../../../../Services/Types/TUserData";
import { bulkUploadSlice } from "../../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import { RootState } from "../../../../Store/Store";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { Delete_Multiple, Get } from "../../../../Supporting files/CallAPI";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
import {
  date_time_format,
  isValidDate,
} from "./../../../../Supporting files/HelpingFunction";

const Contacts = ({
  shortcutDisable,
  setShortcutDisable,
}: {
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}) => {
  const dispatch = useAppDispatch();
  const [removeContact, setRemoveContact] = React.useState(false);
  const [deleteStatusStep, setDeleteStatusStep] = React.useState(1);
  const [massupdateopen, setmassupdateopen] = React.useState(false);
  const navigate = useNavigate();
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const contact_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Contact
  );
  const [contactsBulkUplodPermission, setContactsBulkUplodPermission] =
    useState(false);
  const [contactsEiditPermission, setContactsEiditPermission] = useState(false);
  const [contactsAddPermission, setContactsAddPermission] = useState(false);
  const [contactsRemovePermission, setContactsRemovePermission] =
    useState(false);
  const [contactsCsvPermission, setContactsCsvPermission] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    get_contact_data(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    get_contact_data(tableLimit ?? 25, 0, "csv");
    handleClose();
  };
  let timeout: any;

  useEffect(() => {
    setContactsBulkUplodPermission(
      contact_permission?.upload === undefined
        ? false
        : contact_permission.upload
    );
    setContactsEiditPermission(
      contact_permission?.edit === undefined ? false : contact_permission.edit
    );
    setContactsAddPermission(
      contact_permission?.add === undefined ? false : contact_permission.add
    );
    setContactsRemovePermission(
      contact_permission?.remove === undefined
        ? false
        : contact_permission.remove
    );
    setContactsCsvPermission(
      contact_permission?.csv === undefined ? false : contact_permission.csv
    );

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
        get_contact_data();
        get_header_field();
    }

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  const [tableData, setTableData] = useState<Array<{}>>([]);
  const [headerData, setHeaderData] = useState<Array<{}>>([]);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [edit_popup, setEdit_popup] = useState<boolean>(false);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [headerDataMassUpadte, setheaderDataMassUpadte] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();

  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    if(removeContact){
      get_header_field();
    }
  }, [removeContact]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArray_Delete(value);
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName === "massupdateopen") {
      setmassupdateopen(false);
      get_contact_data(tableLimit || 25, 0);
    } else {
      attrName(value);
    }
  };
  const edit_navigate = () => {
    if (array_delete.length === 0) {
      return setEdit_popup(true);
    } else if (array_delete.length > 1) {
      return setEdit_popup(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_CONTACT}?id=${array_delete[0]}`);
    }
  };
  const customComparator = (v1: any, v2: any) => {
    const regex = /(\D+)(\d+)/;
    const [, a1, n1] = v1.match(regex);
    const [, a2, n2] = v2.match(regex);

    if (a1 === a2) {
      return parseInt(n1, 10) - parseInt(n2, 10);
    }
    return a1.localeCompare(a2);
  };
  const delete_contact_multiple = () => {
    let params = {
      ids: array_delete,
    };
    Delete_Multiple(URL_CONSTANTS.delete_multiple_contacts, params)
      .then((res) => {
        if (res.data.status === "success") {
          if (
            res.data.message ===
            "Some of Contacts cannot be deleted as they are assigned to locations of  Clients/Insurers/TPAs"
          ) {
            toast.info(res.data.message);
            setRemoveContact(false);
            get_contact_data(tableLimit, tableSkip);
          } else {
            setDeleteStatusStep(2);
            get_contact_data(tableLimit, tableSkip);
          }
        } else {
          get_contact_data(tableLimit, tableSkip);
          // setDeleteStatus(false);
        }
      })
      .catch((error) => {
        let data = error?.response?.data;
        let status = data.status;
        let errors = data.errors[0].message;
        if (status === "failed") {
        }
        if (error.code !== "ERR_BAD_REQUEST") {
        }
        alert(errors);
        return [error, {}];
      });
  };

  const get_header_field = () => {
    Get(URL_CONSTANTS.get_contact_header_field)
      .then((res) => {
        setheaderDataMassUpadte(res.data.data);
        let data: any = [];
        res.data.data.forEach((e: any, index: number) => {
          if (e.field === "First Name") {
            data.push({
              ...e,
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return (
                  <Link
                    underline="none"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `${ALL_ROUTES.CONTACT_DETAIL_VIEW}?id=${row.id}`
                      );
                    }}
                  >
                    {row["First Name"]}
                  </Link>
                );
              },
            });
          } else if (e.field === "ID") {
            data.push({
              field: "ID",
              headerName: "ID",
              width: 150,
              sortComparator: customComparator,
            });
          } else if (e.field === "Date Of Birth") {
            data.push({
              ...e,
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return (
                  <span>
                    {isValidDate(row["Date Of Birth"])
                      ? date_time_format(
                          row["Date Of Birth"],
                          userState.localInformation.dateFormat,
                          userState.localInformation.timeZone
                        )
                      : "--"}
                  </span>
                );
              },
            });
          } else if (e.field === "Email") {
            data.push({
              ...e,
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return (
                  <Link
                    underline="none"
                    style={{ cursor: "pointer" }}
                    href={`mailto:${row.Email}`}
                  >
                    {row.Email}
                  </Link>
                );
              },
            });
          } else {
            data.push(e);
          }
        });
        setHeaderData(
          data.concat(
            {
              field: "createdAt",
              minWidth: 200,
              check_status: true,
              headerName: "Created At",
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return (
                  <span>
                    {date_time_format(row.createdAt, user_type_dateFormat, tz)}
                  </span>
                );
              },
            },
            {
              field: "updatedAt",
              minWidth: 200,
              check_status: true,
              headerName: "Updated At",
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return (
                  <span>
                    {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
                  </span>
                );
              },
            }
          )
        );
      })
      .catch((error) => {
        alert(error);
        return [error, {}];
      });
  };

  const get_contact_data = (pageSize?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      if (csv) {
       return downloadCsv(res.data.data.data);
      }
      setTableData(res.data.data.data);
      setTotalRecords(res.data.data.countData);

    };

    const onError = (err: any) => {
      console.log(err);
    };
    const currentRoute = E_BE_FILTER_CODE.CONTACT.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : []; 
    const existingEntryIndex = parsedData.find((entry: any) => entry.route === currentRoute);
    CONTACTS_SERVICES.get_contact_list(
      onSuccess,
      onError,
      pageSize ? pageSize : existingEntryIndex?.pageNumber?? 25,
      skip ? skip : 0,
      startEndDate.startDate ? startEndDate.startDate : "",
      startEndDate.endDate ? startEndDate.endDate : "",
      searchBarFilter ? searchBarFilter : ""
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if(searchBarFilter ||startEndDate.endDate){
        get_contact_data();
      }
    };

    timeoutId = setTimeout(delayedApiCall, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter,startEndDate]);

  // useEffect(() => {
  //   get_contact_data();
  // }, [startEndDate]);

  const downloadCsv = (csvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "contacts.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    const updatedArr = csvData?.map((item: any) => {
      return {
        ...item,
        "Date Of Birth":
          item["Date Of Birth"] !== "--"
            ? date_time_format(item["Date Of Birth"], user_type_dateFormat, tz)
            : item["Date Of Birth"],
        "Created At": date_time_format(
          item?.createdAt,
          user_type_dateFormat,
          tz
        ),
        "Updated At": date_time_format(
          item?.updatedAt,
          user_type_dateFormat,
          tz
        ),
      };
    });

    headerData.forEach((item: any) => {
      item.field = item.headerName;
    });
    let dto = {
      headers: headerData,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userState.userType.toLowerCase(),
      "contact"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={2} sx={{ paddingBottom: "50px" }}>
        <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Contacts</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>

        <Grid
          item
          xs={8}
          className="text-right"
          style={{ alignSelf: "center" }}
        >
          <UGDateRangePicker
            placement="bottom"
            value_update={updateMasterState}
          />
          {tableData?.length > 0 && contactsCsvPermission && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          {array_delete.length > 1 && contactsEiditPermission && (
            <Link
              sx={{ height: "15px" }}
              underline="none"
              className="greenBtn edit"
              onClick={() => {
                setmassupdateopen(true);
                if (!shortcutDisable && setShortcutDisable) {
                  setShortcutDisable(true);
                }
              }}
            >
              Mass Update
            </Link>
          )}
          {contactsBulkUplodPermission && (
            <Link
              sx={{ height: "15px" }}
              title="Bulk Upload"
              underline="none"
              className="blueBtn upload"
              onClick={() => {
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadData(
                    bulkUploadSlice.getInitialState()
                  )
                );
                navigate({
                  pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                  search: createSearchParams({
                    calledFrom: ALL_ROUTES.CONTACTS,
                  }).toString(),
                });
              }}
            >
              Bulk Upload
            </Link>
          )}
          {contactsEiditPermission && array_delete.length > 0 && (
            <Link
              sx={{ height: "15px" }}
              title="Edit"
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {contactsRemovePermission && array_delete.length > 0 && (
            <Link
              sx={{ height: "15px" }}
              title="Remove"
              underline="none"
              className="redBtn delete"
              onClick={() => {
                setRemoveContact(true);
              }}
            >
              Remove
            </Link>
          )}
          {contactsAddPermission && (
            <Link
              sx={{ height: "15px" }}
              title="Add"
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_CONTACT);
              }}
            >
              Add Contact
            </Link>
          )}
        </Grid>

        <Grid item xs={12}>
          <UGTable
            header_data={headerData}
            data={tableData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.CONTACT}
            refetch_data={get_contact_data}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>
        <GlobalDeletePopup
          open_status={removeContact}
          value_update={updateMasterState}
          attrName={setRemoveContact}
          delete_step={deleteStatusStep}
          delete_item={delete_contact_multiple}
          ids={array_delete}
          title="Contact"
        />
        <GlobelEditPopup
          popupStatus={edit_popup}
          setPopupStatus={setEdit_popup}
          title={
            array_delete.length === 0
              ? "Select Contact Id"
              : array_delete.length > 1
              ? `Select Only One Id`
              : ""
          }
        />
        <MassUpdate
          open_status={massupdateopen}
          value_update={updateMasterState}
          selected_id={array_delete}
          get_Api_url={CONTACTS_SERVICES.massModification}
          update_Api_url={CONTACTS_SERVICES.updateMassmodification}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
          forComponent="Contact"
        />
      </Grid>
    </>
  );
};
export default Contacts;
