import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { GENERAL_SERVICES } from "../../../../../../Services/Settings/General/General";
import { MAIL_SETTINGS_SERVICES } from "../../../../../../Services/Settings/MailConfig/MailConfigService";
import { userDetailsSlice } from "../../../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";

function EmailSettings() {
  const [showloader, setshowloader] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userId = useAppSelector(
    (state: RootState) => state.userDetailsSlice._id
  );
  const settingId = useAppSelector(
    (state: RootState) => state.userDetailsSlice.config_settings.mail
  );
  const { BROKER } = useAppSelector(
    (state: RootState) => state?.permissionSlice
  );
  const [editEmailPermission, setEmailEditPermission] = useState(false);
  const [productTabsStatus, setProductTabsStatus] = useState("");
  console.log("product tab status", productTabsStatus);
  const [security, setSecurity] = useState("None");
  const [isEdit, setIsEdit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [systemData, setMailConfig] = useState({
    _id: "",
    security: false,
    type: "",
    smtp: "",
    port: 0,
    user_name: "",
    password: "",
    from_email: "",
    to_email: "",
    for: "",
    sub_type: "",
    //createdBy: "",
    userId: "",
    createdAt: "",
    updatedAt: "",
    creds: {
      api_key: "",
      from_email: "",
      to_email: "",
      smtp: "",
      port: 0,
      user_name: "",
      password: "",
    },
  });
  const [inputFields, setInputFields] = useState({
    _id: "",
    security: false,
    type: "",
    smtp: "",
    port: 0,
    user_name: "",
    password: "",
    from_email: "",
    to_email: "",
    for: "",
    sub_type: "",
    //createdBy: "",
    userId: "",
    createdAt: "",
    updatedAt: "",
    creds: {
      api_key: "",
      from_email: "",
      to_email: "",
      smtp: "",
      port: 0,
      user_name: "",
      password: "",
    },
  });
  const [dropDownData, setDropDownData] = useState<any[]>([]);
  const [testLink, setTestLink] = useState(false);

  useEffect(() => {
    getDropDownData();
  }, [isEdit]);

  useEffect(() => {
    if (settingId) {
      getConfigurationDetails(settingId);
    }
  }, [settingId]);
  useEffect(() => {
    setEmailEditPermission(
      BROKER?.EmailSetting?.edit === undefined
        ? false
        : BROKER?.EmailSetting?.edit
    );
  });
  const getDropDownData = () => {
    const onSuccess = (res: any) => {
      setDropDownData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GENERAL_SERVICES.getEmailDropDown(onSuccess, onError);
  };

  const getEmailData = (settingId: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        //getConfigurationDetails(res.data.data.config_settings.mail);
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GENERAL_SERVICES.getEmailData(onSuccess, onError, userId, settingId);
  };
  const getConfigurationDetails = (id: any) => {
    const onSuccess = (res: any) => {
      if (res?.data?.data !== null) {
        setMailConfig(res?.data?.data);
        setInputFields(res?.data?.data);
        setProductTabsStatus(res?.data?.data?.sub_type);
        setshowloader(false);
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
      setshowloader(false);
    };
    setshowloader(true);
    GENERAL_SERVICES.getConfigurationDetails(id, onSuccess, onError);
  };

  const updateMailConfig = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      if (res?.data?.data !== null) {
        setMailConfig(res?.data?.data);
        setInputFields(res?.data?.data);
      }
      setDisableSave(false);
    };
    const onError = (err: any) => {
      setDisableSave(false);
      console.log("false", err);
    };
    setDisableSave(true);
    GENERAL_SERVICES.updateMailConfig(
      {
        for: inputFields.for,
        type: inputFields.type,
        sub_type: inputFields.sub_type,
        creds: inputFields.creds,
      },
      onSuccess,
      onError
    );
  };

  const callTestApi = () => {
    const selectedObject = dropDownData.find(
      (item) => item.label === productTabsStatus
    );

    // const settingId = selectedObject.id;

    const onSuccess = (res: any) => {
      if (isEdit) {
        setIsValid(true);
      }
      if (res?.data?.data !== null) {
        Swal.fire({
          title: "Mail Setting Configured!",
          text: "Check your inbox for test mail!",
          icon: "success",
        });
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Failed to configure email settings: This might be due to the server being down, network issues, or incorrect server settings. Please Try again!`,
      });
    };
    console.log(
      {
        for: inputFields.for,
        type: inputFields.type,
        sub_type: inputFields.sub_type,
        creds: inputFields.creds,
      },
      "prem>>"
    );
    MAIL_SETTINGS_SERVICES.testMail(
      {
        for: inputFields.for,
        type: inputFields.type,
        sub_type: inputFields.sub_type,
        creds: inputFields.creds,
      },
      onSuccess,
      onError
    );
  };

  //master state update function
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "input_fields") {
      setIsValid(false);
      let data = inputFields;
      const field = attrName[1];
      data = {
        ...data,
        creds: {
          ...data.creds,
          [field]: value,
        },
      };
      //  setMailConfig(data);
      setInputFields(data);
    } else if (attrName[1] === "dropdown") {
      const selectedLabel = value;
      const selectedObject = dropDownData.find(
        (item) => item.label === selectedLabel
      );
      const settingId = selectedObject ? selectedObject.id : null;
      //setId(settingId);
      dispatch(
        userDetailsSlice.actions.updateEmailConfig({
          for: "mail",
          data: settingId,
        })
      );
      getEmailData(settingId);
      setProductTabsStatus(value);
    } else if (typeof attrName === "function") return attrName(value);
    // setMailConfig({ ...systemData, [attrName[1]]: value });
  };

  //form submits functions
  const detail_form_submit = async () => {
    //set form error for required true field
    setIsEdit(!isEdit);
    setIsValid(false);
    updateMailConfig();
  };

  const performTest = () => {
    callTestApi();
  };

  //dynamic jsx function call
  const showEditFields = (isEdit: boolean) => {
    return isEdit ? (
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Mail Settings</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Smtp</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {/* <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"SMTP Server"}
                      value={systemData.smtp}
                      attrName={["input_fields", "smtp"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Port"}
                      value={systemData.port}
                      attrName={["input_fields", "port"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Security"
                      value={security}
                      attrName={["email_config", "dropdown"]}
                      value_update={updateMasterState}
                      dropdown_data={[
                        { label: "None", id: "none" },
                        { label: "TLS", id: "tls" },
                      ]}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Username"}
                      value={systemData.user_name}
                      attrName={["input_fields", "username"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Password"}
                      value={systemData.password}
                      attrName={["input_fields", "password"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Frome email address"}
                      value={systemData.from_email}
                      attrName={["input_fields", "from_email"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid> */}
                  {/* <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"To email address"}
                      value={systemData.to_email}
                      attrName={["input_fields", "to_email"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid> */}

                  {Object.entries(inputFields?.creds || {}).map(
                    ([key, value]) => (
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={key.replace(/_/g, " ").toUpperCase()}
                          value={value}
                          attrName={["input_fields", key]}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : null;
  };

  const customFields = (isEdit: boolean) => {
    return (
      <>
        {showEditFields(isEdit)}
        <Grid container spacing={3} className="footerSection">
          {editEmailPermission && (
            <Grid xs={12} className="ctaBtn">
              {!isEdit && ( // Show buttons when not in edit mode
                <>
                  <Button
                    onClick={() => {
                      callTestApi(); // Call test API
                    }}
                    variant="outlined"
                    className="borderBtn"
                  >
                    Test Link
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(true); // Enable edit mode
                    }}
                    variant="contained"
                    className="submitBtn"
                  >
                    Edit
                  </Button>
                </>
              )}
              {isEdit && ( // Show buttons in edit mode
                <>
                  <Button
                    onClick={() => {
                      setIsEdit(false); // Exit edit mode
                      setIsValid(false); // Reset test link state
                    }}
                    variant="outlined"
                    className="borderBtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={callTestApi} // Show Test Link button in edit mode
                    variant="outlined"
                    className="borderBtn"
                  >
                    Test Link
                  </Button>
                  {isValid && ( // Show Update button only if test link was successful
                    <Button
                      onClick={detail_form_submit} // Keep original onClick
                      variant="contained"
                      className="submitBtn"
                      disabled={disableSave}
                    >
                      Update
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  return (
    <>
      {showloader && <NewLoader />}
      <Box className="settingMenuWrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h3 className="mb-0">Email Configurations</h3>
          </Grid>
          {/* <div className="sp-embed-player" data-id="cZQbiHVSj6O" style={containerStyle}>
      <script src="https://go.screenpal.com/player/appearance/cZQbiHVSj6O"></script>
      <iframe
        style={iframeStyle}
        src="https://go.screenpal.com/player/cZQbiHVSj6O?width=800&height=600&ff=1&title=0"
        title="Embedded Player"
      ></iframe>
    </div> */}

          <Grid xs={5}>
            <div className="status_dropdown" id={"1"}>
              {true ? (
                <CheckCircleIcon id={`Active`} />
              ) : true ? (
                <HighlightOffIcon id={`Active`} />
              ) : null}
              <SelectDropdown
                class_name="inputFieldd"
                title="Choose Email Configuration"
                value={productTabsStatus}
                attrName={["email_config", "dropdown"]}
                value_update={updateMasterState}
                dropdown_data={dropDownData}
                warn_status={false}
                disabled={!editEmailPermission}
              />
            </div>
          </Grid>
          <Grid xs={12}>
            {productTabsStatus === "SYSTEM" || "SENDGRID" ? (
              <>
                {!isEdit && (
                  <div className="logosection">
                    <h4 style={{ textAlign: "left" }}>
                      Configuration Settings
                    </h4>
                    <ul className="detail_list">
                      {!isEdit &&
                        Object.entries(systemData?.creds || {}).map(
                          ([key, value]) => (
                            <li key={key}>
                              <p>{key.replace(/_/g, " ").toUpperCase()}</p>
                              <h6 style={{ textAlign: "left" }}>
                                {value?.toString()}
                              </h6>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                )}
              </>
            ) : null}
            {productTabsStatus ? customFields(isEdit) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EmailSettings;
