import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import GlobalDeletePopup from "../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import "../../../../../Components/Broker/PolicyHistory/PolicyHistory.scss";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import { PROFILE_SERVICE } from "../../../../../Services/Employee/ProfileService";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  add_date_format,
  downloadAttachement,
  updateTimeFormat,
} from "../../../../../Supporting files/HelpingFunction";
import AddFamilyKyc from "./AddPopup/AddKyc";
import EditFamily from "./EditPopup/EditFamily";
import { RootState } from "../../../../../Store/Store";
import AddFamily from "../../../Broker/Employees/EmployeeDetails/AddPopup/AddFamily";

function EmployeeDetailProfile() {
  const usertypeinfo = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const [selectedVehicleData, setSelectedVehicleData] = useState<any>({});
  const [removePopup, setRemovePopup] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [addKyc, setAddKyc] = useState<boolean>(false);
  const [employee_kyc_data, setEmployee_kyc_data] = useState<any[]>([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [addFamily, setAddFamily] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [addKycEmployee, setAddKycEmployee] = useState(false);
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const [selectedPropertyData, setSelectedPropertyData] = useState<any>({});
  const [selectedFamilyData, setSelectedFamilyData] = useState<any>({});
  const [family_data, setFamily_data] = useState<any[]>([]);
  const [family_kyc, setFamily_kyc] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [kyc_id, setKyc_id] = useState<string>("");
  const [deleteStepDiff, setDeleteStepDiff] = useState<number>(0);
  const [delete_kyc, setDelete_kyc] = useState("");
  const [sectionStatus, SetSectionStatus] = useState<string>("familyDeatils");
  const familyDeatils = useRef(null);
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setAddPermission] = useState(false);

  useEffect(() => {
    setAddPermission(
      EMPLOYER?.Employees?.add === undefined ? false : EMPLOYER?.Employees?.add
    );
  }, []);
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  let ids =
    selectedVehicleData._id ||
    selectedPropertyData._id ||
    selectedFamilyData._id
      ? [
          {
            ids:
              selectedVehicleData._id ||
              selectedPropertyData._id ||
              selectedFamilyData._id,
          },
        ]
      : [];

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setRemovePopup) {
      return setRemovePopup(false);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else {
      attrName(value);
    }
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const empId = params.get("id") || "";
    setId(empId);
    get_family_detail(empId ?? "");
    // get_family_kyc_detail(empId);
  }, [removePopup, addFamily, editFamily, addKyc]);
  console.log("selectedFamilyData", selectedFamilyData);

  const get_family_detail = (id: string) => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data[0].members.forEach((el: any) => {
        switch (el.relationWithEmployee?.toLowerCase()) {
          case "daughter":
            data.push({
              ...el,
              img: "./images/girl.svg",
            });
            break;
          case "son":
            data.push({
              ...el,
              img: "./images/boy.svg",
            });
            break;
          case "sister":
            data.push({
              ...el,
              img: "./images/wife.svg",
            });
            break;
          case "brother":
            data.push({
              ...el,
              img: "./images/husband.svg",
            });
            break;
          case "husband":
            data.push({
              ...el,
              img: "./images/husband.svg",
            });
            break;
          case "wife":
            data.push({
              ...el,
              img: "./images/wife.svg",
            });
            break;
          case "father":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          case "mother":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "mother-in-law":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "mother in law":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "father-in-law":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          case "father in law":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          default:
            break;
        }
      });
      if (!selectedFamilyData?.fullName && data.length > 0) {
        // setSelectedFamilyData(data[0]);
      }
      get_family_kyc_detail(data.length > 0 && data[0]._id);
      setFamily_data(data);
    };
    const onError = (err: any) => {
      // console.log("err", err);
    };
    PROFILE_SERVICE.get_family_detail_list(
      onSuccess,
      onError,
      id,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const get_family_kyc_detail = (_id?: string) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id: any = params.get("id");
    const onSuccess = (res: any) => {
      setFamily_kyc(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    let memberId = _id ? _id : delete_kyc;
    PROFILE_SERVICE.get_family_kyc_detail_list(
      onSuccess,
      onError,
      `${URL_CONSTANTS.get_family_kyc_detail(
        id,
        memberId,
        usertypeinfo.toLocaleLowerCase()
      )}`
    );
  };

  //common delete function
  const delete_property_vehicle = () => {
    const onSuccess = (res: any) => {
      console.log("ressss", res);
      setDeleteStatusStep(2);
      setSelectedFamilyData({});
      setSelectedVehicleData({});
      setSelectedPropertyData({});
    };
    const onError = (err: any) => {
      console.log("error", err);
    };

    if (selectedVehicleData._id && deleteStepDiff === 2) {
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_vehicle_delete}${selectedVehicleData._id}`,
        onSuccess,
        onError
      );
    } else if (selectedPropertyData._id && deleteStepDiff === 3) {
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_property_detail}${selectedPropertyData._id}`,
        onSuccess,
        onError
      );
    } else if (selectedFamilyData._id && deleteStepDiff === 1) {
      const onSuccess = (res: any) => {
        setDeleteStatusStep(2);
        console.log("after deleting", res);
        setSelectedVehicleData({});
        setSelectedFamilyData({});
        setFamily_data(res.data.data.members);
      };
      const onError = (err: any) => {
        console.log("error", err);
      };
      let remian = `&type=members&id=${selectedFamilyData._id}`;
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_family_detail(
          id,
          remian,
          usertypeinfo.toLocaleLowerCase()
        )}`,
        onSuccess,
        onError
      );
    } else if (deleteStepDiff === 4) {
      let remian = `&type=kyc&id=${kyc_id}`;
      PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_family_detail(
          id,
          remian,
          usertypeinfo.toLocaleLowerCase()
        )}`,
        onSuccess,
        onError
      );
    } else if (deleteStepDiff === 5) {
      PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_kyc_detail(kyc_id, id)}`,
        onSuccess,
        onError
      );
    } else {
      toast.error("Select Data to delete");
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "familyDeatils"
                  ? "active sectionLink familyDeatils"
                  : "sectionLink familyDeatils"
              }
              onClick={() => {
                scrollToSection(familyDeatils);
                SetSectionStatus("familyDeatils");
              }}
            >
              <span> Family Details</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <Box className="detailSection mt-4" ref={familyDeatils}>
            <BulkUploadForm
              open_status={uploadFile}
              attrName={setUploadFile}
              value_update={updateMasterState}
            />
            <div className="sectionTitle">
              <h4>Family Details</h4>
              <div className="actionBtns ">
                {deleteStepDiff === 1 && !selectedFamilyData.dependentId && (
                  <>
                    {selectedFamilyData?.dob && family_data.length > 0 && (
                      <>
                        <Link
                          underline="none"
                          className="redBtn delete"
                          onClick={() => {
                            // if (deleteStepDiff === 2 || deleteStepDiff === 3) {
                            //   return toast.error("Select Family Member to delete");
                            // } else if (selectedFamilyData.dependentId) {
                            //   toast.error('Cannot delete family member which is a dependent')
                            // } else {
                            //   setRemovePopup(true);
                            //   setDeleteStepDiff(1);
                            // }
                            if (selectedFamilyData.dependentId) {
                              return toast.error(
                                "Cannot delete family member which is a dependent"
                              );
                            } else {
                              setRemovePopup(true);
                              setDeleteStepDiff(1);
                            }
                          }}
                        >
                          Remove
                        </Link>
                        <Link
                          underline="none"
                          className="blueBtn edit"
                          onClick={() => {
                            if (
                              selectedFamilyData._id &&
                              !selectedFamilyData.dependentId
                            ) {
                              setEditFamily(true);
                            } else if (selectedFamilyData.dependentId) {
                              toast.error(
                                "Cannot edit family member which is a dependent"
                              );
                            } else {
                              toast.error("Select family member");
                            }
                          }}
                        >
                          Edit
                        </Link>
                      </>
                    )}
                  </>
                )}

                {addPermission && (
                  <Link
                    className="blueBtn addfile mr-3"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setAddFamily(true)}
                  >
                    Add Family Member
                  </Link>
                )}
                <EditFamily
                  open_status={editFamily}
                  attrName={setEditFamily}
                  value_update={updateMasterState}
                  data={selectedFamilyData}
                  addedMemberData={family_data}
                />
              </div>
            </div>
            <Box className="profile_detail">
              {family_data?.map((data, index) => (
                <div className="profileTypeSection">
                  <div
                    className={
                      selectedFamilyData._id === data._id
                        ? "radioBox_two check"
                        : "radioBox_two"
                    }
                    onClick={() => {
                      setDeleteStepDiff(1);
                      setSelectedFamilyData(data);
                      get_family_kyc_detail(data._id);
                      setDelete_kyc(data._id);
                    }}
                  >
                    <input
                      type="radio"
                      id="individual"
                      name="plantype"
                      defaultChecked
                    />
                    <label>
                      <img src={data.img} alt="" />
                    </label>

                    <h6>{data.fullName}</h6>
                    <p>
                      {data.relationWithEmployee}
                      <span>{add_date_format(data.dob)}</span>
                      <span>{data.dependentId}</span>
                    </p>
                  </div>
                </div>
              ))}

              {/* table data */}
              <Grid container spacing={3} width={"100%"}>
                <Grid xs={12} textAlign={"right"}>
                  <div className="actionBtns ">
                    {addPermission && (
                      <Link
                        className="blueBtn addfile"
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (selectedFamilyData?._id) {
                            setAddKyc(true);
                          } else {
                            toast.error("select family  Member");
                          }
                        }}
                      >
                        Add KYC Document
                      </Link>
                    )}
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className="table">
                    <div
                      className="attatchment_table"
                      style={{ overflow: "hidden" }}
                    >
                      <ul className="heading">
                        <li>File Name</li>
                        <li>Document Type</li>
                        <li>Description</li>
                        <li>Date</li>
                        <li>Actions</li>
                      </ul>

                      {family_kyc?.map((data: any) => (
                        <ul className="subheading">
                          <li>{data.kyc.doc_name}</li>
                          <li>{data.kyc.doc_type}</li>
                          <li>{data.kyc.description}</li>
                          <li>
                            <p className="date">
                              {updateTimeFormat(
                                data.kyc.date,
                                user_type_dateFormat
                              )}
                            </p>
                          </li>

                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Link
                              className="blueBtn download ml-0"
                              // href={data.kyc.downloadLink}
                              onClick={() =>
                                downloadAttachement(
                                  data.kyc.downloadLink,
                                  usertypeinfo
                                )
                              }
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                            {/* <Link
                              className="redBtn delete"
                              onClick={() => {
                                setKyc_id(data.kyc._id);
                                setDeleteStepDiff(4);
                                setRemovePopup(true);
                                // setDelete_kyc(data._id);
                                // delete_property_vehicle(data._id);
                              }}
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link> */}
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <AddFamily
        attrName={setAddFamily}
        open_status={addFamily}
        value_update={updateMasterState}
        apiUrl={""}
        filterDropDown={true}
        addedMemberData={family_data}
      />
      <AddFamilyKyc
        attrName={setAddKyc}
        open_status={addKyc}
        value_update={updateMasterState}
        id={selectedFamilyData._id}
        apiUrl={""}
      />
      <AddFamilyKyc
        attrName={setAddKycEmployee}
        open_status={addKycEmployee}
        value_update={updateMasterState}
        id={""}
        apiUrl={URL_CONSTANTS.create_kyc_detail}
      />
      <GlobalDeletePopup
        open_status={removePopup}
        value_update={updateMasterState}
        attrName={setRemovePopup}
        delete_step={deleteStatusStep}
        delete_item={() => {
          delete_property_vehicle();
        }}
        ids={deleteStatusStep != 1 || 2 || 3 ? [{ ids: "123" }] : ids}
        title="Data"
      />
    </>
  );
}

export default EmployeeDetailProfile;
