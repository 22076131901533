import EditIcon from "@mui/icons-material/Edit";
import { Collapse, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useRef } from "react";
import { AccessLevelDTO } from "../../../Services/DTO/Settings/AccessLevel/AccessLevelDTO";
import "../../../formLayout.scss";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DownloadIcon from "@mui/icons-material/Download";
import EmojiFlagsRoundedIcon from "@mui/icons-material/EmojiFlagsRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface Props {
  value_update: Function;
  dataArray: Array<AccessLevelDTO>;
}

const ModulePermissionAccessControl: React.FC<Props> = ({
  value_update,
  dataArray,
}) => {
  // for scrolling
  const modulePermission = useRef(null);

  const updateStatus = (
    id: Array<string>,
    updateValue: boolean,
    key: keyof any
  ) => {
    const data: any[] = [...dataArray];
    if (id.length === 1) {
      const index = data.findIndex((obj: any) => obj.data.id === id[0]);
      if (key === "child_open_status") {
        data[index] = { ...data[index], [key]: updateValue };
      } else {
        data[index].data.view = true;
        data[index].data[key] = updateValue;
      }
    } else if (id.length === 2) {
      const parent_index = data.findIndex((obj: any) => obj.data.id === id[0]);
      const parent_data = data[parent_index];
      const child_index = parent_data.child.findIndex(
        (obj: any) => obj.data.id === id[1]
      );
      if (key === "child_open_status") {
        data[parent_index].child[child_index].child_open_status =
          !data[parent_index].child[child_index].child_open_status;
      } else {
        data[parent_index].child[child_index].data.view = true;
        data[parent_index].child[child_index].data[key] =
          !data[parent_index].child[child_index].data[key];
      }
    } else if (id.length === 3) {
      const parent_index = data.findIndex((obj: any) => obj.data.id === id[0]);
      const parent_data = data[parent_index];

      const child_index = parent_data.child.findIndex(
        (obj: any) => obj.data.id === id[1]
      );
      const child_data = parent_data.child[child_index];

      const child_of_child_index = child_data.child.findIndex(
        (obj: any) => obj.data.id === id[2]
      );
      if (key === "child_open_status") {
        data[parent_index].child[child_index].child_open_status =
          !data[parent_index].child[child_index].child_open_status;
      } else {
        data[parent_index].child[child_index].child[
          child_of_child_index
        ].data.view = true;
        data[parent_index].child[child_index].child[child_of_child_index].data[
          key
        ] = updateValue;
      }
    }

    value_update("datarrrrrr", data ? data : dataArray);
  };

  return (
    <div ref={modulePermission}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h5 className="subHeading">Module Permission</h5>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="module_table">
            <div
              className="module_permission_table"
              style={{ overflow: "hidden" }}
            >
              <ul className="heading">
                <li>Module Name</li>
                <li>
                  <VisibilityIcon />
                  View
                </li>
                <li>
                  <EditIcon />
                  Edit
                </li>
                <li>
                  <DownloadIcon />
                  CSV (Download)
                </li>
                <li>
                  <FileUploadRoundedIcon />
                  Bulk Upload
                </li>
                <li>
                  <EmojiFlagsRoundedIcon />
                  Status
                </li>
                <li>
                  <AddCircleOutlineRoundedIcon />
                  Add
                </li>
                <li>
                  <RemoveRoundedIcon />
                  Remove
                </li>
              </ul>
              <div className="module_table_inner">
                {dataArray.map((data, index) => (
                  <>
                    {
                      <ul className="subheading parent">
                        <li
                          className={
                            data.data &&
                            data.data.module_name === "Settings" &&
                            data.child_open_status === false &&
                            data.data.id === "AB13"
                              ? "collapse_plus setting_plus"
                              : data.data &&
                                data.data.module_name === "Settings" &&
                                data.child_open_status === true &&
                                data.data.id === "AB13"
                              ? "collapse_minus setting_minus"
                              : ""
                          }
                        >
                          <p
                            onClick={() => {
                              data.data &&
                                updateStatus(
                                  [data.data.id],
                                  !data.child_open_status,
                                  "child_open_status"
                                );
                            }}
                          >
                            {data.data && data.data.module_name}
                          </p>

                          {data.child_open_status ? (
                            <Collapse in={data.child_open_status}>
                              {data?.child?.map((child_data, index) => (
                                <>
                                  <ul
                                    className={
                                      child_data.child_open_status === true
                                        ? "subheading child collapse_minus"
                                        : "subheading child collapse_plus"
                                    }
                                  >
                                    <li>
                                      <p
                                        className="setting_parent"
                                        onClick={() => {
                                          data.data &&
                                            child_data.data &&
                                            updateStatus(
                                              [
                                                data.data.id,
                                                child_data.data.id,
                                              ],
                                              !child_data.child_open_status,
                                              "child_open_status"
                                            );
                                        }}
                                      >
                                        {child_data.data &&
                                          child_data.data.module_name}
                                      </p>
                                      {child_data.child_open_status ? (
                                        <Collapse
                                          in={child_data.child_open_status}
                                        >
                                          {child_data?.child?.map(
                                            (child_of_child_data, index) => (
                                              <>
                                                <ul
                                                  className={
                                                    (child_data?.child
                                                      ?.length === undefined
                                                      ? 0
                                                      : child_data?.child
                                                          ?.length - 1) ===
                                                    index
                                                      ? "subheading childofchild module_table_line_last"
                                                      : "subheading childofchild module_table_lines"
                                                  }
                                                >
                                                  <li>
                                                    {
                                                      child_of_child_data.data
                                                        .module_name
                                                    }
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data
                                                            .view_disable &&
                                                          !child_of_child_data
                                                            .data.view
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.view
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.view
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              child_of_child_data.data &&
                                                              child_of_child_data
                                                                .data.edit ===
                                                                false &&
                                                              child_of_child_data
                                                                .data.csv ===
                                                                false &&
                                                              child_of_child_data
                                                                .data.upload ===
                                                                false &&
                                                              child_of_child_data
                                                                .data.status ===
                                                                false &&
                                                              child_of_child_data
                                                                .data.add ===
                                                                false &&
                                                              child_of_child_data
                                                                .data.remove ===
                                                                false &&
                                                              !child_of_child_data
                                                                .data
                                                                .view_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "view"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data
                                                            .edit_disable &&
                                                          !child_of_child_data
                                                            .data.edit
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.edit
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.edit
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .edit_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "edit"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data.csv_disable &&
                                                          !child_of_child_data
                                                            .data.csv
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.csv
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.csv
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .csv_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "csv"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data
                                                            .upload_disable &&
                                                          !child_of_child_data
                                                            .data.upload
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.upload
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.upload
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .upload_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "upload"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data
                                                            .status_disable &&
                                                          !child_of_child_data
                                                            .data.status
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.status
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.status
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .status_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "status"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data.add_disable &&
                                                          !child_of_child_data
                                                            .data.add
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.add
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.add
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .add_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "add"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="Checkbox">
                                                      <Tooltip
                                                        title={
                                                          child_of_child_data
                                                            .data
                                                            .remove_disable &&
                                                          !child_of_child_data
                                                            .data.remove
                                                            ? "Feature not available"
                                                            : ""
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          name="check"
                                                          checked={
                                                            child_of_child_data
                                                              .data.remove
                                                          }
                                                          defaultChecked={
                                                            child_of_child_data
                                                              .data.remove
                                                          }
                                                          onChange={(e) => {
                                                            data.data &&
                                                              child_data.data &&
                                                              !child_of_child_data
                                                                .data
                                                                .remove_disable &&
                                                              updateStatus(
                                                                [
                                                                  data.data.id,
                                                                  child_data
                                                                    .data.id,
                                                                  child_of_child_data
                                                                    .data.id,
                                                                ],
                                                                e.target
                                                                  .checked,
                                                                "remove"
                                                              );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <label />
                                                    </div>
                                                  </li>
                                                </ul>
                                              </>
                                            )
                                          )}
                                        </Collapse>
                                      ) : null}
                                    </li>
                                    {child_data?.child === undefined ? (
                                      <>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.view_disable &&
                                                !child_data.data.view
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.view
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.view
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    child_data.data.edit ===
                                                      false &&
                                                    child_data.data.csv ===
                                                      false &&
                                                    child_data.data.upload ===
                                                      false &&
                                                    child_data.data.status ===
                                                      false &&
                                                    child_data.data.add ===
                                                      false &&
                                                    child_data.data.remove ===
                                                      false &&
                                                    !child_data.data
                                                      .view_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "view"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.edit_disable &&
                                                !child_data.data.edit
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.edit
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.edit
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .edit_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "edit"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.csv_disable &&
                                                !child_data.data.csv
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.csv
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.csv
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .csv_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "csv"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .upload_disable &&
                                                !child_data.data.upload
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.upload
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.upload
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .upload_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "upload"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .status_disable &&
                                                !child_data.data.status
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.status
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.status
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .status_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "status"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.add_disable &&
                                                !child_data.data.add
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.add
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.add
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .add_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "add"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .remove_disable &&
                                                !child_data.data.remove
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.remove
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.remove
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .remove_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "remove"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                      </>
                                    ) : child_data?.child.length === 0 ? (
                                      <>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.view_disable &&
                                                !child_data.data.view
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.view
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.view
                                                }
                                                onChange={(e) => {
                                                  data.data &&
                                                    child_data.data &&
                                                    !child_data.data
                                                      .view_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "view"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.edit_disable &&
                                                !child_data.data.edit
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.edit
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.edit
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .edit_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "edit"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.csv_disable &&
                                                !child_data.data.csv
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.csv
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.csv
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .csv_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "csv"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .upload_disable &&
                                                !child_data.data.upload
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.upload
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.upload
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .upload_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "upload"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .status_disable &&
                                                !child_data.data.status
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.status
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.status
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .status_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "status"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data.add_disable &&
                                                !child_data.data.add
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.add
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.add
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .add_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "add"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                        <li>
                                          <div className="Checkbox">
                                            <Tooltip
                                              title={
                                                child_data.data &&
                                                child_data.data
                                                  .remove_disable &&
                                                !child_data.data.remove
                                                  ? "Feature not available"
                                                  : ""
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  child_data.data &&
                                                  child_data.data.remove
                                                }
                                                defaultChecked={
                                                  child_data.data &&
                                                  child_data.data.remove
                                                }
                                                onChange={(e) => {
                                                  child_data.data &&
                                                    data.data &&
                                                    !child_data.data
                                                      .remove_disable &&
                                                    updateStatus(
                                                      [
                                                        data.data.id,
                                                        child_data.data.id,
                                                      ],
                                                      e.target.checked,
                                                      "remove"
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                            <label />
                                          </div>
                                        </li>
                                      </>
                                    ) : null}
                                  </ul>
                                </>
                              ))}
                            </Collapse>
                          ) : null}
                        </li>
                        {data?.child === undefined ? (
                          <>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.view_disable &&
                                    !data.data.view
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.view}
                                    defaultChecked={data.data && data.data.view}
                                    onChange={(e) => {
                                      if (data.data) {
                                        if (
                                          data.data.edit === false &&
                                          data.data.csv === false &&
                                          data.data.upload === false &&
                                          data.data.status === false &&
                                          data.data.add === false &&
                                          data.data.remove === false &&
                                          !data.data.view_disable
                                        ) {
                                          updateStatus(
                                            [data.data.id],
                                            e.target.checked,
                                            "view"
                                          );
                                        }
                                      }
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.status_disable &&
                                    !data.data.status
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.edit}
                                    defaultChecked={data.data && data.data.edit}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.edit_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "edit"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.csv_disable &&
                                    !data.data.csv
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.csv}
                                    defaultChecked={data.data && data.data.csv}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.csv_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "csv"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.upload_disable &&
                                    !data.data.upload
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.upload}
                                    defaultChecked={
                                      data.data && data.data.upload
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.upload_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "upload"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.status_disable &&
                                    !data.data.status
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.status}
                                    defaultChecked={
                                      data.data && data.data.status
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.status_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "status"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.add_disable &&
                                    !data.data.add
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.add}
                                    defaultChecked={data.data && data.data.add}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.add_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "add"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.remove_disable &&
                                    !data.data.remove
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.remove}
                                    defaultChecked={
                                      data.data && data.data.remove
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.remove_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "remove"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                          </>
                        ) : data?.child.length > 0 ? null : (
                          <>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.view_disable &&
                                    !data.data.view
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.view}
                                    onChange={(e) => {
                                      data.data &&
                                        data.data.edit === false &&
                                        data.data.csv === false &&
                                        data.data.upload === false &&
                                        data.data.status === false &&
                                        data.data.add === false &&
                                        data.data.remove === false &&
                                        !data.data.view_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "view"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.edit_disable &&
                                    !data.data.edit
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.edit}
                                    defaultChecked={data.data && data.data.edit}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.edit_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "edit"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.csv_disable &&
                                    !data.data.csv
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.csv}
                                    defaultChecked={data.data && data.data.csv}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.csv_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "csv"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.upload_disable &&
                                    !data.data.upload
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.upload}
                                    defaultChecked={
                                      data.data && data.data.upload
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.upload_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "upload"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.status_disable &&
                                    !data.data.status
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.status}
                                    defaultChecked={
                                      data.data && data.data.status
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.status_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "status"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.add_disable &&
                                    !data.data.add
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.add}
                                    defaultChecked={data.data && data.data.add}
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.add_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "add"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                            <li>
                              <div className="Checkbox">
                                <Tooltip
                                  title={
                                    data.data &&
                                    data.data.remove_disable &&
                                    !data.data.remove
                                      ? "Feature not available"
                                      : ""
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name="check"
                                    checked={data.data && data.data.remove}
                                    defaultChecked={
                                      data.data && data.data.remove
                                    }
                                    onChange={(e) => {
                                      data.data &&
                                        !data.data.remove_disable &&
                                        updateStatus(
                                          [data.data.id],
                                          e.target.checked,
                                          "remove"
                                        );
                                    }}
                                  />
                                </Tooltip>
                                <label />
                              </div>
                            </li>
                          </>
                        )}
                      </ul>
                    }
                  </>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModulePermissionAccessControl;
