import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import "../Attachment/Attachment.scss";
import { useParams } from "react-router-dom";
import deleteimage from "./deletepopup_deleteicon.svg";
import tickicon from "./deletePopup_tickicon.svg";
import moment from "moment";
import "moment-timezone";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { useAppSelector } from "../../../../../Store/hooks";
import { RootState } from "../../../../../Store/Store";
import BulkUploadForm from "./BulkUploadFormSP/BulkUploadForm";
import SlidingPanel from "react-sliding-side-panel";
import { GridCloseIcon } from "@mui/x-data-grid";
import {
  allowFileExtention,
  downloadAttachement,
  uploadImage,
} from "../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { supporting_document_data } from "../../../../../Components/Broker/Endorsment/AddNewRequestSP/dropdownDataJson";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import { toast } from "react-toastify";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  dataArray?: any;
  endoresemetId: any;
  downnloadCsvPermission?: boolean;
}

const Attachment: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  dataArray,
  endoresemetId,
  downnloadCsvPermission,
}) => {
  useEffect(() => {
    setTableData(dataArray === undefined ? [] : dataArray);
  }, [open_status, value_update]);

  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [tableData, setTableData] = React.useState<Array<any>>([]);
  const [uploadFile, setUploadFile] = React.useState(false);
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
    docType: "",
  });
  const updateMasterState = (attrName: any, value: any) => {
    let data: any = displayData;
    if (attrName[0] === "docType") {
      data = { ...data, docType: value,warning:false };
    }
    setDisplayData(data);
  };

  const submit_data = () => {
    let dto: any = {
      endorsementId: endoresemetId,
      attachments: {
        doc: displayData.doc,
        doc_name: displayData.doc_name,
        docType: displayData.docType,
      },
    };
    const onSuccess = (res: any) => {
      value_update("close_attachment", false);
      value_update("attachment_updated", false);
      setDisplayData({
        doc: "",
        warning: false,
        description: "",
        warnDescription: false,
        doc_name: "",
        docType: "",
      });
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };
    if (!displayData.docType ||!displayData.doc ) {
      setDisplayData({
        ...displayData,
        warning: !displayData.docType,
        warnDescription: !displayData.doc,
      })
      return;
    }
    ENDORSEMENT_SERVICES.upoad_attachment_endorsement(
      `${URL_CONSTANTS.endorsement_upload_attachment(
        userState?.userType?.toLowerCase()
      )}`,
      dto,
      onSuccess,
      onError
    );
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="attatchment_table" style={{ overflow: "hidden" }}>
              <ul className="heading">
                <li>File Name</li>
                <li>Document Type</li>
                <li>Date</li>
                <li>Time</li>
                <li>Actions</li>
              </ul>

              {tableData?.map((data, index) => (
                <ul key={data.id} className="subheading">
                  <li>{data?.doc_name}</li>
                  <li>{data?.docType}</li>
                  <li>
                    <p className="date">
                      {moment(data?.date).format(
                        userState.localInformation.dateFormat
                      )}
                    </p>
                  </li>
                  <li>
                    <p className="time">
                      {moment(data?.time).utcOffset("+05:30").format("hh:mm A")}
                    </p>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {downnloadCsvPermission && (
                      <Link
                        className="blueBtn download ml-4"
                        // href={data.downloadLink}
                        onClick={() =>
                          downloadAttachement(
                            data.downloadLink,
                            userState.userType
                          )
                        }
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      ></Link>
                    )}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Upload your Document</h4>
            </Grid>
            <Grid xs={2} textAlign={"end"}>
              <GridCloseIcon
                onClick={() => {
                  value_update("close_attachment", false);
                  setDisplayData({
                    doc: "",
                    warning: false,
                    description: "",
                    warnDescription: false,
                    doc_name: "",
                    docType: "",
                  });
                }}
              />
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Supporting Documents"
                  value={displayData.docType}
                  attrName={["docType", "supportDoc"]}
                  value_update={updateMasterState}
                  dropdown_data={supporting_document_data}
                  warn_status={displayData.warning}
                  error_messg="Select Supporting Document"                />
              </Grid>
              <Grid
                container
                spacing={0}
                justifyContent="center"
                className="mb-5"
              >
                <Grid xs={10} className="inputField uploadFile">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Upload .xl .csv .xls .pdf file"
                    variant="outlined"
                    className="textarea"
                    value={displayData.doc_name}
                    disabled
                  />
                </Grid>
                <Grid xs={2}>
                  <Button
                    variant="contained"
                    className="browsebtn"
                    component="label"
                  >
                    Browse
                    <input
                      hidden
                      multiple
                      type="file"
                      style={{ zIndex: "99" }}
                      onChange={(e: any) => {
                        const file = e.target.files[0];
                        const allowExt = allowFileExtention(file);
                        if (!allowExt) {
                          toast.warn(
                            "Invalid file type. Please upload a PDF, DOC, or Image file."
                          );
                          return (e.target.value = null);
                        }
                        uploadImage(
                          file,
                          (base64Image: any, base64String: any) => {
                            setDisplayData((prevDisplayData: any) => ({
                              ...prevDisplayData,
                              warning:
                                base64Image === "" ||
                                prevDisplayData.data === ""
                                  ? true
                                  : false,
                              doc: base64Image,
                              doc_name: e.target.files[0]?.name,
                              description: prevDisplayData.data,
                              warnDescription:false,
                            }));
                          }
                        );
                      }}
                    />
                  </Button>
                  {displayData.warnDescription === true ? (
                                <span style={{color:"#eb5757" , fontSize:"11px"}}>Upload logo</span>
                              ) : null}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={submit_data}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </Box>
  );
};

export default Attachment;
