import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  get_client_list,
  validateEmail,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../formLayout.scss";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";

function CreateUsers() {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [customRoleName, setCustomRoleName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState("");
  const [department, setDepartment] = useState("");
  const [singleUse, setSingleUse] = useState("");
  const [selectClient, setSelectClient] = useState("");
  const [searchList, setSearchList] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Array<{
    checkbox_status: boolean;
    id: number;
    name: string;
  }>>([
    {
      checkbox_status: false,
      id: 0,
      name: "All Clients",
    },
    {
      checkbox_status: false,
      id: 1,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 2,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 3,
      name: "EV0016 - Kartike Kakkar",
    },
    {
      checkbox_status: false,
      id: 4,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 5,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 6,
      name: "EV0016 - Kartike Kakkar",
    },
    {
      checkbox_status: false,
      id: 7,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 8,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 9,
      name: "EV0016 - Kartike Kakkar",
    },
  ]);
  const [usersRole, setUsersRole] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const [id, setId] = useState<string>("");
  const [priorityStatus, setPriorityStatus] = useState("");
  const [editUser, setEditUser] = useState<any>({
    name: "",
    email: "",
    dob: null,
    roleName: "",
    branch: { label: "", id: "" },
    department: { label: "", id: "" },
    clientName: "",
    client: { label: "", id: "" },
    warnName: false,
    warnEmail: false,
    warnRolename: false,
    warnBranch: false,
    warnDepartment: false,
  });
  const [department_data, setDepartment_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const [branch_data, setBranch_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const [password, setPassword] = useState("");
  const [warn_password, setWarn_Password] = useState(false);
  const [clientListData, setClientListData] = useState<Array<{
    checkbox_status: boolean;
    label: string;
    id: string;
  }>>([]);

  // for scrolling
  const userInformation = useRef(null);
  const basics = useRef(null);
  const personalDetails = useRef(null);
  const addressDetails = useRef(null);
  const clientInfo = useRef(null);

  useEffect(() => {
    client_list();
    user_role_data();
  }, []);

  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    user_data(id ?? "");
    get_Dropdown_branch_list(EMPLOYER_URL_CONSTANTS.get_employer_location_list);
    get_Dropdown_branch_list(EMPLOYER_URL_CONSTANTS.get_employer_department_list);
  }, []);

  const get_Dropdown_branch_list = (url: string) => {
    const onSuccess = (res: any) => {
      if (url === EMPLOYER_URL_CONSTANTS.get_employer_location_list) {
        let data: any = res.data.data.map(
          ({ _id, name }: { _id: string; name: string }) => ({
            id: _id,
            label: name,
          })
        );
        setBranch_data(data);
      } else if (url === EMPLOYER_URL_CONSTANTS.get_employer_department_list) {



        let newVar = res.data.data[0].value.map((item: any) => (
          {
            id: item._id,
            label: item.label,
          }
        ))
        setDepartment_data(newVar);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      url,
      onSuccess,
      onSuccess
    );
  };

  const searchHandler = (event: any) => {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = clientListData.filter((el) => {
        let searchValue = el.label.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    setClientListData(displayedContacts);
    setSearchList(true);
  };

  const selectClientsFromList = (index: number, check_status: boolean) => {
    let data = clientListData;
    data[index].checkbox_status = check_status;
    setClientListData(data);
  };

  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      setEditUser(res.data.data[0]);
      setClientListData(res.data.data[0].clientName);
    };
    const onError = (err: any) => {
      console.log(".......,,", err);
    };
    ACCESS_LEVEL_SERVICES.user_data_by_id(id, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "editUser") {
      setEditUser({
        ...editUser,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      attrName(value);
      setWarn_Password(false);
    }
  };
  const scrollToSection = (elementRef: any) => { elementRef.current?.scrollIntoView({ behavior: "smooth" }); };

  const handleClicks = (ref: any) => { ref.current?.scrollIntoView({ behavior: "smooth" }); };

  const client_list = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data?.forEach((e: any) => {
        data.push({
          ...e,
          checkbox_status: false,
        });
      });
      setClientListData(data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  const user_role_data = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => { return { label: data.id, value: data.label } })
      setUsersRole(statusData);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ACCESS_LEVEL_SERVICES.get_user_role_data_employer("", onSuccess, onError);
  };
  const detail_form_submit = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const dynamicPart = Math.random().toString().slice(2, 6);
    let companyId = `USER${month}${year}${dynamicPart}`;
    let data: any = editUser;
    data.warnName = editUser?.name && editUser?.name?.length === 0 ? true : false;
    data.warnEmail = !validateEmail(editUser.email);
    data.warnRolename = editUser?.roleName === "" ? true : false;
    data.warnBranch = editUser.branch === "" ? true : false;
    data.warnDepartment = editUser.department === "" ? true : false;
    setWarn_Password(password === "" ? true : false);

    setEditUser({ ...data });
    if (
      data.warnName === false &&
      data.warnEmail === false &&
      data.warnRolename === false &&
      data.warnBranch === false &&
      data.warnDepartment === false &&
      password !== ""
    ) {
      let params = {
        companyCode: companyId,
        employer_parent_id: userDetails.companyCode,
        name: editUser.name,
        email: editUser.email,
        username: editUser.email,
        userType: userDetails.userType,
        role: editUser.roleName.id ?? editUser.roleName,
        branch: editUser.branch.id ?? editUser.branch,
        Department: editUser.department.id ?? editUser.department,
        client: "All",
        clientName: [],
        password: password,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success("User Created Successfully");
      };
      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
      };
      ACCESS_LEVEL_SERVICES.create_new_employer_user(params, onSuccess, onError);
    }
  };
  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Create New User</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "userInformation"
                    ? "active sectionLink userInformation"
                    : "sectionLink userInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(userInformation);
                  SetSectionStatus("userInformation");
                }}
              >
                <span> User Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basics"
                        ? "inner_link active basics"
                        : "inner_link  basics"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(basics);
                      setSubSection("basics");
                    }}
                  >
                    Basic Info
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={
                      subSection === "personalDetails"
                        ? "inner_link active personalDetails"
                        : "inner_link  personalDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(personalDetails);
                      setSubSection("personalDetails");
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "addressDetails"
                        ? "inner_link active addressDetails"
                        : "inner_link  addressDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(addressDetails);
                      setSubSection("addressDetails");
                    }}
                  >
                    Address Details
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className={
                      subSection === "clientInfo"
                        ? "inner_link active clientInfo"
                        : "inner_link clientInfo"
                    }
                    onClick={() => {
                      setSubSection("clientInfo");
                      handleClicks(subSection);
                      scrollToSection(clientInfo);
                    }}>
                    Client Info
                  </Link>
                </li> */}
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={userInformation}>
                <div ref={basics}>
                  <div className="sectionTitle">
                    <h4>User Information</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Basic Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="name"
                        class_name="inputField"
                        title={"Name"}
                        value={editUser?.name}
                        attrName={["editUser", "name"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnName}
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Email"}
                        value={editUser?.email}
                        attrName={["editUser", "email"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnEmail}
                      />
                    </Grid>
                    {/* <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Support Email"}
                        value={editUser?.supportEmail}
                        attrName={["editUser", "supportEmail"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnSupportEmail}
                      />
                    </Grid> */}

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Role"
                        value={editUser?.roleName === undefined ? "" : editUser?.roleName}
                        attrName={["editUser", "roleName"]}
                        value_update={updateMasterState}
                        dropdown_data={usersRole}
                        warn_status={editUser?.warnRolename}
                        error_messg="Select Role"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Branch"
                        value={editUser?.branch === undefined ? "" : editUser?.branch}
                        attrName={["editUser", "branch"]}
                        value_update={updateMasterState}
                        data={branch_data}
                        warn_status={editUser.warnBranch}
                        error_message="Select Branch"
                        info_status={true}
                        info_message="This is the branch list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Department"
                        value={editUser?.department === undefined ? "" : editUser?.department}
                        attrName={["editUser", "department"]}
                        value_update={updateMasterState}
                        data={department_data}
                        warn_status={editUser.warnDepartment}
                        error_message="Select department"
                        info_status={true}
                        info_message="This is the department list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Password"
                        value={password}
                        attrName={setPassword}
                        value_update={updateMasterState}
                        warn_status={warn_password}
                        error_messg="Enter Password"
                        type="password"
                        autoComplete="new-password"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="submitBtn"
            onClick={detail_form_submit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateUsers;
