/* eslint-disable no-loop-func */
import { InputAdornment } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PolicyHistory from "../../../../../Components/Broker/PolicyHistory/PolicyHistory";
import SelectvoluntryPlans from "../../../../../Components/Broker/SelectVoluntryPlans/SelectVoluntryPlans";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { quotesDTONew } from "../../../../../Services/DTO/QuotesDTO";
import { one_to_ten_data } from "../../../../../Services/DropDowndata";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { PolicyCoverageType } from "../../../../../Services/Enum/EPolicy";
import { premiumType } from "../../../../../Services/Enum/EPremiumPaymentType";
import { SumInsuredLimit } from "../../../../../Services/Enum/ESumInsuredLimits";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import {
  add_days_in_date_getDateObject,
  areAgeBandsConsistent,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  get_industry_list,
  get_subindustry_list,
  subtract_days_in_date_getDateObject,
  titleCase,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import Exclusion from "../../Quotes/Exclusion/Exclusion";
import SelectFeature from "../../Quotes/SelectFeature/SelectFeature";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function AddNewQuotes() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { formatCurrency } = useFormatCurrency();
  const navigate = useNavigate();
  const [others, setOthers] = useState("");
  const taxPercentage = 0.18;
  const [ageBandData, setAgeBandData] = useState<
    Array<{ min_age: string; max_age: string }>
  >([{ min_age: "", max_age: "" }]);
  const [sectionStatus, SetSectionStatus] = useState<string>(
    "organizationDetails"
  );
  const [livesSelectedGrade, setlivesSelectedGrade] = useState<
    Array<{ selectedGrade: number }>
  >([{ selectedGrade: 0 }]);
  const [planType, setPlanType] = useState(0);
  const [policyType, setPolicyType] = useState(0);
  const [uploadFile, setUploadFile] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [isExistingClinet, setisExistingClinet] = useState("existing");
  const [dynamicLocationOffice, setdynamicLocationOffice] = useState<
    Array<Array<{ key: string; value: string }>>
  >([]);
  const [indivisualGradeLives, setindivisualGradeLives] = useState(0);
  const [showNominee, setShowNominee] = useState(false);
  const [showhealth, setShowhealth] = useState(false);
  const [showcorporate, setShowcorporate] = useState(false);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [addFeature, setAddFeature] = useState(false);
  const [selectvoluntryPlans, setSelectVoluntryPlans] = useState(false);
  const [Addexclusion, setAddExclusion] = useState(false);
  const [AddFeatureexclusionIndex, setAddFeatureExclusionIndex] =
    useState<number>(0);
  const [liveGraded, setliveGraded] = useState(0);
  const [clientListdata, setClientListdata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [organizationFullDetails, setorganizationFullDetails] = useState<any>(
    {}
  );
  const [minage_data, setminage_data] = useState<any>([]);
  const [maxage_data, setmaxage_data] = useState<any>([]);
  const [empGradeData, setEmpGradeData] = useState<
    Array<{
      sum_insured: string;
      emp_count: string;
      deleteable: boolean;
      features: Array<string>;
    }>
  >([{ sum_insured: "", emp_count: "", deleteable: false, features: [] }]);
  const [clientLocationList, setClientLocationList] = useState<any>({});
  const [selectedClientId, setSelectedClientId] = useState<any>("");
  const [dropdownSalarydesignationDep, setdropdownSalarydesignationDep] =
    useState<any>([]);
  const [
    showdropdownSalarydesignationDep,
    setshowdropdownSalarydesignationDep,
  ] = useState<any>([]);
  const [IndustryData, setIndustryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [subIndustryData, setSubIndustryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [insurerList, setInsurerList] = useState<any>([]);
  const [insurerAddressList, setInsurerAddressList] = useState<any>([]);
  const [featuresListdata, setfeaturesListdata] = useState<any>([]);
  const [exclusionsListdata, setexclusionsListdata] = useState<any>([]);
  const [featuresListdataGraded, setfeaturesListdataGraded] = useState<any>([]);
  const [featuresListdataGradedCopy, setfeaturesListdataGradedCopy] =
    useState<any>([]);
  const [exclusionsListdataGraded, setexclusionsListdataGraded] = useState<any>(
    []
  );
  const [showLoader, setShowLoader] = useState(true);
  const [exclusionsListdataGradedCopy, setexclusionsListdataGradedCopy] =
    useState<any>([]);
  const [basicConfig_data, setbasicConfig_data] = useState<any>([
    { key: "1E", value: "1E" },
  ]);
  const [indvConfig_data, setindvConfig_data] = useState<any>([
    { key: "Nill", value: "Nill" },
  ]);
  const ownership_data = [
    { id: "Private Limited", label: "Private Limited" },
    { id: "Public Limited", label: "Public Limited" },
    {
      id: "Limited liability partnership",
      label: "Limited liability partnership",
    },
    { id: "Private Companies", label: "Private Companies" },
    { id: "Sole Proprietorship", label: "Sole Proprietorship" },
    { id: "Public Companies", label: "Public Companies" },
  ];
  const prorata = [
    {
      key: "YEARLY",
      value: "Yearly",
    },
    { key: "MONTHLY", value: "Monthly" },
    { key: "WEEKLY", value: "Weekly" },
    { key: "DAILY", value: "Daily" },
  ];
  const [maxage_dataClone, setmaxage_dataClone] = useState<any>([]);
  const [ageBandMax, setageBandMax] = useState<string>("");
  const [taxDropDown, settaxDropDown] = useState<any>([]);
  const [modifiedArrayAgeBand, setModifiedArrayAgeBand] = useState<any>([]);
  console.log("ageData",modifiedArrayAgeBand)
  const [addPoliciesdata, setAddPoliciesdata] = useState<quotesDTONew>({
    organisationDetails: {
      id: "55555a555555a555a55aaa55",
      clientId: "",
      organisationName: "",
      ownership: "",
      webSite: "",
      industry: "",
      sub_industry: "",
      email: "",
      mobile: "",
      location: [
        {
          name: "",
          city: "nill",
          state: "nill",
          country: "nill",
        },
      ],
    },
    productType: {
      name: "Group Super TopUp",
      code: "5",
    },
    policyCase: {
      name: "FRESH",
      code: 0,
    },
    insurerDetails: [
      {
        id: "",
        name: "",
        office: "",
        email: "",
      },
    ],
    planType: {
      name: PolicyCoverageType.FLAT,
      coverageType: [
        {
          code: 0,
          name: "EMPLOYEE",
        },
      ],
      basicConfig: "1E",
      parentConfig: "Nill",
      familyDefinition: "",
      gradeCriteria: "nill",
      setOfParents: "both",
    },
    payType: {
      type: premiumType.SPLIT,
      data: [
        {
          name: "EMPLOYEE",
          value: 0,
        },
      ],
    },
    is_rider: false,
    rider: [
      {
        name: "",
        description: "",
      },
    ],
    benefits_detail_flat: [
      {
        label: "",
        enable: false,
        amount: "",
        description: "",
      },
    ],
    benefits_detail_graded: [
      {
        grade_name: "Grade 1",
        riders: [
          {
            label: "",
            enable: false,
            amount: "",
            description: "",
          },
        ],
      },
    ],
    rider_premium_graded: [
      {
        proreta_by: "YEARLY",
        rider_name: "",
        premium_split: "",
        livesInfoGrade: [
          {
            gradeName: "Grade 1",
            data: [
              {
                min: "",
                max: "",
                coverageTypeData: [
                  {
                    name: "amount",
                    value: "500",
                  },
                  {
                    code: "0",
                    name: "EMPLOYEE",
                    value: "",
                  },
                  {
                    name: "total Live",
                    value: 0,
                  },
                ],
                name: "age band",
              },
            ],
          },
        ],
      },
    ],
    rider_premium_flat: [
      {
        proreta_by: "YEARLY",
        premium_split: "",
        rider_name: "",
        livesInfoFlat: [
          {
            min: "",
            max: "",
            name: "",
            coverageTypeData: [
              {
                code: "",
                name: "",
                value: 0,
              },
            ],
          },
        ],
      },
    ],
    maxChildAge: "",
    ageBand: [
      {
        name: "",
        max: "",
        min: "",
      },
    ],
    benefitsDetailsflat: {
      sumInsured: "",
      deductible: "",
      features: [
        {
          name: "",
          value: "",
          featureId: "",
          groupId: "",
        },
      ],
      exclusion: [
        {
          name: "",
          description: "",
          exclusionId: "",
        },
      ]
    },
    benefitsDetailsGrade: [
      {
        gradeDivisions: [{ id: "", label: "" }],
        name: "",
        sumInsured: "",
        deductible: "",
        features: [
          {
            name: "",
            value: "",
            featureId: "",
            groupId: "",
          },
        ],
        exclusion: [
          {
            name: "",
            description: "",
            exclusionId: "",
          },
        ],
      },
    ],
    premiumDetails: {
      netPremium: 0,
      taxPercentage: [{ id: "", label: "" }],
      taxAmount: "nill",
      grossPremium: "nill",
    },
    policyDetails: {
      startDate: new Date(),
      endDate: new Date(),
      cdBalance: "nill",
    },
    policy: {
      nomination: false,
      maxNomination: 0,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
      isCorporateBuffer: false,
      corporateBuffer: null,
      proreta_by: "YEARLY",
    },
    livesInfoFlat: [
      {
        min: "",
        max: "",
        name: "",
        coverageTypeData: [
          {
            code: "",
            name: "",
            value: 0,
          },
        ],
      },
    ],
    livesInfoGrade: [],
  });
  const [errorMaxChildAge, seterrorMaxChildAge] = useState(false);
  function isNumberInAgeBand(number: number): boolean {
    let error = true;  // Initialize as true, assume there's an error

    addPoliciesdata.ageBand.some((band: any) => {
        let min = band.min.includes('Up to') ? 0 : (band.min ? parseInt(band.min) : 0);
        let max = band.max ? parseInt(band.max) : Infinity;

        // If the number is within the range, set error to false
        if (number >= min && number <= max) {
            error = false;
            return true; // Stop further iteration as a valid band is found
        }

        // Continue searching other bands if the number is not within the current band
        return false;
    });

    seterrorMaxChildAge(error);
    return error;
}

useEffect(() => {
  if(addPoliciesdata.planType.coverageType.some(
    (obj) => obj.code === 2
  )){
    isNumberInAgeBand(Number(extractNumbersFromString(addPoliciesdata.maxChildAge || "")))
   
  }else{
    seterrorMaxChildAge(false);
  }
}, [...addPoliciesdata.ageBand,...addPoliciesdata.maxChildAge,...addPoliciesdata.planType.coverageType]);

  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [addPoliciesdataWarn, setAddPoliciesdataWarn] = useState<any>({
    employeeId: false,
    organisationDetails: {
      clientId: false,
      parentClientId: false,
      parentOrganistion: false,
      email: false,
      website: false,
      organisationName: false,
      ownership: false,
      sub_industry: false,
      industry: false,
      mobile: false,
      location: false,
    },

    productType: {
      name: false,
      code: false,
    },
    policyCase: {
      name: false,
      code: false,
    },
    rider: [
      {
        name: false,
        description: false,
      },
    ],
    benefits_detail_flat: [
      {
        label: false,
        enable: false,
        amount: false,
        description: false,
      },
    ],
    rider_premium_flat: [
      {
        proreta_by: false,
        premium_split: false,
        rider_name: false,
        livesInfoFlat: [
          {
            min: false,
            max: false,
            name: false,
            coverageTypeData: [
              {
                code: false,
                name: false,
                value: false,
              },
            ],
          },
        ],
      },
    ],
    insurerDetails: [
      {
        name: false,
        office: false,
        email: false,
      },
    ],
    planType: {
      name: false,
      coverageType: [
        {
          name: false,
          code: false,
        },
      ],
      basicConfig: false,
      parentConfig: false,
      familyDefinition: false,
    },
    ageBand: [
      {
        name: false,
        max: false,
        min: false,
      },
    ],
    benefitsDetailsGrade: [
      {
        designation: false,
        deductible: false,
        name: false,
        sumInsured: false,
        features: [
          {
            name: false,
            value: false,
            featureId: false,
            groupId: false,
          },
        ],
        exclusion: [
          {
            name: false,
            description: false,
            exclusionId: false,
          },
        ],
      },
    ],
    benefitsDetailsflat: {
      deductible: false,
      sumInsured: false,
      features: false,
      exclusion: [
        {
          name: false,
          description: false,
        },
      ],
    },
    premiumDetails: {
      netPremium: false,
      taxPercentage: false,
      grossPremium: false,
    },
    policyDetails: {
      startDate: false,
      endDate: false,
      cdBalance: false,
    },
    policy: {
      criticalCd: false,
      criticalPercentage: false,
      enrolmentStartDate: false,
      enrolmentEndDate: false,
      period: false,
      gracePeriod: false,
      maxMemberPerFamily: false,
      totalMemberPerFamily: false,
      newMemberPeriod: false,
      nomination: false,
      maxNomination: false,
      corporateBuffer: false,
      isCorporateBuffer: false,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
    },
    liveswarning: false,
  });
  const [expanded, setExpanded] = useState([{ expanded: false }]);
  const [premiumPaymentType, setPremiumPaymentType] = useState<premiumType>(
    premiumType.SPLIT
  );

  const getMaxAge = (str: string) => {
    const index = maxage_data.findIndex(
      (dateObj: any) => dateObj.value === str
    );
    if (index >= 0) {
      return maxage_data.slice(index + 1);
    }
    return maxage_data;
  };
  let timeout: any;
  useEffect(() => {
    filterDropDownData();
  }, [dropdownSalarydesignationDep]);

  useEffect(() => {

    setShowLoader(true);
    if (timeout) {
      clearTimeout(timeout)
    }
    const delayedApiCall = () => {
      
      get_client_list();
    YearOptions();
    get_policy_tax();
    // Api for feature list
    features_list(5);
    // Api for insurer list
    insurance_name_dropdownlist("GENERAL");
    // Api for exclusion list
    exclusion_list(5);
    get_industry_list((cb: any) => {
      setIndustryData(cb);
    });
    }
 
    timeout = setTimeout(delayedApiCall, 900);
 
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };    
  }, []);

  // for scrolling
  const organizationDetails = useRef(null);
  const products = useRef(null);
  const quoteCase = useRef(null);
  const plantype = useRef(null);
  const premiumPayment = useRef(null);
  const AgeBand = useRef(null);
  const EmployeesDetails = useRef(null);
  const PlansSettings = useRef(null);
  const InsurerDetails = useRef(null);
  const RelationshipLives = useRef(null);
  const riders = useRef(null);
  const RiderDetail = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const YearOptions = () => {
    const options = [];
    options.push({ label: "Up to 1 Year", value: "Up to 1 Year" });
    for (let i = 2; i <= 100; i++) {
      const label = `${i} ${i === 1 ? "Year" : "Years"}`;
      const value = `${i} Years`;
      options.push({ label, value });
    }
    setminage_data(options);
    setmaxage_dataClone(options);
    setmaxage_data(options);
  };

  const updateMasterState = (attrName: any, value: any) => {
    type ValueTypes = keyof quotesDTONew;
    let data = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    let sectionName: ValueTypes = attrName[1];
    let layout_name: any = attrName[0];
    let fieldname: any = attrName[2];
    let dropDown_key = attrName[3];
    let index_value = attrName[4];
    if (attrName !== "others") {
      if (sectionName === "organisationDetails") {
        if (dropDown_key === "dropdown") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value?.label },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (fieldname === "clientId") {
            setShowLoader(true);
            setSelectedClientId(value?.id);
            get_organization_data(value?.id);
            get_client_location_list(value?.id);
          }
        }
        if (dropDown_key === "multiselect") {
          if (fieldname === "location") {
            let arr: any = value ? value : [];
            let newArr: any = [];
            arr.map((data: any) => {
              newArr.push({
                name: data.value,
                city: "nill",
                state: "nill",
                country: "nill",
              });
            });

            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: newArr },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
        if (dropDown_key === "newclient") {
          if (fieldname === "organisationName") {
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: value },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "ownership") {
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: value },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "locationName") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                location: [
                  { name: value, city: "nill", state: "nill", country: "nill" },
                ],
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], location: false },
            };
          } else if (fieldname === "industry") {
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };

            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value.label,
              },
            };
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                ["sub_industry"]: "",
              },
            };
            get_subindustry_list(value, (cb: any) => {
              setSubIndustryData(cb);
            });
          } else if (fieldname === "sub_industry") {
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value.label,
              },
            };
          } else if (fieldname === "email") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "webSite") {
            
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], website: false },
            };
          } else if (fieldname === "mobile") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
      } else if (sectionName === "productType") {
        if (data.planType.name === PolicyCoverageType.FLAT) {
          data = {
            ...data,
            benefitsDetailsflat: {
              sumInsured: "",
              deductible: "",
              features: [
                {
                  name: "",
                  value: "",
                  featureId: "",
                  groupId: "",
                },
              ],
              exclusion: [
                {
                  name: "",
                  description: "",
                  exclusionId: "",
                },
              ]
            },
            benefitsDetailsGrade: [],
          };
        } else if (data.planType.name === PolicyCoverageType.GRADED) {
          data = {
            ...data,
            benefitsDetailsflat: {},
            benefitsDetailsGrade: [
              {
                gradeDivisions: [{ id: "", label: "" }],
                name: "",
                sumInsured: "",
                deductible: "",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
                exclusion: [
                  {
                    name: "",
                    description: "",
                    exclusionId: "",
                  },
                ],
              },
            ],
          };
        }

        // if (layout_name === "GRADED") {
        //   // addPoliciesdata.benefitsDetailsGrade[index].features
        //   // data = {
        //   //   ...data, benefitsDetailsGrade: {
        //   //     ...data.benefitsDetailsflat, features: [
        //   //       {
        //   //         name: "",
        //   //         value: "",
        //   //         featureId: "",
        //   //         groupId: "",
        //   //       },
        //   //     ],
        //   //     exclusion: []
        //   //   }
        //   // }
        // }

        const num =
          value === "Group Term"
            ? 0
            : value === "Group Health"
              ? 1
              : value === "Group Travel"
                ? 2
                : value === "Group Personal Accident"
                  ? 3
                  : value === "Group Critical Illness"
                    ? 4
                    : value === "Group Super TopUp"
                      ? 5
                      : null;

        let productType: any = { name: value, code: num };
        data = { ...data, [sectionName]: productType };
        dataWarn = {
          ...dataWarn,
          [sectionName]: { ...dataWarn[sectionName], name: false },
        };
        // Api for feature list
        features_list(num);
        // Api for exclusion list
        exclusion_list(num);
        // Api for insurer list
        insurance_name_dropdownlist(
          value === "Group Term" ? "LIFE" : "GENERAL"
        );
      } else if (sectionName === "policyCase") {
        if (dropDown_key === "radio") {
          const num = value === "FRESH" ? 0 : value === "RENEWAL" ? 1 : null;
          let policyCase: any = { name: value, code: num };
          data = { ...data, [sectionName]: policyCase };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], name: false },
          };
        } else if (dropDown_key === "input_field") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "insurerDetails") {
        if (dropDown_key === "dropdown") {
          if (fieldname === "name") {
            const obj: any = {
              id: value?.id,
              name: value.label,
              email: data.insurerDetails[index_value]?.email,
              office: "",
            };

            const objwarn: any = {
              name: false,
              office: addPoliciesdataWarn.insurerDetails[index_value].office,
              email: addPoliciesdataWarn.insurerDetails[index_value].email,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);

            insurance_address_dropdownlist(value?.id, index_value);
          }
          if (fieldname === "office") {
            const obj: any = {
              id: data.insurerDetails[index_value]?.id,
              name: data.insurerDetails[index_value]?.name,
              email: data.insurerDetails[index_value]?.email,
              office: value,
            };
            const objwarn: any = {
              name: addPoliciesdataWarn.insurerDetails[index_value].name,
              email: addPoliciesdataWarn.insurerDetails[index_value].email,
              office: false,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);
          }
          if (fieldname === "email") {
            const obj: any = {
              id: data.insurerDetails[index_value]?.id,
              name: data.insurerDetails[index_value]?.name,
              office: data.insurerDetails[index_value]?.office,
              email: value,
            };
            const objwarn: any = {
              name: addPoliciesdataWarn.insurerDetails[index_value].name,
              office: addPoliciesdataWarn.insurerDetails[index_value].office,
              email: false,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);
          }
        }
      } else if (sectionName === "planType") {
        if (dropDown_key === "radio") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          setAgeBandData([{ min_age: "", max_age: "" }]);
          if (value === "GRADED") {
            data = {
              ...data,
              premiumDetails: {
                netPremium: 0,
                taxPercentage: [{ id: "", label: "" }],
                taxAmount: "nill",
                grossPremium: "nill",
              },
              policyDetails: {
                startDate: new Date(),
                endDate: new Date(),
                cdBalance: "nill",
              },
              policyCase: {
                name: "FRESH",
                code: 0,
              },
              insurerDetails: [
                {
                  id: "",
                  name: "",
                  office: "",
                  email: "",
                },
              ],
              planType: {
                name: PolicyCoverageType.GRADED,
                coverageType: [
                  {
                    code: 0,
                    name: "EMPLOYEE",
                  },
                ],
                basicConfig: "1E",
                parentConfig: "Nill",
                familyDefinition: "",
                gradeCriteria: "nill",
                setOfParents: "both",
              },
              is_rider: false,
              rider: [
                {
                  name: "",
                  description: "",
                },
              ],
              payType: {
                type: premiumType.SPLIT,
                data: [
                  {
                    name: "EMPLOYEE",
                    value: 0,
                  },
                ],
              },
              ageBand: [
                {
                  name: "",
                  max: "",
                  min: "",
                },
              ],
              livesInfoGrade: [],
              policy: {
                nomination: false,
                maxNomination: 0,
                healthCard: false,
                cardType: "",
                healthCardIssued: "",
                isCorporateBuffer: false,
                corporateBuffer: null,
                proreta_by: "",
              },
              benefitsDetailsflat: {},
              benefitsDetailsGrade: [
                {
                  gradeDivisions: [{ id: "", label: "" }],
                  name: "Grade 1",
                  sumInsured: "",
                  deductible: "",
                  features: [
                    {
                      name: "",
                      value: "",
                      featureId: "",
                      groupId: "",
                    },
                  ],
                  exclusion: [
                    {
                      name: "",
                      description: "",
                      exclusionId: "",
                    },
                  ],
                },
              ],
              benefits_detail_graded: [
                {
                  grade_name: "Grade 1",
                  riders: [
                    {
                      label: "",
                      enable: false,
                      amount: "",
                      description: "",
                    },
                  ],
                },
              ],
              rider_premium_graded: [
                {
                  proreta_by: "YEARLY",
                  rider_name: "",
                  premium_split: "",
                  livesInfoGrade: [
                    {
                      gradeName: "Grade 1",
                      data: [
                        {
                          min: "",
                          max: "",
                          coverageTypeData: [
                            {
                              name: "amount",
                              value: "500",
                            },
                            {
                              code: "0",
                              name: "EMPLOYEE",
                              value: "",
                            },
                            {
                              name: "total Live",
                              value: 0,
                            },
                          ],
                          name: "age band",
                        },
                      ],
                    },
                  ],
                },
              ],
              benefits_detail_flat: [],
              rider_premium_flat: [],
            };
          } else if (value === "FLAT") {
            data = {
              ...data,
              planType: {
                name: PolicyCoverageType.FLAT,
                coverageType: [
                  {
                    code: 0,
                    name: "EMPLOYEE",
                  },
                ],
                basicConfig: "1E",
                parentConfig: "Nill",
                familyDefinition: "",
                gradeCriteria: "nill",
                setOfParents: "both",
              },
              premiumDetails: {
                netPremium: 0,
                taxPercentage: [{ id: "", label: "" }],
                taxAmount: "nill",
                grossPremium: "nill",
              },
              policyDetails: {
                startDate: new Date(),
                endDate: new Date(),
                cdBalance: "nill",
              },
              policyCase: {
                name: "FRESH",
                code: 0,
              },
              is_rider: false,
              rider: [
                {
                  name: "",
                  description: "",
                },
              ],
              payType: {
                type: premiumType.SPLIT,
                data: [
                  {
                    name: "EMPLOYEE",
                    value: 0,
                  },
                ],
              },
              ageBand: [
                {
                  name: "",
                  max: "",
                  min: "",
                },
              ],
              benefitsDetailsGrade: [],
              rider_premium_graded: [],
              benefits_detail_graded: [],
              insurerDetails: [
                {
                  id: "",
                  name: "",
                  office: "",
                  email: "",
                },
              ],
              rider_premium_flat: [
                {
                  proreta_by: "YEARLY",
                  premium_split: "",
                  rider_name: "",
                  livesInfoFlat: [
                    {
                      min: "",
                      max: "",
                      name: "",
                      coverageTypeData: [
                        {
                          code: "",
                          name: "",
                          value: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
              benefits_detail_flat: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
              benefitsDetailsflat: {
                sumInsured: "",
                deductible: "",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
              },
            };
          }
        } else if (dropDown_key === "checkbox") {
          const checkboxes = Array.from(
            document.querySelectorAll<HTMLInputElement>(
              '#checkboxContainer input[type="checkbox"]'
            )
          );
          const checkedValues = checkboxes
            .filter((checkbox) => {
              if (checkbox.value === "EMPLOYEE") {
                return true;
              } else if (checkbox.checked) {
                return true;
              }
            })
            .map((checkbox) => ({
              code:
                checkbox.value === "EMPLOYEE"
                  ? 0
                  : checkbox.value === "SPOUSE"
                    ? 1
                    : checkbox.value === "KIDS"
                      ? 2
                      : checkbox.value === "PARENTS"
                        ? 3
                        : checkbox.value === "PARENTSINLAW"
                          ? 4
                          : null,
              name: checkbox.value,
            }));

          const newData = [
            ...data.payType.data.filter(
              (item: any) => item.name === "EMPLOYEE"
            ),
          ];

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: checkedValues },
          };

          for (let i = 0; i < checkedValues.length; i++) {
            let pushObj = true;
            for (let j = 0; j < newData.length; j++) {
              if (checkedValues[i].name === newData[j].name) {
                pushObj = false;
                break;
              }
            }

            if (pushObj) {
              newData.push({ name: checkedValues[i].name, value: 0 });
            }
          }

          for (let i = 0; i <= checkedValues.length; i++) {
            if (checkedValues[i]?.name === "EMPLOYEE") {
              setbasicConfig_data([{ key: "1E", value: "1E" }]);
              data.planType.basicConfig = "1E";
            } else if (checkedValues[i]?.name === "SPOUSE") {
              setbasicConfig_data([{ key: "1E+1S", value: "1E+1S" }]);
              data.planType.basicConfig = "1E+1S";
            } else if (checkedValues[i]?.name === "KIDS") {
              if (!checkedValues.includes({ code: 1, name: "SPOUSE" })) {
                let updated_data = checkedValues;
                if (
                  !data.planType.coverageType.some((obj: any) => obj.code === 1)
                ) {
                  if (!newData.some((obj) => obj.name === "SPOUSE")) {
                    if (
                      data.payType.data.some((e: any) => e.name === "SPOUSE")
                    ) {
                      newData.splice(
                        1,
                        0,
                        ...data.payType.data.filter(
                          (item: any) => item.name === "SPOUSE"
                        )
                      );
                    } else {
                      newData.splice(1, 0, { name: "SPOUSE", value: 0 });
                    }
                  }

                  updated_data = [...updated_data, { code: 1, name: "SPOUSE" }];
                }
                data = {
                  ...data,
                  [sectionName]: {
                    ...data[sectionName],
                    [fieldname]: updated_data,
                  },
                };
              }
              setbasicConfig_data([
                { key: "1E+1S+1C", value: "1E+1S+1C" },
                { key: "1E+1S+1C+1C", value: "1E+1S+1C+1C" },
                { key: "1E+1S+1C+1C+1C", value: "1E+1S+1C+1C+1C" },
                { key: "1E+1S+1C+1C+1C+1C", value: "1E+1S+1C+1C+1C+1C" },
              ]);
              data.planType.basicConfig = "1E+1S+1C";
            } else if (
              checkedValues.some((obj) => obj.code === 3) &&
              checkedValues.some((obj) => obj.code === 4)
            ) {
              data.planType.parentConfig = "1P+1P+1PIL+1PIL";
              setindvConfig_data([
                { key: "1P+1P+1PIL+1PIL", value: "1P+1P+1PIL+1PIL" },
              ]);
            } else if (checkedValues.some((obj) => obj.code === 3)) {
              data.planType.parentConfig = "1P+1P ";
              setindvConfig_data([{ key: "1P+1P ", value: "1P+1P " }]);
            } else if (checkedValues.some((obj) => obj.code === 4)) {
              data.planType.parentConfig = "1PIL+1PIL";
              setindvConfig_data([{ key: "1PIL+1PIL", value: "1PIL+1PIL" }]);
            } else if (
              checkedValues.some((obj) => obj.code !== 3) &&
              checkedValues.some((obj) => obj.code !== 4)
            ) {
              data.planType.parentConfig = "Nill";
              setindvConfig_data([{ key: "Nill", value: "Nill" }]);
            }
          }
          data = {
            ...data,
            payType: {
              ...data.payType,
              data: newData,
            },
          };
        } else if (dropDown_key === "input_fields") {
          if (value === "Department") {
            get_dep_designation_salary("DEPARTMENTS");
          } else if (value === "Designation") {
            get_dep_designation_salary("DESIGNATIONS");
          } else if (value === "Salary Bracket") {
            get_dep_designation_salary("SALARY_BRACKETS");
          }

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          data = {
            ...data,
            benefitsDetailsGrade: [
              {
                gradeDivisions: [{ id: "", label: "" }],
                name: "",
                sumInsured: "0",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
                exclusion: [
                  {
                    name: "",
                    description: "",
                    exclusionId: "",
                  },
                ],
              },
            ],
          };
          dataWarn = {
            ...dataWarn,
            benefitsDetailsGrade: [
              {
                designation: false,
                name: false,
                sumInsured: false,
                features: [
                  {
                    name: false,
                    value: false,
                    featureId: false,
                    groupId: false,
                  },
                ],
                exclusion: [
                  {
                    name: false,
                    description: false,
                    exclusionId: false,
                  },
                ],
              },
            ],
          };
        }
      } else if (sectionName === "rider") {
        if (fieldname === "name") {
          const obj = {
            description: data.rider[index_value]?.description,
            name: value,
          };
          const obj1 = {
            description: data.rider[index_value]?.description,
            label: value,
            amount: data.benefits_detail_flat[index_value]?.amount,
            enable: data.benefits_detail_flat[index_value]?.enable,
          };
          let objGraded1: any = {};
          if (data.planType.name === PolicyCoverageType.GRADED) {
            objGraded1 = {
              description:
                data.benefits_detail_graded[0].riders[index_value]?.description,
              label: value,
              amount:
                data.benefits_detail_graded[0].riders[index_value]?.amount,
              enable:
                data.benefits_detail_graded[0].riders[index_value]?.enable,
            };
          }

          const obj2 = {
            ...data.rider_premium_flat?.[index_value],
            rider_name: value,
          };
          data.rider.splice(index_value, 1, obj);

          if (data.planType.name === PolicyCoverageType.FLAT) {
            data.benefits_detail_flat.splice(index_value, 1, obj1);
            data.rider_premium_flat.splice(index_value, 1, obj2);
          } else if (data.planType.name === PolicyCoverageType.GRADED) {
            data.benefits_detail_graded = data.benefits_detail_graded.map(
              (grade) => {
                const updatedRiders = [...grade.riders];
                updatedRiders.splice(index_value, 1, objGraded1);
                return { ...grade, riders: updatedRiders };
              }
            );
          }
        } else if (fieldname === "description") {
          const obj = {
            description: value,
            name: data.rider[index_value]?.name,
          };

          data.rider.splice(index_value, 1, obj);

          if (data.planType.name === PolicyCoverageType.FLAT) {
            const obj1 = {
              description: value,
              label: data.benefits_detail_flat[index_value]?.label,
              amount: data.benefits_detail_flat[index_value]?.amount,
              enable: data.benefits_detail_flat[index_value]?.enable,
            };

            data.benefits_detail_flat.splice(index_value, 1, obj1);
          } else if (data.planType.name === PolicyCoverageType.GRADED) {
            const objGraded1 = {
              description: value,
              label: data.benefits_detail_graded[0].riders[index_value]?.label,
              amount:
                data.benefits_detail_graded[0].riders[index_value]?.amount,
              enable:
                data.benefits_detail_graded[0].riders[index_value]?.enable,
            };
            data.benefits_detail_graded = data.benefits_detail_graded.map(
              (grade) => {
                const updatedRiders = [...grade.riders];
                updatedRiders.splice(index_value, 1, objGraded1);
                return { ...grade, riders: updatedRiders };
              }
            );
          }
        }
      } else if (sectionName === "benefits_detail_flat") {
        if (fieldname === "amount") {
          const obj = {
            ...data.benefits_detail_flat[index_value],
            amount: extractNumbersFromString(value),
          };
          data.benefits_detail_flat.splice(index_value, 1, obj);
        }
      } else if (sectionName === "benefits_detail_graded") {
        if (fieldname === "amount") {
          data.benefits_detail_graded[index_value].riders[attrName[5]].amount =
            extractNumbersFromString(value);
        }
      } else if (sectionName === "rider_premium_flat") {
        if (fieldname === "proreta_by") {
          data.rider_premium_flat[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_flat[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_flat[attrName[2]]
          );
          updatedPremium.livesInfoFlat[attrName[3]].coverageTypeData[0].value =
            value;
          data.rider_premium_flat.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "rider_premium_graded") {
        if (fieldname === "proreta_by") {
          data.rider_premium_graded[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_graded[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_graded[attrName[2]]
          );
          updatedPremium.livesInfoGrade[attrName[3]].data[
            attrName[4]
          ].coverageTypeData[0].value = value;
          data.rider_premium_graded.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "ageBand") {
        if (fieldname === "min") {
          breakAgeBand(value.label, "onchange");
          setageBandMax(value.label);
          const obj: any = {
            min: value.label,
            max: "",
            name: "Age band ",
          };
          const objWarn: any = {
            min: false,
            max: dataWarn.ageBand[index_value]?.max,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          dataWarn.ageBand.splice(index_value, 1, objWarn);
        } else if (fieldname === "max") {
          // breakAgeBand(value.label)
          // setageBandMax(value.label)
          const objWarn: any = {
            min: dataWarn.ageBand[index_value]?.min,
            max: false,
            name: "Age band ",
          };
          const obj = {
            min: data.ageBand[index_value]?.min,
            max: value.label,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          dataWarn.ageBand.splice(index_value, 1, objWarn);
        }
        // dataWarn = {
        //   ...dataWarn,
        //   [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
        // };
      } else if (sectionName === "maxChildAge") {
        data = {
          ...data,

          [sectionName]: value.value,
        };
      } else if (layout_name === "slidepanelfeatures") {
        setAddFeature(false);
        setAddExclusion(false);
      } else if (sectionName === "premiumDetails") {
        if (fieldname === "netPremium") {
          //value = extractNumbersFromString(value);
          value = extractNumbersFromString(value);

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          // let totalStandardTaxPercentage = 0;
          // let cessTaxPercentage = 0;

          // if (data?.premiumDetails?.taxPercentage) {
          //   // Calculate the total standard tax percentage from the taxPercentage array
          //   data?.premiumDetails?.taxPercentage?.forEach((tax: any) => {
          //     if (tax.id !== "Cess") {
          //       totalStandardTaxPercentage += parseFloat(
          //         tax?.label?.split("-")[1].trim()
          //       );
          //     } else {
          //       cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
          //     }
          //   });
          // }
          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;

          if (data?.premiumDetails?.taxPercentage) {
            // Calculate the total standard tax percentage from the taxPercentage array
            data?.premiumDetails?.taxPercentage?.forEach((tax) => {
              if (tax.id !== "Cess") {
                const splitLabel = tax?.label?.split("-");
                if (splitLabel[1]) {
                  totalStandardTaxPercentage += parseFloat(
                    splitLabel[1].trim()
                  );
                } else {
                  console.log("Index 1 is undefined in the split label array.");
                }
              } else {
                const splitLabel = tax.label.split("-");
                // console.log("Split Label Array:", splitLabel);
                if (splitLabel[1]) {
                  cessTaxPercentage = parseFloat(splitLabel[1].trim());
                } else {
                  console.log("Index 1 is undefined in the split label array.");
                }
              }
            });
          }

          // Calculate the standard tax amount
          const standardTaxAmount = (value * totalStandardTaxPercentage) / 100;
          // Calculate the cess tax amount
          const cessTaxAmount = (standardTaxAmount * cessTaxPercentage) / 100;

          // Calculate the total tax amount
          const totalTaxAmount = standardTaxAmount + cessTaxAmount;

          // Calculate the gross premium
          const calculatedGrossPremium = (
            Number(value) + totalTaxAmount
          ).toFixed(2);

          // Set the calculated values in the state

          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium: calculatedGrossPremium,
            },
          };
        } else if (fieldname === "taxPercentage") {
          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;
          let netPremium: any = extractNumbersFromString(
            data.premiumDetails.netPremium
          );

          //console.log(data.premiumDetails.netPremium, "data.premiumDetails.netPremium");

          // Calculate the total standard tax percentage from the taxPercentage array
          value.forEach((tax: any) => {
            if (tax.id !== "Cess") {
              totalStandardTaxPercentage += parseFloat(
                tax.label.split("-")[1].trim()
              );
            } else {
              cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
            }
          });

          // Calculate the standard tax amount
          const standardTaxAmount =
            (netPremium * totalStandardTaxPercentage) / 100;
          // Calculate the cess tax amount
          const cessTaxAmount = (standardTaxAmount * cessTaxPercentage) / 100;

          // Calculate the total tax amount
          const totalTaxAmount = (standardTaxAmount + cessTaxAmount).toFixed(2);

          // Calculate the gross premium
          const calculatedGrossPremium = (
            Number(netPremium) + Number(totalTaxAmount)
          ).toFixed(2);

          // Set the calculated values in the state
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium: calculatedGrossPremium,
            },
          };
        } else if (fieldname === "grossPremium") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;
          if (data?.premiumDetails?.taxPercentage) {
            // Calculate the total standard tax percentage and cess tax percentage from the taxPercentage array
            data?.premiumDetails?.taxPercentage?.forEach((tax: any) => {
              if (tax.id !== "Cess") {
                totalStandardTaxPercentage += parseFloat(
                  tax.label.split("-")[1].trim()
                );
              } else {
                cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
              }
            });
          }
          let netPremium: number =
            (value * 10000) /
            (100 * totalStandardTaxPercentage +
              totalStandardTaxPercentage * cessTaxPercentage +
              10000);
          const roundedNetPremium = Math.round(netPremium);
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              netPremium: roundedNetPremium,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: (value - roundedNetPremium).toFixed(2),
            },
          };
        }
      } else if (sectionName === "policyDetails") {
        if (fieldname === "startDate") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          let end_date = add_days_in_date_getDateObject(value, "365");
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], endDate: end_date },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        } else if (fieldname === "endDate") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          let start_date = subtract_days_in_date_getDateObject(value, "365");
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], startDate: start_date },
          };
        } else if (fieldname === "cdBalance") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "policy") {
        if (fieldname === "criticalCd") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: parseInt(value),
            },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (data.policyDetails.cdBalance) {
            const calculatedPercentage: any =
              (value / data.policyDetails.cdBalance) * 100;
            // data = {
            //   ...data,
            //   [sectionName]: {
            //     ...data[sectionName],
            //     ["criticalPercentage"]: calculatedPercentage,
            //   },
            // };
          }
        } else if (fieldname === "criticalPercentage") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: parseInt(value),
            },
          };
          // if (data.policyDetails.cdBalance) {
          //   let Part: any = (value / 100) * data.policyDetails.cdBalance;
          //   data = {
          //     ...data,
          //     [sectionName]: {
          //       ...data[sectionName],
          //       ["criticalCd"]: parseInt(Part),
          //     },
          //   };
          // }
        } else if (dropDown_key === "drop_down_common") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (fieldname === "gracePeriod") {
            data.policyDetails.startDate = null;
            data.policyDetails.endDate = null;
          }
          if (fieldname === "proreta_by") {
            get_graded_data();
          }
        }
      } else if (layout_name === "FLAT") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: value,
          },
        };
        data = {
          ...data,
          benefitsDetailsGrade: [],
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: false,
          },
        };
      } else if (layout_name === "sendaddExclusion") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: value,
          },
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: false,
          },
        };
        if (dropDown_key === "gradedExclusion") {
          if (addPoliciesdata.planType.name === "GRADED") {
            let indexx: any = fieldname;
            let arr: any = [];
            value?.map((el: any) =>
              el.exclusion?.check === true
                ? arr.push({
                  description: el.exclusion.description,
                  exclusionId: el.exclusion.id,
                  name: el.exclusion.title,
                })
                : null
            );
            let warndata = {
              description: false,
              exclusionId: false,
              name: false,
            };
            data["benefitsDetailsGrade"][indexx].exclusion = arr;
            dataWarn["benefitsDetailsGrade"][indexx].exclusion = warndata;

            let graded_data: any = JSON.parse(
              localStorage.getItem("addPolicy") || ""
            );
            graded_data[indexx] = value;

            setexclusionsListdataGraded(graded_data);
            // setexclusionsListdataGradedCopy(graded_data);
          }
        }
      } else if (sectionName === "benefitsDetailsflat") {
        if (fieldname === "sumInsured") {
          const val = Number(extractNumbersFromString(value));

          let warn = false;

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          if (!val) {
            warn = true;
          }

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: String(val) },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: warn },
          };
        } else if (fieldname === "deductible") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "benefitsDetailsGrade") {
        data = {
          ...data,
          benefitsDetailsflat: {},
        };
        if (fieldname === "gradeDivisions") {
          //let data_string: any = value.label.toString();
          data[sectionName][index_value].gradeDivisions = value;
          dataWarn[sectionName][index_value].designation = false;
          filterDropDownData();
        } else if (fieldname === "sumInsured") {
          const val = Number(extractNumbersFromString(value));

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          let warn = false;

          if (index_value > 0) {
            if (val <= Number(data[sectionName][index_value - 1].sumInsured)) {
              // toast.warn(
              //   "Sum insured value cannot be less than or equal to previous grade."
              // );
              warn = true;
            }
            else {
              warn = false;
            }
          }

          if (data[sectionName][index_value + 1]?.sumInsured) {
            if (val >= Number(data[sectionName][index_value + 1].sumInsured)) {
              // toast.warn(
              //   "Sum insured value cannot be greater than or equal to next grade."
              // );
              warn = true;
            }
            else {
              warn = false;
            }
          }

          data[sectionName][index_value].sumInsured = val.toString();
          dataWarn[sectionName][index_value].sumInsured = warn;
        } else if (fieldname === "deductible") {
          data[sectionName][index_value].deductible = value;
          dataWarn[sectionName][index_value].deductible = false;
        }
      } else if (layout_name === "GRADED") {
        let indexx: any = sectionName;
        let arr: any = [];
        value?.map((data: any) =>
          data?.features?.map((el: any) =>
            el.check === true && el.value
              ? arr.push({
                name: el.name,
                value: el.value,
                featureId: el.id,
                groupId: data._id,
                unit: el.unit === "Free Text" ? el.customUnitType : el.unit,
              })
              : null
          )
        );
        let warndata = {
          featureId: false,
          groupId: false,
          name: false,
          value: false,
        };
        data["benefitsDetailsGrade"][indexx].features = arr;
        dataWarn["benefitsDetailsGrade"][indexx].features = warndata;

        let graded_data: any = gradedAddDataCopy();
        graded_data[indexx] = value;

        setfeaturesListdataGraded(graded_data);
        setfeaturesListdataGradedCopy(graded_data);
      }
    } else {
      setOthers(value);
    }
    setAddPoliciesdata(data);
    setAddPoliciesdataWarn(dataWarn);
  };

  const updatePayTypeValues = (attrName: any, value: any) => {
    let data = addPoliciesdata;

    if (attrName[1] === "payType") {
      const newData = data.payType.data.map((item) => {
        let retVal = item;
        if (item.name === attrName[3]) {
          const val = value.replace(/[^0-9.]/g, "");
          const trimmedVal = val.replace(/^0+/, "");
          if (!(trimmedVal < 0 || trimmedVal > 100)) {
            retVal = { ...retVal, value: trimmedVal ? trimmedVal : 0 };
          }
        }
        return retVal;
      });

      data = {
        ...data,
        payType: { ...data.payType, data: newData },
      };
    }

    setAddPoliciesdata(data);
  };

  // Filtering data of get_dep_designation_salary
  const filterDropDownData = () => {
    let updatedDropdownSalarydesignationDep = dropdownSalarydesignationDep;

    addPoliciesdata?.benefitsDetailsGrade?.forEach((grade: any) => {
      grade?.gradeDivisions?.forEach((designation: any) => {
        updatedDropdownSalarydesignationDep =
          updatedDropdownSalarydesignationDep?.filter((dropDownData: any) => {
            return dropDownData.label !== designation.label;
          });
      });
    });
    setshowdropdownSalarydesignationDep(updatedDropdownSalarydesignationDep);
  };

  const get_dep_designation_salary = (type: any) => {
    const onSuccess = (res: any) => {
      setdropdownSalarydesignationDep(res.data.data[0].data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      userState.userType.toLocaleLowerCase(),
      selectedClientId,
      type
    );
  };
  function toggleShowNominee(e: any) {
    let data = { ...addPoliciesdata };
    setShowNominee(e.target.checked);
    data = {
      ...data,
      policy: { ...data["policy"], nomination: e.target.checked },
    };
    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], maxNomination: "" },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowhealth(e: any) {
    setShowhealth(e.target.checked);
    let data = { ...addPoliciesdata };
    data = {
      ...data,
      policy: { ...data["policy"], healthCard: e.target.checked },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], healthCardIssued: "" },
      };
      data = {
        ...data,
        policy: { ...data["policy"], cardType: "" },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowcorporate(e: any) {
    let data = { ...addPoliciesdata };
    setShowcorporate(e.target.checked);
    data = {
      ...data,
      policy: {
        ...data["policy"],
        isCorporateBuffer: e.target.checked,
      },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: {
          ...data["policy"],
          corporateBuffer: null,
        },
      };
    }
    setAddPoliciesdata(data);
  }

  const gradedDesignation_data = [
    { key: "Salary Bracket", value: "Salary Bracket" },
    { key: "Designation", value: "Designation" },
    { key: "Department", value: "Department" },
    { key: "Custom", value: "Custom" },
  ];

  const cardType_data = [
    { key: "E - Card", value: "E - Card" },
    { key: "Physical Card", value: "Physical Card" },
  ];

  const healthcardIssued_data = [
    {
      key: "Per Individual Member of the Family",
      value: "Per Individual Member of the Family",
    },
    { key: "Per Employee Only", value: "Per Employee Only" },
  ];

  useEffect(() => {
    get_graded_data();
  }, [
    ...addPoliciesdata.planType.coverageType,
    ...addPoliciesdata.benefitsDetailsGrade,
    ...addPoliciesdata.ageBand,
    ...addPoliciesdata.rider,
  ]);

  const get_graded_data = () => {
    let data = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    if (data?.planType?.name === "FLAT") {
      let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
        min: dataItem.min,
        max: dataItem.max,
        coverageTypeData: data?.planType?.coverageType.map(
          (dataCover: any) => ({
            code: String(dataCover.code),
            name: dataCover.name,
            value: 0,
          })
        ),
      }));
      let data_arrWarn: any = data?.ageBand?.map((dataItem: any) => ({
        min: false,
        max: false,
        coverageTypeData: data?.planType?.coverageType.map(
          (dataCover: any) => ({
            code: false,
            name: false,
            value: false,
          })
        ),
      }));
      data = {
        ...data,
        rider_premium_flat: data.rider_premium_flat.map((riderdata) => ({
          ...riderdata,
          livesInfoFlat: data_arr,
        })),
      };
      dataWarn = {
        ...dataWarn,
        rider_premium_flat: data.rider_premium_flat.map((riderdata) => ({
          ...riderdata,
          livesInfoFlat: data_arrWarn,
        })),
      };
      setAddPoliciesdataWarn(dataWarn);

      let newDataArr = structuredClone(data_arr);
      if (newDataArr) {
        newDataArr.forEach((item: any) => {
          // Check if coverageTypeData exists before pushing items into it
          if (item.coverageTypeData) {
            item.coverageTypeData.unshift({
              name: "amount",
              value: 0,
            });
          }
          if (item.coverageTypeData) {
            item.coverageTypeData.push({
              name: "total Live",
              value: 0,
            });
          }
        });
      }
      data = {
        ...data,
        livesInfoFlat: newDataArr,
      };

      setAddPoliciesdata(data);
      setModifiedArrayAgeBand(newDataArr);
    }
    if (
      data?.planType?.name === "GRADED" &&
      data.benefitsDetailsGrade.length > 0
    ) {
      let updatedArr: any = [];
      for (let i = 0; i < addPoliciesdata.benefitsDetailsGrade.length; i++) {
        let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
          min: dataItem.min,
          max: dataItem.max,
          coverageTypeData: data?.planType?.coverageType.map(
            (dataCover: any) => ({
              code: String(dataCover.code),
              name: dataCover.name,
              value: "",
            })
          ),
        }));

        if (data_arr) {
          data_arr.forEach((item: any) => {
            // Check if coverageTypeData exists before pushing items into it
            if (item.coverageTypeData) {
              item.coverageTypeData.unshift({
                name: "amount",
                value: "",
              });
            }
            if (item.coverageTypeData) {
              item.coverageTypeData.push({
                name: "total Live",
                value: "",
              });
            }
          });
        }

        data = {
          ...data,
          livesInfoFlat: data_arr,
        };
        updatedArr.push(data_arr);
        data = {
          ...data,
          rider_premium_graded: data.rider.map((riderdata, index) => ({
            proreta_by: "YEARLY",
            rider_name: riderdata.name,
            livesInfoGrade: updatedArr.map((grade: any, idx: any) => ({
              gradeName: `Grade ${idx + 1}`,
              data: structuredClone(grade),
            })),
          })),
        };

        setAddPoliciesdata(data);
      }

      let newArray = updatedArr?.map((dataArray: any, index: number) => {
        return {
          gradeName: "Grade " + (index + 1),
          data: dataArray.map((inner: any) => {
            return { ...inner, name: "age band" };
          }),
        };
      });

      setAddPoliciesdata((prevState) => ({
        ...prevState,
        livesInfoGrade: newArray,
        livesInfoFlat: [],
        benefitsDetailsflat: {},
      }));
      setModifiedArrayAgeBand(updatedArr);
    }
  };

  const add_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index + 1, 0, { min_age: "", max_age: "" });
    setAgeBandData(data);

    let dataMain = addPoliciesdata;
    let dataMainwarn = addPoliciesdataWarn;

    dataMain.ageBand.splice(index + 1, 0, { min: "", max: "", name: "" });
    dataMainwarn.ageBand.splice(index + 1, 0, {
      min: false,
      max: false,
      name: "",
    });

    setAddPoliciesdata(dataMain);

    setAddPoliciesdataWarn(dataMainwarn);
  };

  const remove_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index, 1);
    setAgeBandData(data);

    let dataMain = addPoliciesdata;
    dataMain.ageBand.splice(index, 1);
    setAddPoliciesdata(dataMain);
    setageBandMax(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      )
    );
    breakAgeBand(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      ),
      "ondelete"
    );
  };

  // Get Client list for dropdown
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setClientListdata(res.data.data);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  // Get organization data after select client
  const get_organization_data = (id: any) => {
    const onSuccess = (res: any) => {
      setShowLoader(false);
      setorganizationFullDetails(res.data.data[0]);
      let get_data = res.data.data[0];
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          webSite: get_data["Website URL"],
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          email: get_data["Organization Email"],
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          sub_industry: get_data["Sub-Industry"],
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          industry: get_data["Industry"],
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          mobile: get_data["Organization Phone Number"],
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          organisationName: get_data.Organisation,
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          ownership: get_data.Ownership,
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          clientId: get_data.clientId,
        },
      };
      data = {
        ...data,
        organisationDetails: {
          ...data["organisationDetails"],
          id: get_data.id,
        },
      };
      setAddPoliciesdata(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_organization_data(onSuccess, onError, id);
  };

  // GetLocation list data after select client
  const get_client_location_list = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setClientLocationList(newArr);
    };
    const onError = (err: any) => {
      console.log("get_client_location_list", err);
    };
    POLICIES_SERVICES.get_client_location_list(onSuccess, onError, id);
  };

  // Get insurance_name_dropdownlist data
  const insurance_name_dropdownlist = (type: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ id: data.id, label: data.label });
      });
      setInsurerList(newArr);
    };
    const onError = (err: any) => {
      console.log("insurance_name_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_name_dropdownlist(onSuccess, onError, type);
  };

  // Get insurance_address_dropdownlist data
  const insurance_address_dropdownlist = (id: any, index: number) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setInsurerAddressList(newArr);
      let locationdata = [...dynamicLocationOffice];
      locationdata.splice(index, 1, newArr);
      setdynamicLocationOffice(locationdata);
    };
    const onError = (err: any) => {
      console.log("insurance_address_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_address_dropdownlist(onSuccess, onError, id);
  };

  // Get features list data
  const features_list = (code: any) => {
    const onSuccess = (res: any) => {
      setfeaturesListdata(res.data.data);
      gradedAddData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("features_list", err);
    };
    POLICIES_SERVICES.features_list(onSuccess, onError, code);
  };
  // get_policy_tax data
  const get_policy_tax = () => {
    const onSuccess = (res: any) => {
      let arr: any = [];
      res.data.data?.taxList?.map((data: any, index: any) => {
        let name = arr.push({
          id: data.taxName,
          label: `${data.taxName}- ${data.taxRate}`,
        });
      });
      settaxDropDown(arr);
    };
    const onError = (err: any) => {
      console.log("get_policy_tax", err);
    };
    POLICIES_SERVICES.get_policy_tax(onSuccess, onError);
  };

  const submit_add_policy = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      toast.success("Created successfully");
      navigate(ALL_ROUTES.QUOTES);
    };
    const onError = (err: any) => {
      setDisableSave(false);
      console.log("submit_add_policy", err);
      if (err?.response.data.errors[0].param === 'webSite') {
        toast.error("website uri must be valid");
      }
    };
    let data: quotesDTONew = addPoliciesdata;
    console.log("submitData",data)
    console.log("qdata", data);
    data.organisationDetails.webSite = data.organisationDetails.webSite?.startsWith("https://") ? data.organisationDetails.webSite :`https://${data.organisationDetails.webSite}`;
    if (data?.planType?.name === "GRADED") {
      data = {
        ...data,
        benefitsDetailsflat: {},
        benefits_detail_flat: [],
        rider_premium_flat: [],
      };
    } else {
      data = {
        ...data,
        benefitsDetailsGrade: [],
        benefits_detail_graded: [],
        rider_premium_graded: [],
      };
    }

    if (data.planType.gradeCriteria === "Others") {
      data.planType.gradeCriteria = others;
    }

    if (data.productType.name !== "Group Term") {
      data = {
        ...data,
        benefits_detail_graded: [],
        rider_premium_graded: [],
        rider_premium_flat: [],
        benefits_detail_flat: [],
        rider: [],
      };
    }
    if (!data.is_rider) {
      data = {
        ...data,
        rider: [],
        rider_premium_flat: [],
        rider_premium_graded: [],
        benefits_detail_flat: [],
        benefits_detail_graded: [],
      };
    }
   
    if (!data.planType.coverageType.some((data) => data.name === "KIDS")) {
      data = { ...data, maxChildAge: "" };
    }
    // debugger;

    setDisableSave(true);
    if (data?.planType?.name === "GRADED") {
      data={
        ...data,
        livesInfoGrade: modifiedArrayAgeBand?.map((item: any, i: number) => {
          return {
            gradeName: `Grade ${i + 1}`,
            data: modifiedArrayAgeBand[i],
          };
        }),
      }
    }

  // return
    QUOTES_SERVICES.submit_add_quotes(data, onSuccess, onError);
  };

  // Get features list data
  const exclusion_list = (code: any) => {
    const onSuccess = (res: any) => {
      const updatedData = res.data.data.map((item: any) => {
        return {
          ...item,
          exclusion: {
            ...item.exclusion,
            check: false,
            id: item._id,
          },
        };
      });
      setexclusionsListdata(updatedData);
      exclusionsgradedAddData(updatedData);
    };
    const onError = (err: any) => {
      console.log("exclusion_list", err);
    };
    POLICIES_SERVICES.exclusion_list(onSuccess, onError, code);
  };

  const submithandle = () => {
    let warningLives = false;
    if (addPoliciesdata.planType.name === "FLAT") {
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsGrade: [],
      };
      setAddPoliciesdata(data);
    } else if (addPoliciesdata.planType.name === "GRADED") {
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsflat: {},
        livesInfoFlat: [],
      };
      setAddPoliciesdata(data);
    }
    const data: any = { ...addPoliciesdataWarn };

    let check_status_agebandMin = false;

    if (
      addPoliciesdata.planType.coverageType.some((data) => data.name === "KIDS")
    ) {
      data.maxChildAge = addPoliciesdata.maxChildAge ? false : true;
      check_status_agebandMin = data.maxChildAge;
    } else {
      data.maxChildAge = false;
      check_status_agebandMin = data.maxChildAge;
      setAddPoliciesdata({ ...addPoliciesdata, maxChildAge: "" });
    }

    if (isExistingClinet === "existing") {
      data.organisationDetails.clientId = addPoliciesdata.organisationDetails
        .clientId
        ? false
        : true;
      data.organisationDetails.location =
        addPoliciesdata.organisationDetails.clientId &&
          addPoliciesdata.organisationDetails.location[0].name
          ? false
          : true;
    } else {
      data.organisationDetails.organisationName = addPoliciesdata
        .organisationDetails.organisationName
        ? false
        : true;
      data.organisationDetails.ownership = addPoliciesdata.organisationDetails
        .ownership
        ? false
        : true;

      data.organisationDetails.location = addPoliciesdata.organisationDetails
        .location[0].name
        ? false
        : true;

      data.organisationDetails.industry = addPoliciesdata.organisationDetails
        .industry
        ? false
        : true;

      data.organisationDetails.sub_industry = addPoliciesdata
        .organisationDetails.sub_industry
        ? false
        : true;

      data.organisationDetails.email = !validateEmail(
        addPoliciesdata?.organisationDetails?.email
          ? addPoliciesdata?.organisationDetails?.email
          : ""
      );
      data.organisationDetails.mobile = !validateMobileNumber(
        addPoliciesdata?.organisationDetails?.mobile
          ? addPoliciesdata?.organisationDetails?.mobile
          : ""
      );

      data.organisationDetails.website = !validateUrl(
        addPoliciesdata?.organisationDetails?.webSite
          ? addPoliciesdata?.organisationDetails?.webSite
          : ""
      );
    }

    let check_status_agebandMax = false;
    let check_status_graded = false;
    let check_status_rider = false;

    const dataError = addPoliciesdata.ageBand.map((dataa: any, index: any) => {
      data.ageBand[index].min = dataa.min
        ? false
        : ((check_status_agebandMin = true), true);
      data.ageBand[index].max = dataa.max
        ? false
        : ((check_status_agebandMax = true), true);
    });
    if (addPoliciesdata.is_rider) {
      const datariderError = addPoliciesdata.rider.map(
        (dataa: any, index: any) => {
          data.rider[index].name = dataa.name
            ? false
            : ((check_status_rider = true), true);
          data.rider[index].description = dataa.description
            ? false
            : ((check_status_rider = true), true);
        }
      );
    }
    let check_status_insurername = false;
    let check_status_insureroffice = false;
    let check_status_insureremail = false;

    // Check if data exists in addPoliciesdata
    if (
      addPoliciesdata &&
      addPoliciesdata.insurerDetails &&
      addPoliciesdata.insurerDetails.length > 0
    ) {
      data.insurerDetails = addPoliciesdata.insurerDetails.map((dataa: any) => {
        const updatedData = {
          ...dataa,
          office: dataa.office
            ? false
            : ((check_status_insureroffice = true), true),
          name: dataa.name ? false : ((check_status_insurername = true), true),
          email: validateEmail(dataa.email)
            ? false
            : ((check_status_insureremail = true), true),
        };
        return updatedData;
      });

      // Now, you can use check_status_insurername and check_status_insureroffice
      // to determine if any warnings are present in the data.insurerDetails array.
      // If both variables are false, it means there are no warnings.

      // For example:
      if (check_status_insurername || check_status_insureroffice) {
        // Warnings are present in the data.insurerDetails array
        // Handle the warnings accordingly
      } else {
        // No warnings in the data.insurerDetails array
        // You can proceed with your API call or any other logic.

        // If you want to update the addPoliciesdataWarn state with the new data:
        setAddPoliciesdataWarn(data);
      }
    } else {
      // Handle the case when no data is present in addPoliciesdata
    }

    if (addPoliciesdata.planType.name === "FLAT") {
      data.benefitsDetailsflat.features = addPoliciesdata.benefitsDetailsflat
        ?.features?.[0]?.value
        ? false
        : true;
      data.benefitsDetailsflat.exclusion = addPoliciesdata.benefitsDetailsflat
        ?.exclusion
        ? false
        : true;
      if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
        data.benefitsDetailsflat.deductible = addPoliciesdata
          .benefitsDetailsflat?.deductible
          ? false
          : true;
      }
      if (addPoliciesdata?.productType?.name === "Group Term") {
        const benefits_detail_flatError =
          addPoliciesdata.benefits_detail_flat.map((dataa: any, index: any) => {
            if (dataa.enable) {
              data.benefits_detail_flat[index].amount = dataa.amount
                ? false
                : ((check_status_rider = true), true);
            } else {
              data.benefits_detail_flat[index].amount = false;
              check_status_rider = false;
            }
          });
        // if rider is true, check all warning
        const rider_premium_flat =
          addPoliciesdata.is_rider &&
          addPoliciesdata.rider_premium_flat.map((dataa: any, index: any) => {
            // dataa?.livesInfoFlat.map((liveData: any, indexLive: any) => {
            //   data.rider_premium_flat[index].livesInfoFlat[
            //     indexLive
            //   ].coverageTypeData[0].value = liveData?.coverageTypeData[0]?.value
            //       ? false
            //       : ((check_status_rider = true), true);
            // });
            data.rider_premium_flat[index].premium_split = dataa.premium_split
              ? false
              : ((check_status_rider = true), true);
          });
        // if rider is false, make all warning false

        const rider_premium_flat_check =
          !addPoliciesdata.is_rider &&
          addPoliciesdata.rider_premium_flat.map((dataa: any, index: any) => {
            dataa?.livesInfoFlat.map((liveData: any, indexLive: any) => {
              data.rider_premium_flat[index].livesInfoFlat[
                indexLive
              ].coverageTypeData[0].value = false;
              check_status_rider = false;
            });
            data.rider_premium_flat[index].premium_split = false;
            check_status_rider = false;
          });
      }
    } else if (addPoliciesdata.planType.name === "GRADED") {
      
      const dataError = addPoliciesdata.benefitsDetailsGrade.map(
        (value: any, index: any) => {
          data.benefitsDetailsGrade[index].designation =
            value.gradeDivisions[index] !== ""
              ? false
              : ((check_status_graded = true), true);
          if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
            data.benefitsDetailsGrade[index].deductible =
              value?.deductible !== "" ? false : true;
          }

          value?.features?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.features &&
              data.benefitsDetailsGrade[index].features[indexx]
            ) {
              data.benefitsDetailsGrade[index].features[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });
          value?.exclusion?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.exclusion &&
              data.benefitsDetailsGrade[index].exclusion[indexx]
            ) {
              data.benefitsDetailsGrade[index].exclusion[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });
        }
      );
      if (addPoliciesdata?.productType?.name === "Group Term") {
        addPoliciesdata.benefits_detail_graded.forEach((grade: any) => {
          grade.riders.forEach((rider: any) => {
            if (rider.enable && !rider.amount) {
              check_status_graded = true;
              toast.warn("Enter sum Assured of Riders in Every Grade");
              return;
            }
          });
        });

        let warningShown = false;

        addPoliciesdata.is_rider &&
          addPoliciesdata.rider_premium_graded.forEach((premium: any) => {
            // Check proreta_by and premium_split
            if (!premium.proreta_by || !premium.premium_split) {
              check_status_graded = true;
              if (!warningShown) {
                toast.warn("Proreta_by and premium_split cannot be empty");
                warningShown = true;
              }
              return;
            }
            // Check coverageTypeData
            // premium.livesInfoGrade.forEach((grade: any) => {
            //   grade.data.forEach((coverage: any) => {
            //     coverage.coverageTypeData.forEach((coverageItem: any) => {
            //       if (coverageItem.name === "amount" && !coverageItem.value) {
            //         check_status_graded = true;
            //         if (!warningShown) {
            //           toast.warn("Amount in coverageTypeData cannot be empty");
            //           warningShown = true;
            //         }
            //         return;
            //       }
            //     });
            //   });
            // });
          });
      }
      // Declare i outside the loop
      // let i;

      // // Function to check if sumInsured is in ascending order
      // function checkSumInsuredOrder(data: any) {
      //   if (!Array.isArray(data)) {
      //     console.warn("Data is not an array");
      //     return false;
      //   }

      //   for (i = 1; i < data.length; i++) {
      //     // Attempt to convert sumInsured to a number if it's not already a number
      //     const sumInsuredCurrent = Number(data[i].sumInsured);
      //     const sumInsuredPrevious = Number(data[i - 1].sumInsured);

      //     if (
      //       !isNaN(sumInsuredCurrent) &&
      //       !isNaN(sumInsuredPrevious) &&
      //       sumInsuredCurrent > sumInsuredPrevious
      //     ) {
      //       continue;
      //     } else {
      //       return false;
      //     }
      //   }
      //   return true;
      // }

      // // Call the function to check the order
      // if (!checkSumInsuredOrder(addPoliciesdata.benefitsDetailsGrade)) {
      //   // Handle the warning condition here
      //   return toast.warn(
      //     `Grade ${i && i + 1}'s Sum Insured is smaller than the Grade ${i && i
      //     }`
      //   );
      // }

      // addPoliciesdata.livesInfoGrade.map((outer: any) => {
      //   outer.data.map((inner: any) => {
      //     inner.coverageTypeData.map((inside: any) => {
      //       if (inside.value === "") {
      //         warningLives = false;
      //       } else {
      //         warningLives = true;
      //       }
      //     });
      //   });
      // });
      // if (warningLives) {
      //   toast.error("Please fill Remaining live");
      // }
      let i;
      let z = false;
      // Function to check if sumInsured is in ascending order

      
      function checkSumInsuredOrder(data: any) {
        if (!Array.isArray(data)) {
          console.warn("Data is not an array");
          return false;
        }
      
        for (let i = 1; i < data.length; i++) {
          // Attempt to convert sumInsured to a number if it's not already a number
          const sumInsuredCurrent = Number(data[i].sumInsured);
          const sumInsuredPrevious = Number(data[i - 1].sumInsured);
      
          // Check if the current and previous sumInsured are the same
          if (sumInsuredCurrent === sumInsuredPrevious && !z) {
            toast.warn(
              `Sum Insured of Grade ${i} & Grade ${i + 1} can't be the same`
            );
            z = true; // Set the flag to true after the warning is shown
            return false; // Return false to indicate an error
          }
      
          // Check if current sumInsured is less than the previous
          if (
            !isNaN(sumInsuredCurrent) &&
            !isNaN(sumInsuredPrevious) &&
            sumInsuredCurrent < sumInsuredPrevious
          ) {
            toast.warn(
              `Grade ${i + 1}'s Sum Insured is smaller than Grade ${i}`
            );
            return false; // Return false if the condition is met
          }
        }
        
        return true; // All checks passed
      }
      
      // Usage of checkSumInsuredOrder
      if (!checkSumInsuredOrder(addPoliciesdata.benefitsDetailsGrade)) {
        // Handle the case when the check fails
        return; // You can return or handle any further logic here
      }
    }
    data.planType.basicConfig = addPoliciesdata.planType.basicConfig
      ? false
      : true;
    data.planType.familyDefinition = addPoliciesdata.planType.familyDefinition
      ? false
      : true;

    if (addPoliciesdata.policy.nomination === true) {
      data.policy.maxNomination =
        addPoliciesdata.policy.maxNomination !== 0 ? false : true;
    } else {
      data.policy.maxNomination = false;
    }
    if (addPoliciesdata.policy.isCorporateBuffer === true) {
      const corporateBuffer = addPoliciesdata.policy.corporateBuffer;
      data.policy.corporateBuffer = corporateBuffer === 0 ? true : false;
    } else {
      data.policy.corporateBuffer = false;
    }

    if (addPoliciesdata.policy.healthCard === true) {
      data.policy.cardType =
        addPoliciesdata.policy.cardType &&
          addPoliciesdata.policy.healthCardIssued
          ? false
          : true;
    } else {
      data.policy.cardType = false;
    }

    setAddPoliciesdataWarn({ ...data });

    if (
      (addPoliciesdata.planType.name === "FLAT"
        ? data.benefitsDetailsflat.features === false &&
        data.benefitsDetailsflat.exclusion === false
        : check_status_graded === false) &&
      data.organisationDetails.clientId === false &&
      data.organisationDetails.location === false &&
      data.premiumDetails.netPremium === false &&
      data.planType.familyDefinition === false &&
      data.planType.basicConfig === false &&
      data.policyDetails.startDate === false &&
      data.policy.enrolmentStartDate === false &&
      data.policy.enrolmentEndDate === false &&
      data.policyDetails.cdBalance === false &&
      data.policy.criticalCd === false &&
      data.policy.maxNomination === false &&
      data.policy.cardType === false &&
      data.policy.corporateBuffer === false &&
      check_status_agebandMin === false &&
      check_status_agebandMax === false &&
      check_status_insurername === false &&
      check_status_insureroffice === false &&
      check_status_insureremail === false &&
      warningLives === false &&
      check_status_rider === false &&
      errorMaxChildAge === false
    ) {
      submit_add_policy();
    }
  };
  function toggleEnableRiders(e: any) {
    let data = { ...addPoliciesdata };
    data = {
      ...data,
      is_rider: e.target.checked,
    };
    if (!e.target.checked) {
      data = {
        ...data,
        rider: [],
      };
    }
    if (e.target.checked) {
      data = {
        ...data,
        rider: [
          {
            name: "",
            description: "",
          },
        ],
      };
    }
    setAddPoliciesdata(data);
  }
  const gradedAddData = (data: any) => {
    const modifiedData = data.map((featureGroup: any) => ({
      ...featureGroup,
      features: featureGroup.features.map((feature: any, index: number) => {
        let value = "";
        return {
          ...feature,
          value,
          check: false,
          groupid: featureGroup._id,
          id: feature.id,
        };
      }),
    }));
    // here we are creating the replica of previous selected features
    const updatedMainData = modifiedData.map((group: any) => {
      const updatedFeatures = group?.features?.map((feature: any) => {
        const matchingLocalData = addPoliciesdata?.benefitsDetailsGrade[
          addPoliciesdata.benefitsDetailsGrade.length - 1
        ]?.features?.find(
          (localItem: any) => localItem.featureId === feature.id
        );
        if (matchingLocalData) {
          return { ...feature, value: matchingLocalData.value, check: true };
        }
        return feature;
      });
      return { ...group, features: updatedFeatures };
    });

    //setMainData(updatedMainData);

    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata.benefitsDetailsGrade.length + 1),
    ].map(() => updatedMainData);
    setfeaturesListdataGraded(updated_data);
  };

  const gradedAddDataCopy = () => {
    if (addPoliciesdata.planType.name === "GRADED") {
      const modifiedData = featuresListdata.map((item: any) => ({
        ...item,
        features: item.features.map((feature: any) => ({
          ...feature,
          value: "",
          check: false,
          groupid: featuresListdata._id,
          id: feature.id,
        })),
      }));
      let updated_data: any = [];
      updated_data = [
        ...Array(addPoliciesdata.benefitsDetailsGrade.length + 1),
      ].map(() => modifiedData);

      if (featuresListdataGradedCopy.length > 0) {
        return featuresListdataGradedCopy;
      }
      return updated_data;
    }
  };

  const exclusionsgradedAddData = (updatedData: any) => {
    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata.benefitsDetailsGrade.length + 1),
    ].map(() => updatedData);

    const localExclusionIds: any = addPoliciesdata.benefitsDetailsGrade[
      addPoliciesdata.benefitsDetailsGrade.length - 1
    ]?.exclusion?.map((item: any) => item.exclusionId);
    const updatedMainData = updated_data.map((group: any) => {
      const updatedGroup = group.map((item: any) => {
        if (item.exclusion && localExclusionIds?.includes(item.exclusion.id)) {
          return {
            ...item,
            exclusion: {
              ...item.exclusion,
              check: true,
            },
          };
        }
        return item;
      });
      return updatedGroup;
    });
    setexclusionsListdataGraded(updatedMainData);
  };
  function toggleEnableRidersFeaure(e: any, index: number) {
    let data = { ...addPoliciesdata };
    data.benefits_detail_flat[index].enable = e.target.checked;

    if (!e.target.checked) {
      data.benefits_detail_flat[index].amount = "";
    }
    if (e.target.checked) {
    }
    setAddPoliciesdata(data);
  }

  function toggleEnableRidersFeatureGrade(
    e: any,
    gradeIndex: number,
    riderIndex: number
  ) {
    const newData = JSON.parse(JSON.stringify(addPoliciesdata));
    newData.benefits_detail_graded[gradeIndex].riders[riderIndex].enable =
      e.target.checked;
    if (!e.target.checked) {
      newData.benefits_detail_graded[gradeIndex].riders[riderIndex].amount = "";
    }
    setAddPoliciesdata(newData);
  }
  const handleClicktoogle = (e: any) => {
    setisExistingClinet(e.target.value);
    if (e.target.value === "new") {
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        organisationDetails: {
          id: "55555a555555a555a55aaa55",
          clientId: "",
          organisationName: "",
          ownership: "",
          webSite: "",
          industry: "",
          sub_industry: "",
          email: "",
          mobile: "",
          location: [
            {
              name: "",
              city: "nill",
              state: "nill",
              country: "nill",
            },
          ],
        },
      };
      setAddPoliciesdata(data);
    }
    let dataWarn = { ...addPoliciesdataWarn };
    dataWarn = {
      ...dataWarn,
      organisationDetails: {
        clientId: false,
        parentClientId: false,
        parentOrganistion: false,
        email: false,
        website: false,
        organisationName: false,
        ownership: false,
        sub_industry: false,
        industry: false,
        mobile: false,
        location: false,
      },
    };
    setAddPoliciesdataWarn(dataWarn);
  };
  const updateMasterStateLives = (attrName: any, value: any) => {
    let dataArr: any = [...modifiedArrayAgeBand];
    console.log("modifiedArrayAgeBand",modifiedArrayAgeBand)
    if (attrName[2] === "flatData") {
      dataArr[attrName[0]].coverageTypeData[attrName[1]].value = value;
      let totalValue = 0;

      dataArr[attrName[0]].coverageTypeData?.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[attrName[0]].coverageTypeData[
        dataArr[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    }
    else if (attrName[2] === "gradeData") {
      console.log("beforeData",dataArr);
      console.log("index",attrName[1])
      console.log(
        "graded",
        dataArr[liveGraded][attrName[0]].coverageTypeData[attrName[1]].value
      );
      console.log("dataArr",dataArr)
      dataArr[liveGraded][attrName[0]].coverageTypeData[attrName[1]].value =
        value;
      let total: number = 0;
      let totalValue = 0;

      dataArr[liveGraded][attrName[0]].coverageTypeData.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[liveGraded][attrName[0]].coverageTypeData[
        dataArr[liveGraded][attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
      console.log("afterData",dataArr);
    }
    console.log("datas>>>>",dataArr)
    setModifiedArrayAgeBand(dataArr);
  };

  const indivisualgradedlives = () => {
    let totalValue = 0;
    switch (addPoliciesdata?.planType?.name) {
      case "GRADED":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array?.forEach((data: any) => {
            data?.coverageTypeData?.forEach((coverageData: any) => {
              if (coverageData.name === "total Live") {
                totalValue += parseInt(coverageData.value);
              }
            });
          });
        });
        break;

      case "FLAT":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array.coverageTypeData.forEach((coverageData: any) => {
            if (coverageData.name === "total Live") {
              totalValue += parseInt(coverageData.value);
            }
          });
        });
        break;

      default:
        break;
    }

    setindivisualGradeLives(totalValue);
  };
  useEffect(() => {
    indivisualgradedlives();
  }, [modifiedArrayAgeBand]);

  const breakAgeBand = (value?: any, from?: any) => {
    let min;
    let max;
    if (addPoliciesdata.ageBand.length === 1) {
      min = maxage_dataClone.slice(0);
      if (from === "onchange") {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(value ?? ageBandMax))
        );
      } else {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(addPoliciesdata.ageBand[0]?.min)) ??
          ageBandMax
        );
      }
    } else {
      min = maxage_dataClone.slice(
        Number(
          extractNumbersFromString(
            addPoliciesdata.ageBand[addPoliciesdata.ageBand.length - 2]?.max ??
            ageBandMax
          )
        )
      );
      max = maxage_dataClone.slice(
        Number(extractNumbersFromString(value ?? ageBandMax))
      );
    }
    setminage_data(min);
    setmaxage_data(max);
  };
console.log("addPoliciesdata",addPoliciesdata)
  return (
    <>
      {showLoader && <NewLoader />}
      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />

        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={12} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                // navigate(ALL_ROUTES.QUOTES);
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <div>
              <h3>Add New Quotes</h3>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <>
              <Grid container spacing={3}>
                <Grid xs className="leftSection h-100">
                  <Link
                    className={
                      sectionStatus === "organizationDetails"
                        ? "active sectionLink organizationDetails"
                        : "sectionLink organizationDetails"
                    }
                    onClick={() => {
                      scrollToSection(organizationDetails);
                      SetSectionStatus("organizationDetails");
                    }}
                  >
                    <span>Organization Details</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "products"
                        ? "active sectionLink products"
                        : "sectionLink products"
                    }
                    onClick={() => {
                      scrollToSection(products);
                      SetSectionStatus("products");
                    }}
                  >
                    <span>Product Type</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "quoteCase"
                        ? "active sectionLink quoteCase"
                        : "sectionLink quoteCase"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(quoteCase);
                      SetSectionStatus("quoteCase");
                    }}
                  >
                    <span>Quote Case</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "plantype"
                        ? "active sectionLink plantype"
                        : "sectionLink plantype"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(plantype);
                      SetSectionStatus("plantype");
                    }}
                  >
                    <span>Plan Type</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "premiumPayment"
                        ? "active sectionLink premiumPayment"
                        : "sectionLink premiumPayment"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(premiumPayment);
                      SetSectionStatus("premiumPayment");
                    }}
                  >
                    <span>Premium Payment</span>
                  </Link>
                  {addPoliciesdata.productType.name === "Group Term" && (
                    <Link
                      className={
                        sectionStatus === "riders"
                          ? "active sectionLink riders"
                          : "sectionLink riders"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(riders);
                        SetSectionStatus("riders");
                      }}
                    >
                      <span>Riders</span>
                    </Link>
                  )}
                  <Link
                    className={
                      sectionStatus === "AgeBand"
                        ? "active sectionLink AgeBand"
                        : "sectionLink AgeBand"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(AgeBand);
                      SetSectionStatus("AgeBand");
                    }}
                  >
                    <span>Age Bands for Lives</span>
                  </Link>
                  {planType === 0 || planType === 1 || planType === 2 ? (
                    <Link
                      className={
                        sectionStatus === "EmployeesDetails"
                          ? "active sectionLink EmployeesDetails"
                          : "sectionLink EmployeesDetails"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(EmployeesDetails);
                        SetSectionStatus("EmployeesDetails");
                      }}
                    >
                      <span>SI/SA, Features & Exclusions Details</span>
                    </Link>
                  ) : null}

                  <Link
                    className={
                      sectionStatus === "PlansSettings"
                        ? "active sectionLink PlansSettings"
                        : "sectionLink PlansSettings"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(PlansSettings);
                      SetSectionStatus("PlansSettings");
                    }}
                  >
                    <span>Plans Settings</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "InsurerDetails"
                        ? "active sectionLink InsurerDetails"
                        : "sectionLink InsurerDetails"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(InsurerDetails);
                      SetSectionStatus("InsurerDetails");
                    }}
                  >
                    <span>Insurer Details</span>
                  </Link>
                  <Link
                    className={
                      sectionStatus === "RelationshipLives"
                        ? "active sectionLink RelationshipLives"
                        : "sectionLink RelationshipLives"
                    }
                    onClick={() => {
                      handleClicks(sectionStatus);
                      scrollToSection(RelationshipLives);
                      SetSectionStatus("RelationshipLives");
                    }}
                  >
                    <span>Relationship-Lives</span>
                  </Link>
                  {addPoliciesdata.is_rider && (
                    <Link
                      className={
                        sectionStatus === "RiderDetail"
                          ? "active sectionLink RiderDetail"
                          : "sectionLink RiderDetail"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(RiderDetail);
                        SetSectionStatus("RiderDetail");
                      }}
                    >
                      <span>Rider Details</span>
                    </Link>
                  )}
                </Grid>
                <Grid xs className="rightSection h-100">
                  <Box className="detailSection" sx={{ height: "85px" }}>
                    <Grid container spacing={3} >
                      <Grid className="radio_group no-border mt-3 mb-2 "  >
                        <>
                          <RadioGroup row defaultValue="existing">
                            <FormControlLabel
                              value="existing"
                              control={<Radio />}
                              label="Existing Client"
                              onClick={handleClicktoogle}
                            />
                            <FormControlLabel
                              // checked
                              value="new"
                              control={<Radio />}
                              label="Prospective Client"
                              onClick={handleClicktoogle}
                            />
                          </RadioGroup>
                          {isExistingClinet === "existing" ? (
                            <>
                              <div className="client_div">
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Search Client ID"
                                  value={`${addPoliciesdata.organisationDetails.clientId} ${addPoliciesdata.organisationDetails.organisationName}`}
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "clientId",
                                    "dropdown",
                                  ]}
                                  value_update={updateMasterState}
                                  data={clientListdata}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .clientId
                                  }
                                  error_message="Select client"
                                  required={true}
                                />
                              </div>
                            </>
                          ) : null}
                        </>
                      </Grid>
                    </Grid>
                  </Box>
                  <>
                    {/* Organization Details */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12}>
                        {isExistingClinet === "existing" ? (
                          <Box
                            className="detailSection mb-0"
                            ref={organizationDetails}
                            onMouseEnter={() => {
                              SetSectionStatus("organizationDetails");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Organization Details</h4>
                            </div>
                            {addPoliciesdata.organisationDetails.clientId ? (
                              <>
                                <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <p className="clientsID">
                                      Client ID{" "}
                                      <span>
                                        {organizationFullDetails.clientId}
                                      </span>
                                    </p>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Organization Name
                                      <span>
                                        {organizationFullDetails.Organisation}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Ownership{" "}
                                      <span>
                                        {organizationFullDetails.Ownership}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Industry - Sub-industry
                                      <span>
                                        {
                                          organizationFullDetails[
                                          "Sub-Industry"
                                          ]
                                        }
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="contactNumber">
                                      <PhoneIphoneIcon />
                                      {
                                        organizationFullDetails[
                                        "Organization Phone Number"
                                        ]
                                      }
                                    </p>
                                    <Link
                                      className="email_list"
                                      href=""
                                      target="_blank"
                                    >
                                      <EmailIcon />
                                      {
                                        organizationFullDetails[
                                        "Organization Email"
                                        ]
                                      }
                                    </Link>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <MultipleSearchDropdown
                                      class_name="inputField"
                                      title="Location Names"
                                      value={
                                        addPoliciesdata.organisationDetails
                                          .location
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "organisationDetails",
                                        "location",
                                        "multiselect",
                                      ]}
                                      value_update={updateMasterState}
                                      multiple_data={clientLocationList}
                                      required
                                      warn_status={
                                        addPoliciesdataWarn.organisationDetails
                                          .location
                                      }
                                      error_messg="Select location"
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                        ) : (
                          <Box
                            className="detailSection mb-0"
                            ref={organizationDetails}
                            onMouseEnter={() => {
                              SetSectionStatus("organizationDetails");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Organization Details</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={12} lg={6} xl={4}>
                                <RKTextField
                                  validation_type="name"
                                  class_name="inputField"
                                  title="Organisation Name"
                                  value={
                                    addPoliciesdata.organisationDetails
                                      .organisationName
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "organisationName",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .organisationName
                                  }
                                  required
                                />
                              </Grid>
                              <Grid xs={12} lg={6} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Ownership"
                                  value={
                                    addPoliciesdata.organisationDetails
                                      .ownership
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "ownership",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  data={ownership_data}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .ownership
                                  }
                                  required
                                  error_message="Select Ownership"
                                  value_type_id_value={true}
                                />
                              </Grid>
                              <Grid xs={12} lg={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Location Name"
                                  value={
                                    addPoliciesdata.organisationDetails
                                      .location[0].name
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "locationName",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .location
                                  }
                                  required
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={6} lg={4}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Industry"
                                  value={
                                    addPoliciesdata.organisationDetails.industry
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "industry",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  data={IndustryData}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .industry
                                  }
                                  required
                                  error_message="Select industry"
                                  value_type_id_value={false}
                                />
                              </Grid>
                              <Grid xs={6} lg={4}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Sub-Industry"
                                  value={
                                    addPoliciesdata.organisationDetails
                                      .sub_industry
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "sub_industry",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  data={subIndustryData}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .sub_industry
                                  }
                                  required
                                  error_message="Select Subindustry"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={12} lg={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Email"
                                  value={
                                    addPoliciesdata.organisationDetails.email
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "email",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .email
                                  }
                                  required
                                  error_messg={
                                    !addPoliciesdata.organisationDetails?.email
                                      ?.length
                                      ? "Enter Email"
                                      : "Enter Valid Email"
                                  }
                                />
                              </Grid>
                              <Grid xs={12} lg={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Website URL"
                                  value={
                                    addPoliciesdata.organisationDetails.webSite
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "webSite",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .website
                                  }
                                  required
                                />
                              </Grid>
                              <Grid xs={12} lg={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="contact No."
                                  value={
                                    addPoliciesdata.organisationDetails.mobile
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "mobile",
                                    "newclient",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .mobile
                                  }
                                  required
                                  charcterlimit={10}
                                  validation_type="numeric"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {/* Product Type */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12}>
                        <Box
                          className="detailSection mb-0"
                          onMouseEnter={() => {
                            SetSectionStatus("products");
                          }}
                          ref={products}
                        >
                          <div className="sectionTitle">
                            <h4>Product Type</h4>
                          </div>
                          <Grid container spacing={3}>
                            <Grid className="productTypeSection">
                              <div className="radioBox productcategory">
                                <input
                                  type="radio"
                                  value="Group Term"
                                  id="individual"
                                  name="plantype"
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "productType",
                                        "LIFE",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="producttype grpTerm">
                                  <hr />
                                  Group Term Insurance
                                  <p>
                                    This plan aim to offer financial protection
                                    to the nominee or the beneficiary.
                                  </p>
                                </label>
                              </div>
                            </Grid>
                            <Grid className="productTypeSection">
                              <div className="radioBox productcategory">
                                <input
                                  type="radio"
                                  value="Group Super TopUp"
                                  id="individual"
                                  name="plantype"
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "productType",
                                        "TOPUP",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                  defaultChecked
                                />
                                <label className="producttype grptopup">
                                  <hr />
                                  Group Super TopUp
                                  <p>
                                    This plan aim to offer financial protection
                                    to the nominee or the beneficiary.
                                  </p>
                                </label>
                              </div>
                            </Grid>
                            <Grid className="productTypeSection">
                              <div className="radioBox">
                                <input
                                  type="radio"
                                  value="Group Health"
                                  id="individual1"
                                  name="plantype"
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "productType",
                                        "GENERAL",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="producttype grpHealth">
                                  <hr />
                                  Group Health Insurance
                                  <p>
                                    Group critical Illness helps employees
                                    prepare financially for an unexpected life
                                    event.
                                  </p>
                                </label>
                              </div>
                            </Grid>
                            {/* <Grid className="productTypeSection">
                            <div className="radioBox">
                              <input
                                type="radio"
                                value="Group Travel"
                                id="individual2"
                                name="plantype"
                                onChange={(e) => {
                                  updateMasterState(
                                    [
                                      "addPoliciesdata",
                                      "productType",
                                      "HEALTH",
                                      "radio",
                                    ],
                                    e.target.value
                                  );
                                }}
                                disabled
                              />
                              <label className="producttype grpTravel">
                                <hr />
                                Group Travel Insurance
                                <p>
                                  A group health insurance plan provides medical
                                  coverage for a group of people.
                                </p>
                              </label>
                            </div>
                          </Grid> */}
                            <Grid className="productTypeSection">
                              <div className="radioBox">
                                <input
                                  type="radio"
                                  value="Group Personal Accident"
                                  id="individual3"
                                  name="plantype"
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "productType",
                                        "TRAVEL",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="producttype grpPA">
                                  <hr />
                                  Group Personal Accident
                                  <p>
                                    That is offered to protect employees against
                                    the medical expenses incurred.
                                  </p>
                                </label>
                              </div>
                            </Grid>
                            <Grid className="productTypeSection">
                              <div className="radioBox">
                                <input
                                  type="radio"
                                  value="Group Critical Illness"
                                  id="individual4"
                                  name="plantype"
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "productType",
                                        "ACCIDENT",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="producttype grpCI">
                                  <hr />
                                  Group Critical Illness
                                  <p>
                                    Group critical Illness helps employees
                                    prepare financially for an unexpected life
                                    event.
                                  </p>
                                </label>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* Quote Case */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12} lg={6} xl={6}>
                        <Box
                          className="detailSection"
                          ref={quoteCase}
                          onMouseEnter={() => {
                            SetSectionStatus("quoteCase");
                          }}
                        >
                          <div className="sectionTitle">
                            <h4>Quote Case</h4>
                          </div>
                          <Grid container spacing={3}>
                            <Grid className="PolicyTypeSection">
                              <div
                                className="radioBox policytype"
                                onClick={() => {
                                  setPolicyType(0);
                                }}
                              >
                                <input
                                  type="radio"
                                  id="fresh"
                                  value="FRESH"
                                  name="planstype"
                                  defaultChecked
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "policyCase",
                                        "FRESH",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="policytype fresh">
                                  <hr />
                                  Fresh Quote
                                  <p>Buying quote for the first time.</p>
                                </label>
                              </div>
                            </Grid>
                            {/* <Grid className="PolicyTypeSection">
                            <div
                              className="radioBox"
                              onClick={() => {
                                setPolicyType(1);
                              }}
                            >
                              <input
                                type="radio"
                                id="renewal"
                                name="planstype"
                                value="RENEWAL"
                                onChange={(e) => {
                                  updateMasterState(
                                    [
                                      "addPoliciesdata",
                                      "policyCase",
                                      "RENEWAL",
                                      "radio",
                                    ],
                                    e.target.value
                                  );
                                }}
                              />
                              <label className="policytype renew">
                                <hr />
                                Renewal Policy
                                <p>Existing Policy will be expiring.</p>
                              </label>
                            </div>
                          </Grid> */}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* policy history */}
                    {policyType === 1 ? <PolicyHistory /> : null}
                    {/* Plan Type */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12}>
                        <Box
                          className="detailSection mb-0"
                          ref={plantype}
                          onMouseEnter={() => {
                            SetSectionStatus("plantype");
                          }}
                        >
                          <div className="sectionTitle">
                            <h4>Plan Type</h4>
                          </div>
                          <Grid container spacing={3}>
                            <Grid xs={4} className="planTypeSection">
                              <div className="radioBox plantype">
                                <input
                                  type="radio"
                                  value="FLAT"
                                  name="radioButtonName"
                                  defaultChecked
                                  onClick={() => {
                                    setPlanType(0);
                                  }}
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "planType",
                                        "name",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label>
                                  Flat Coverage
                                  <span>
                                    Common average amount for all employees in
                                    the policy.
                                  </span>
                                </label>
                              </div>
                            </Grid>
                            <Grid xs={4} className="planTypeSection">
                              <div className="radioBox plantype">
                                <input
                                  type="radio"
                                  value="GRADED"
                                  name="radioButtonName"
                                  onClick={() => {
                                    setPlanType(1);
                                  }}
                                  onChange={(e) => {
                                    updateMasterState(
                                      [
                                        "addPoliciesdata",
                                        "planType",
                                        "name",
                                        "radio",
                                      ],
                                      e.target.value
                                    );
                                  }}
                                />
                                <label>
                                  Graded Coverage
                                  <span>
                                    Different average amount for different
                                    employees in the policy.
                                  </span>
                                </label>
                              </div>
                            </Grid>
                            <Grid xs={12}>
                              {planType === 0 || planType === 1 ? (
                                <Box className="coverageDetail">
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <h5 className="empsecHeading">
                                        Select Coverage Type
                                      </h5>
                                    </Grid>
                                    <Grid xs={12}>
                                      <ul
                                        id="checkboxContainer"
                                        className="coverageAmt"
                                      >
                                        <li>
                                          <div className="Checkbox">
                                            <input
                                              type="checkbox"
                                              name="check"
                                              value="EMPLOYEE"
                                              onChange={(e) => {
                                                updateMasterState(
                                                  [
                                                    "addPoliciesdata",
                                                    "planType",
                                                    "coverageType",
                                                    "checkbox",
                                                  ],
                                                  e.target.value
                                                );
                                              }}
                                              checked
                                            />
                                            <label className="employee">
                                              <p>Employee</p>
                                            </label>
                                          </div>
                                        </li>
                                        {addPoliciesdata.productType.name !==
                                          "Group Term" && (
                                          <>
                                            <li>
                                              <div className="Checkbox">
                                                {addPoliciesdata.planType.coverageType.some(
                                                  (obj) => obj.code === 1
                                                ) ? (
                                                  <input
                                                    type="checkbox"
                                                    name="check"
                                                    value="SPOUSE"
                                                    checked
                                                    onChange={(e) => {
                                                      updateMasterState(
                                                        [
                                                          "addPoliciesdata",
                                                          "planType",
                                                          "coverageType",
                                                          "checkbox",
                                                        ],
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <input
                                                    type="checkbox"
                                                    name="check"
                                                    value="SPOUSE"
                                                    onChange={(e) => {
                                                      updateMasterState(
                                                        [
                                                          "addPoliciesdata",
                                                          "planType",
                                                          "coverageType",
                                                          "checkbox",
                                                        ],
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                )}

                                                <label className="spouse">
                                                  <p>Spouse</p>
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="Checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={addPoliciesdata.planType.coverageType.some(
                                                    (obj) => obj.code === 2
                                                  )}
                                                  name="check"
                                                  value="KIDS"
                                                  onChange={(e) => {
                                                    updateMasterState(
                                                      [
                                                        "addPoliciesdata",
                                                        "planType",
                                                        "coverageType",
                                                        "checkbox",
                                                      ],
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <label className="kids">
                                                  <p>Kids</p>
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="Checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={addPoliciesdata.planType.coverageType.some(
                                                    (obj) => obj.code === 3
                                                  )}
                                                  name="check"
                                                  value="PARENTS"
                                                  onChange={(e) => {
                                                    updateMasterState(
                                                      [
                                                        "addPoliciesdata",
                                                        "planType",
                                                        "coverageType",
                                                        "checkbox",
                                                      ],
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <label className="parents">
                                                  <p>Parents</p>
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="Checkbox">
                                                <input
                                                  checked={addPoliciesdata.planType.coverageType.some(
                                                    (obj) => obj.code === 4
                                                  )}
                                                  type="checkbox"
                                                  name="check"
                                                  value="PARENTSINLAW"
                                                  onChange={(e) => {
                                                    updateMasterState(
                                                      [
                                                        "addPoliciesdata",
                                                        "planType",
                                                        "coverageType",
                                                        "checkbox",
                                                      ],
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <label className="parents">
                                                  <p>Parents In Law</p>
                                                </label>
                                              </div>
                                            </li>
                                          </>
                                        )}
                                      </ul>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <h5 className="subHeading">
                                        Coverage Configuration
                                      </h5>
                                    </Grid>
                                    <Grid sm={6} md={4} lg={3} xl={3}>
                                      {/* <SearchDropdown
                                        class_name="inputField"
                                        title="Basic Configuration"
                                        value={addPoliciesdata.planType.basicConfig}
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "basicConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        data={basicConfig_data}
                                        warn_status={addPoliciesdataWarn.planType.basicConfig}
                                        required
                                      /> */}
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Basic Configuration"
                                        value={
                                          addPoliciesdata.planType.basicConfig
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "basicConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={basicConfig_data}
                                        warn_status={
                                          addPoliciesdataWarn.planType
                                            .basicConfig
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid sm={6} md={4} lg={3} xl={3}>
                                      {/* <SearchDropdown
                                        class_name="inputField"
                                        title="Individual Configuration"
                                        value={addPoliciesdata.planType.parentConfig}
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "parentConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        data={indvConfig_data}
                                        warn_status={false}
                                        required
                                      /> */}
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Individual Configuration"
                                        value={
                                          addPoliciesdata.planType.parentConfig
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "parentConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={indvConfig_data}
                                        warn_status={false}
                                        required
                                      />
                                    </Grid>
                                    {addPoliciesdata.planType.coverageType.some(
                                      (data) => data.name === "KIDS"
                                    ) && (
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        <SearchDropdown
                                          class_name="inputField"
                                          title="Maximum Age for Children"
                                          value={addPoliciesdata.maxChildAge}
                                          attrName={[
                                            "addPoliciesdata",
                                            "maxChildAge",
                                            "maxChildAge",
                                          ]}
                                          value_update={updateMasterState}
                                          data={maxage_dataClone}
                                          error_message="select max child age"
                                          warn_status={
                                            addPoliciesdataWarn.maxChildAge
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid sm={6} md={8} lg={5} xl={5}>
                                      <RKTextField
                                        class_name="inputField"
                                        title="Family Definition"
                                        value={
                                          addPoliciesdata.planType
                                            .familyDefinition
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "familyDefinition",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.planType
                                            .familyDefinition
                                        }
                                        required
                                      />
                                    </Grid>
                                    {addPoliciesdata.planType.parentConfig.includes(
                                      "1P+"
                                    ) &&
                                      addPoliciesdata.planType.parentConfig.includes(
                                        "1PIL"
                                      ) && (
                                        <Grid
                                          xs={12}
                                          className="payment_radio_group"
                                        >
                                          <>
                                            <RadioGroup row defaultValue="both">
                                              <FormControlLabel
                                                value="both"
                                                control={<Radio />}
                                                label="Include both the set of Parents and Parents in law"
                                                onClick={() => {
                                                  setAddPoliciesdata(
                                                    (prev) => ({
                                                      ...prev,
                                                      policy: {
                                                        ...prev.policy,
                                                        totalMemberPerFamily:
                                                          extractNumbersFromString(
                                                            prev.planType
                                                              .basicConfig
                                                          ).length +
                                                          extractNumbersFromString(
                                                            prev.planType
                                                              .parentConfig
                                                          ).length,
                                                      },
                                                      planType: {
                                                        ...prev.planType,
                                                        setOfParents: "both",
                                                      },
                                                    })
                                                  );
                                                }}
                                              />

                                              <FormControlLabel
                                                // checked
                                                value="single"
                                                control={<Radio />}
                                                label="Include only a set of Parents or Parents in law"
                                                onClick={() => {
                                                  setAddPoliciesdata(
                                                    (prev) => ({
                                                      ...prev,
                                                      planType: {
                                                        ...prev.planType,
                                                        setOfParents: "single",
                                                      },
                                                    })
                                                  );
                                                  if (
                                                    extractNumbersFromString(
                                                      addPoliciesdata.planType
                                                        .parentConfig
                                                    ).length === 4
                                                  ) {
                                                    setAddPoliciesdata(
                                                      (prev) => ({
                                                        ...prev,
                                                        policy: {
                                                          ...prev.policy,
                                                          totalMemberPerFamily:
                                                            extractNumbersFromString(
                                                              prev.planType
                                                                .basicConfig
                                                            ).length +
                                                            extractNumbersFromString(
                                                              prev.planType
                                                                .parentConfig
                                                            ).length -
                                                            2,
                                                        },
                                                      })
                                                    );
                                                  }
                                                }}
                                              />
                                            </RadioGroup>
                                          </>
                                        </Grid>
                                      )}
                                  </Grid>
                                  {planType === 1 ? (
                                    <Grid container spacing={3}>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          Criteria for Grade Creation
                                        </h5>
                                      </Grid>
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        {/* <SearchDropdown
                                          class_name="inputField"
                                          title="Select Criteria"
                                          value={addPoliciesdata.planType.gradeCriteria}
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "gradeCriteria",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          data={gradedDesignation_data}
                                          warn_status={false}
                                        /> */}
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Select Criteria"
                                          value={
                                            addPoliciesdata.planType
                                              .gradeCriteria
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "gradeCriteria",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          dropdown_data={gradedDesignation_data}
                                          warn_status={false}
                                        />
                                      </Grid>
                                      {addPoliciesdata.planType
                                        .gradeCriteria === "Others" ? (
                                        <Grid sm={6} md={4} lg={4} xl={3}>
                                          <RKTextField
                                            class_name="inputField"
                                            title="Others"
                                            value={others}
                                            attrName={"others"}
                                            value_update={updateMasterState}
                                            warn_status={false}
                                          />
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  ) : null}
                                </Box>
                              ) : null}
                            </Grid>
                            {/* <Grid xs={12}>
                              {planType === 0 || planType === 1 ? (
                                <Box className="coverageDetail detailSection ">
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <h5 className="empsecHeading">
                                        Select Coverage Type
                                      </h5>
                                    </Grid>
                                    <Grid xs={12}>
                                      <ul
                                        id="checkboxContainer"
                                        className="coverageAmt"
                                      >
                                        <li>
                                          <div className="Checkbox">
                                            <input
                                              type="checkbox"
                                              name="check"
                                              value="EMPLOYEE"
                                              onChange={(e) => {
                                                updateMasterState(
                                                  [
                                                    "addPoliciesdata",
                                                    "planType",
                                                    "coverageType",
                                                    "checkbox",
                                                  ],
                                                  e.target.value
                                                );
                                              }}
                                              checked
                                            />
                                            <label className="employee">
                                              <p>Employee</p>
                                            </label>
                                          </div>
                                        </li>
                                        {addPoliciesdata.productType.name !==
                                          "Group Term" && (
                                            <>
                                              <li>
                                                <div className="Checkbox">
                                                  {addPoliciesdata.planType.coverageType.some(
                                                    (obj) => obj.code === 1
                                                  ) ? (
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      value="SPOUSE"
                                                      checked
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  ) : (
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      value="SPOUSE"
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  )}

                                                  <label className="spouse">
                                                    <p>Spouse</p>
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="Checkbox">
                                                  <input
                                                    type="checkbox"
                                                    name="check"
                                                    value="KIDS"
                                                    onChange={(e) => {
                                                      updateMasterState(
                                                        [
                                                          "addPoliciesdata",
                                                          "planType",
                                                          "coverageType",
                                                          "checkbox",
                                                        ],
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                  <label className="kids">
                                                    <p>Kids</p>
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="Checkbox">
                                                  <input
                                                    type="checkbox"
                                                    name="check"
                                                    value="PARENTS"
                                                    onChange={(e) => {
                                                      updateMasterState(
                                                        [
                                                          "addPoliciesdata",
                                                          "planType",
                                                          "coverageType",
                                                          "checkbox",
                                                        ],
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                  <label className="parents">
                                                    <p>Parents</p>
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="Checkbox">
                                                  <input
                                                    type="checkbox"
                                                    name="check"
                                                    value="PARENTSINLAW"
                                                    onChange={(e) => {
                                                      updateMasterState(
                                                        [
                                                          "addPoliciesdata",
                                                          "planType",
                                                          "coverageType",
                                                          "checkbox",
                                                        ],
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                  <label className="parents">
                                                    <p>Parents In Law</p>
                                                  </label>
                                                </div>
                                              </li>
                                            </>
                                          )}
                                      </ul>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <h5 className="subHeading">
                                        Coverage Configuration
                                      </h5>
                                    </Grid>
                                    <Grid sm={6} md={4} lg={3} xl={3}>
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Basic Configuration"
                                        value={
                                          addPoliciesdata.planType.basicConfig
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "basicConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={basicConfig_data}
                                        warn_status={
                                          addPoliciesdataWarn.planType
                                            .basicConfig
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid sm={6} md={4} lg={3} xl={3}>
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Individual Configuration"
                                        value={
                                          addPoliciesdata.planType.parentConfig
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "parentConfig",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={indvConfig_data}
                                        warn_status={false}
                                        required
                                      />
                                    </Grid>
                                    {addPoliciesdata.planType.coverageType.some(
                                      (data) => data.name === "KIDS"
                                    ) && (
                                        <Grid sm={6} md={4} lg={3} xl={3}>
                                          <SearchDropdown
                                            class_name="inputField"
                                            title="Maximum Age for Children"
                                            value={addPoliciesdata.maxChildAge}
                                            attrName={[
                                              "addPoliciesdata",
                                              "maxChildAge",
                                              "maxChildAge",
                                            ]}
                                            value_update={updateMasterState}
                                            data={maxage_dataClone}
                                            error_message="select max child age"
                                            warn_status={
                                              addPoliciesdataWarn.maxChildAge
                                            }
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid sm={6} md={8} lg={5} xl={5}>
                                      <RKTextField
                                        class_name="inputField"
                                        title="Family Definition"
                                        value={
                                          addPoliciesdata.planType
                                            .familyDefinition
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "planType",
                                          "familyDefinition",
                                          "input_fields",
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.planType
                                            .familyDefinition
                                        }
                                      />
                                    </Grid>
                                  </Grid>

                                  {addPoliciesdata.planType.parentConfig.includes(
                                    "1P+"
                                  ) &&
                                    addPoliciesdata.planType.parentConfig.includes(
                                      "1PIL"
                                    ) && (
                                      <Grid
                                        xs={12}
                                        className="payment_radio_group"
                                      >
                                        <>
                                          <RadioGroup row defaultValue="both">
                                            <FormControlLabel
                                              value="both"
                                              control={
                                                <Radio
                                                  sx={{
                                                    "&.Mui-checked": {
                                                      color: "#6b62dc",
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Include both the set of Parents and Parents in law"
                                              onClick={() => {
                                                setAddPoliciesdata((prev) => ({
                                                  ...prev,
                                                  planType: {
                                                    ...prev.planType,
                                                    setOfParents: "both",
                                                  },
                                                }));
                                              }}
                                            />

                                            <FormControlLabel
                                              // checked
                                              value="single"
                                              control={
                                                <Radio
                                                  sx={{
                                                    "&.Mui-checked": {
                                                      color: "#6b62dc",
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Include only a set of Parents or Parents in law"
                                              onClick={() => {
                                                setAddPoliciesdata((prev) => ({
                                                  ...prev,
                                                  planType: {
                                                    ...prev.planType,
                                                    setOfParents: "single",
                                                  },
                                                }));
                                              }}
                                            />
                                          </RadioGroup>
                                        </>
                                      </Grid>
                                    )}
                                </Box>
                              ) : null}
                            </Grid> */}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* payment configuration */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12}>
                        <Box
                          className="detailSection mb-0"
                          onMouseEnter={() => {
                            SetSectionStatus("premiumPayment");
                          }}
                          ref={premiumPayment}
                        >
                          <div className="sectionTitle">
                            <h4>Premium Payment Configuration</h4>
                          </div>
                          <div className="premium_payment_section">
                            <Grid container spacing={3}>
                              <Grid xs={12}>
                                <h5 className="subHeading">
                                  Premium Payment Type
                                </h5>
                              </Grid>
                              <Grid xs={12} className="payment_radio_group">
                                <>
                                  <RadioGroup row defaultValue="new">
                                    <FormControlLabel
                                      value="existing"
                                      control={<Radio />}
                                      label="Standard Premium"
                                      onClick={() => {
                                        setPremiumPaymentType(
                                          premiumType.STANDARD
                                        );
                                        setToggle(false);
                                        setAddPoliciesdata((prev) => ({
                                          ...prev,
                                          payType: {
                                            ...prev.payType,
                                            type: premiumType.STANDARD,
                                          },
                                        }));
                                      }}
                                    />

                                    <FormControlLabel
                                      // checked
                                      value="new"
                                      control={<Radio />}
                                      label="Split Premium"
                                      onClick={() => {
                                        setPremiumPaymentType(
                                          premiumType.SPLIT
                                        );
                                        setToggle(true);
                                        setAddPoliciesdata((prev) => ({
                                          ...prev,
                                          payType: {
                                            ...prev.payType,
                                            type: premiumType.SPLIT,
                                          },
                                        }));
                                      }}
                                    />
                                  </RadioGroup>
                                </>
                              </Grid>
                            </Grid>
                            {premiumPaymentType === premiumType.SPLIT ? (
                              <>
                                <Grid container spacing={3}>
                                  <Grid sm={12} md={12} lg={6} xl={6}>
                                    <div className="payment_inner_section">
                                      {addPoliciesdata.payType.data.map(
                                        (pType, index) => {
                                          return (
                                            <RKTextField
                                              class_name="inputField"
                                              // type="number"
                                              charcterlimit={3}
                                              title={pType.name}
                                              value={
                                                addPoliciesdata.payType.data.find(
                                                  (el) => el.name === pType.name
                                                )?.value
                                              }
                                              attrName={[
                                                "addPoliciesdata",
                                                "payType",
                                                "data",
                                                pType.name,
                                              ]}
                                              value_update={updatePayTypeValues}
                                              warn_status={false}
                                              inputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    %
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                            <Grid container spacing={3}>
                              <>
                                {toggle === false ? (
                                  <Grid xs={12} className="pt-0">
                                    <h6>
                                      100% of the premium value will be paid by
                                      the Client/Employer
                                    </h6>
                                  </Grid>
                                ) : null}

                                {toggle === true ? (
                                  <Grid xs={12} className="pt-0">
                                    <h6>
                                      Define % of the premium value to be paid
                                      by the employee for the respective
                                      associated relationship
                                    </h6>
                                  </Grid>
                                ) : null}
                              </>
                            </Grid>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* Rider section start */}
                    {addPoliciesdata.productType.name === "Group Term" && (
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("riders");
                            }}
                            ref={riders}
                          >
                            <div className="sectionTitle">
                              <h4>Riders</h4>
                            </div>

                            <Grid container spacing={3}>
                              <Grid
                                xs
                                lg={8}
                                className="switchFields"
                                display={"flex"}
                                alignItems="center"
                                sx={{ height: "70px" }}
                              >
                                <span
                                  style={{
                                    fontSize: "16px",
                                    marginLeft: "12px",
                                  }}
                                >
                                  Enable Riders
                                </span>
                                <input
                                  type="checkbox"
                                  checked={addPoliciesdata.is_rider}
                                  className="switch ml-3"
                                  onClick={(e) => {
                                    toggleEnableRiders(e);
                                  }}
                                />
                              </Grid>
                              {addPoliciesdata.is_rider && (
                                <Grid xs="auto">
                                  <Link
                                    className="blueBtn addfile ml-1"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (!addPoliciesdata.is_rider) {
                                        return toast.warn(
                                          "Please enable Rider"
                                        );
                                      }
                                      setAddPoliciesdata((prev) => ({
                                        ...prev,
                                        rider: [
                                          ...(prev.rider || []),
                                          {
                                            name: "",
                                            description: "",
                                          },
                                        ],
                                        benefits_detail_flat: [
                                          ...(prev.benefits_detail_flat || []),
                                          {
                                            label: "",
                                            enable: false,
                                            amount: "",
                                            description: "",
                                          },
                                        ],
                                        rider_premium_flat: [
                                          ...prev.rider_premium_flat,
                                          {
                                            proreta_by: "YEARLY",
                                            premium_split: "",
                                            rider_name: "",
                                            livesInfoFlat: [
                                              {
                                                min: "",
                                                max: "",
                                                name: "",
                                                coverageTypeData: [
                                                  {
                                                    code: "",
                                                    name: "",
                                                    value: 0,
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                        benefits_detail_graded:
                                          prev.benefits_detail_graded.map(
                                            (dataGrade) => ({
                                              ...dataGrade,
                                              riders: [
                                                ...dataGrade.riders,
                                                {
                                                  label: "",
                                                  enable: false,
                                                  amount: "",
                                                  description: "",
                                                },
                                              ],
                                            })
                                          ),
                                      }));

                                      setAddPoliciesdataWarn((prev: any) => ({
                                        ...prev,
                                        rider: [
                                          ...(prev.rider || []),
                                          {
                                            name: false,
                                            description: false,
                                          },
                                        ],
                                        benefits_detail_flat: [
                                          ...(prev.benefits_detail_flat || []),
                                          {
                                            label: false,
                                            enable: false,
                                            amount: false,
                                            description: false,
                                          },
                                        ],
                                        rider_premium_flat: [
                                          ...prev.rider_premium_flat,
                                          {
                                            proreta_by: false,
                                            premium_split: false,
                                            rider_name: false,
                                            livesInfoFlat: [
                                              {
                                                min: false,
                                                max: false,
                                                name: false,
                                                coverageTypeData: [
                                                  {
                                                    code: false,
                                                    name: false,
                                                    value: false,
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      }));
                                    }}
                                  >
                                    Add
                                  </Link>
                                </Grid>
                              )}
                            </Grid>
                            {addPoliciesdata.is_rider &&
                              addPoliciesdata.rider?.map(
                                (rider: any, index: number) => (
                                  <Grid container spacing={3}>
                                    <Grid xs lg={4}>
                                      <RKTextField
                                        class_name="inputField"
                                        title={"Rider Name"}
                                        value={rider.name}
                                        attrName={[
                                          "addPoliciesdata",
                                          "rider",
                                          "name",
                                          "input_fields",
                                          index,
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.rider[index].name
                                        }
                                      />
                                    </Grid>
                                    <Grid xs lg={4}>
                                      <TextAreaField
                                        class_name="inputField multiline"
                                        title={"Description"}
                                        value={rider.description}
                                        attrName={[
                                          "addPoliciesdata",
                                          "rider",
                                          "description",
                                          "input_fields",
                                          index,
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.rider[index]
                                            .description
                                        }
                                        required
                                        specialcharacter
                                        alphanumeric
                                      />
                                    </Grid>
                                    {index !== 0 && (
                                      <Grid xs="auto">
                                        <Link
                                          className="redBtn delete ml-1"
                                          sx={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setAddPoliciesdata((prev: any) => {
                                              const newRider = prev.rider
                                                ? [...prev.rider]
                                                : [];
                                              const benefits_detail_flat =
                                                prev.benefits_detail_flat
                                                  ? [
                                                    ...prev.benefits_detail_flat,
                                                  ]
                                                  : [];
                                              const rider_premium_flat =
                                                prev.rider_premium_flat
                                                  ? [...prev.rider_premium_flat]
                                                  : [];

                                              if (newRider) {
                                                newRider.splice(index, 1);
                                                benefits_detail_flat.splice(
                                                  index,
                                                  1
                                                );
                                                rider_premium_flat.forEach(
                                                  (rider) => {
                                                    rider.livesInfoFlat.splice(
                                                      index,
                                                      1
                                                    );
                                                  }
                                                );
                                              }

                                              let benefits_detail_graded =
                                                prev.benefits_detail_graded;
                                              if (
                                                prev.planType.name ===
                                                PolicyCoverageType.GRADED
                                              ) {
                                                benefits_detail_graded =
                                                  prev.benefits_detail_graded.map(
                                                    (grade: any) => {
                                                      const updatedRiders = [
                                                        ...grade.riders,
                                                      ];
                                                      updatedRiders.splice(
                                                        index,
                                                        1
                                                      );
                                                      return {
                                                        ...grade,
                                                        riders: updatedRiders,
                                                      };
                                                    }
                                                  );
                                              }

                                              return {
                                                ...prev,
                                                rider: newRider,
                                                benefits_detail_flat,
                                                rider_premium_flat,
                                                benefits_detail_graded,
                                              };
                                            });

                                            setAddPoliciesdataWarn(
                                              (prev: any) => {
                                                const newRider = prev.rider
                                                  ? [...prev.rider]
                                                  : [];
                                                const benefits_detail_flat =
                                                  prev.benefits_detail_flat
                                                    ? [
                                                      ...prev.benefits_detail_flat,
                                                    ]
                                                    : [];
                                                const rider_premium_flat =
                                                  prev.rider_premium_flat
                                                    ? [
                                                      ...prev.rider_premium_flat,
                                                    ]
                                                    : [];

                                                if (newRider) {
                                                  newRider.splice(index, 1);
                                                  benefits_detail_flat.splice(
                                                    index,
                                                    1
                                                  );
                                                  rider_premium_flat.forEach(
                                                    (rider) => {
                                                      rider.livesInfoFlat.splice(
                                                        index,
                                                        1
                                                      );
                                                    }
                                                  );
                                                }

                                                return {
                                                  ...prev,
                                                  rider: newRider,
                                                  benefits_detail_flat,
                                                  rider_premium_flat,
                                                };
                                              }
                                            );
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                )
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    {/* hide this section  */}
                    <Grid container spacing={3} className="px-0">
                      <Grid xs={12}>
                        <Box
                          className="detailSection"
                          onMouseEnter={() => {
                            SetSectionStatus("AgeBand");
                          }}
                          ref={AgeBand}
                        >
                          <div className="sectionTitle">
                            <h4>Age Bands for Lives</h4>
                          </div>
                          <Box className="band_lives">
                            <Grid container spacing={3}>
                              {ageBandData.map((data, index) => (
                                <Grid sm={12} md={12} lg={6} xl={6}>
                                  <h5 className="mb-3 mt-2">
                                    Age Band {index + 1}
                                  </h5>
                                  <div className="bandlives_inner">
                                    <SearchDropdown
                                      class_name="inputField"
                                      title="Min Age"
                                      value={
                                        addPoliciesdata.ageBand[index]?.min
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "ageBand",
                                        "min",
                                        "drop_down",
                                        index,
                                      ]}
                                      value_update={updateMasterState}
                                      data={minage_data}
                                      warn_status={
                                        addPoliciesdataWarn.ageBand[index].min
                                      }
                                      error_message="select min age"
                                      disabled={index <= ageBandData.length - 2}
                                    />
                                    <SearchDropdown
                                      class_name="inputField"
                                      title="Max Age"
                                      value={
                                        addPoliciesdata.ageBand[index]?.max
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "ageBand",
                                        "max",
                                        "drop_down",
                                        index,
                                      ]}
                                      value_update={updateMasterState}
                                      data={maxage_data}
                                      warn_status={
                                        addPoliciesdataWarn.ageBand[index].max
                                      }
                                      error_message="select max age"
                                      disabled={
                                        index <= ageBandData.length - 2 ||
                                        !addPoliciesdata.ageBand[
                                          ageBandData.length - 1
                                        ]?.min
                                      }
                                    />
                                    <div
                                      className="actionBtns"
                                      style={{ display: "flex" }}
                                    >
                                      {" "}
                                      {index > ageBandData.length - 2 && (
                                        <Link
                                          className="blueBtn addfile ml-1"
                                          sx={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let check =
                                              addPoliciesdata.ageBand.some(
                                                (data) => !data.max || !data.min
                                              );

                                            if (!check) {
                                              add_band_for_lives(index);
                                              breakAgeBand("", "onadd");
                                            } else {
                                              return toast.warning(
                                                "Please select empty Age band"
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                      {index === 0 ? null : (
                                        <Link
                                          className="redBtn delete ml-1"
                                          sx={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            remove_band_for_lives(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                            {errorMaxChildAge &&
                            <span style={{color:"#eb5757",fontSize:"11px" ,padding:"12px 0 0 12px"}}>Max child age not included in selected age bands</span>
                            }
                          {areAgeBandsConsistent(addPoliciesdata.ageBand)? "" : <span style={{color:"#eb5757",fontSize:"14px" ,padding:"12px 0 0 12px"}}>Note: Selected age bands are Inconsistent</span>}

                          </Box>
                        </Box>
                        {planType === 0 ? (
                          <>
                            <Box
                              className="detailSection mb-0"
                              onMouseEnter={() => {
                                SetSectionStatus("EmployeesDetails");
                              }}
                              ref={EmployeesDetails}
                            >
                              <div className="sectionTitle">
                                <h4>SI/SA, Features & Exclusions Details</h4>
                                <div className="actionBtns ">
                                  {addPoliciesdata.planType.name === "FLAT" ? (
                                    ""
                                  ) : (
                                    <Link
                                      className="rounded_add_icons mr-3"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setEmpGradeData((empGradeData) => [
                                          ...empGradeData,
                                          {
                                            sum_insured: "5000",
                                            emp_count: "5",
                                            features: ["sdf", "sdfsd"],
                                            deleteable: true,
                                          },
                                        ]);
                                      }}
                                    >
                                      Add Grade
                                    </Link>
                                  )}

                                  {/* <p className="lives">
                              Total Lives - <span> 300</span>
                            </p> */}
                                </div>
                              </div>
                              <Box className="employeeDetail">
                                {empGradeData.map((data, index) => (
                                  <Grid
                                    container
                                    spacing={0}
                                    className=" gradedSection ml-3 mr-3 mb-4"
                                  >
                                    <Grid xs={12} className="pb-3">
                                      <>
                                        <Grid container spacing={3}>
                                          <Grid xs={6}>
                                            <p>
                                              {" "}
                                              {addPoliciesdata?.productType
                                                ?.name === "Group Super TopUp"
                                                ? "Super Top-Up Sum Insured"
                                                : addPoliciesdata?.productType
                                                  ?.name === "Group Term"
                                                  ? "Sum Assured"
                                                  : "Sum Insured"}
                                            </p>
                                          </Grid>
                                          <Grid
                                            xs={6}
                                            sx={{ textAlign: "right" }}
                                          >
                                            {index === 0 ? (
                                              ""
                                            ) : (
                                              <Link
                                                className="redBtn delete ml-1"
                                                sx={{
                                                  textDecoration: "none",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  remove_band_for_lives(index)
                                                }
                                              />
                                            )}
                                          </Grid>
                                          <Grid xs={6}>
                                            <RKTextField
                                              class_name="inputField"
                                              title={
                                                addPoliciesdata?.productType
                                                  ?.name === "Group Term"
                                                  ? "Sum Assured"
                                                  : "Sum Insured"
                                              }
                                              value={formatCurrency(
                                                Number(
                                                  addPoliciesdata
                                                    .benefitsDetailsflat
                                                    .sumInsured ?? 0
                                                )
                                              )}
                                              attrName={[
                                                "addPoliciesdata",
                                                "benefitsDetailsflat",
                                                "sumInsured",
                                                "input_fields",
                                                index,
                                              ]}
                                              value_update={updateMasterState}
                                              warn_status={
                                                addPoliciesdataWarn
                                                  .benefitsDetailsflat
                                                  .sumInsured
                                              }
                                              error_messg="Enter Sum Insured amount"
                                              required
                                            />
                                          </Grid>
                                          {addPoliciesdata?.productType
                                            ?.name === "Group Super TopUp" ? (
                                            <Grid
                                              xs={6}
                                              className="super_topup"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h6 className="mb-0">
                                                {" "}
                                                Deductible
                                              </h6>
                                              <div
                                                className="ml-3"
                                                style={{ width: "300px" }}
                                              >
                                                <RKTextField
                                                  class_name="inputField"
                                                  title="Enter value for Deductible"
                                                  value={formatCurrency(
                                                    Number(
                                                      addPoliciesdata
                                                        .benefitsDetailsflat
                                                        .deductible
                                                    )
                                                  )}
                                                  attrName={[
                                                    "addPoliciesdata",
                                                    "benefitsDetailsflat",
                                                    "deductible",
                                                    "input_fields",
                                                    index,
                                                  ]}
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                  warn_status={
                                                    addPoliciesdataWarn
                                                      .benefitsDetailsflat
                                                      .deductible
                                                  }
                                                  error_messg="Enter deductible amount"
                                                  required
                                                  validation_type="numeric"
                                                />
                                              </div>
                                            </Grid>
                                          ) : (
                                            ""
                                          )}
                                          {addPoliciesdata.productType.name ==
                                            "Group Term" && (
                                              <Grid xs={12}>
                                                {addPoliciesdata.is_rider &&
                                                  addPoliciesdata.benefits_detail_flat?.map(
                                                    (
                                                      riders: any,
                                                      index: number
                                                    ) =>
                                                      riders.label && (
                                                        <Grid
                                                          container
                                                          spacing={3}
                                                        >
                                                          <Grid
                                                            xs
                                                            lg={4}
                                                            className="switchFields"
                                                            display={"flex"}
                                                            alignItems="center"
                                                            sx={{
                                                              height: "70px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: "14px",
                                                                marginLeft:
                                                                  "12px",
                                                                fontWeight: 600,
                                                              }}
                                                            >
                                                              {riders?.label}
                                                            </span>
                                                            <input
                                                              type="checkbox"
                                                              className="switch ml-3"
                                                              checked={
                                                                riders.enable
                                                              }
                                                              onClick={(e) => {
                                                                toggleEnableRidersFeaure(
                                                                  e,
                                                                  index
                                                                );
                                                              }}
                                                            />
                                                          </Grid>
                                                          {riders.enable && (
                                                            <Grid xs lg={4}>
                                                              <RKTextField
                                                                class_name="inputField"
                                                                title={"Rider SA"}
                                                                value={formatCurrency(
                                                                  Number(
                                                                    riders.amount
                                                                  )
                                                                )}
                                                                attrName={[
                                                                  "addPoliciesdata",
                                                                  "benefits_detail_flat",
                                                                  "amount",
                                                                  "input_fields",
                                                                  index,
                                                                ]}
                                                                value_update={
                                                                  updateMasterState
                                                                }
                                                                warn_status={
                                                                  addPoliciesdataWarn
                                                                    .benefits_detail_flat[
                                                                    index
                                                                  ].amount
                                                                }
                                                              />
                                                            </Grid>
                                                          )}
                                                        </Grid>
                                                      )
                                                  )}
                                              </Grid>
                                            )}
                                          <Grid xs={12}>
                                            <div className="sectionsHeading">
                                              <h6>Features</h6>
                                              <div className="actionBtns ">
                                                <Link
                                                  className="rounded_add_icons mr-3 addfile blueBtn"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setAddFeature(true);
                                                    setShowLoader(true);
                                                  }}
                                                >
                                                  Add/Edit
                                                </Link>
                                              </div>
                                            </div>
                                          </Grid>

                                          <Box className="featuresDetail px-0">
                                            {addPoliciesdata
                                              ?.benefitsDetailsflat
                                              ?.features?.[0]?.value ? (
                                              <Grid container spacing={3}>
                                                {addPoliciesdata.benefitsDetailsflat.features?.map(
                                                  (data: any) => (
                                                    <Grid xs={6}>
                                                      <div className="features_list">
                                                        <ul>
                                                          <li>
                                                            {data.name}
                                                            <span>
                                                              {data.value}{" "}
                                                              {data.unit ===
                                                                "Percentage"
                                                                ? "%"
                                                                : data.unit ===
                                                                  "Currency"
                                                                  ? ""
                                                                  : data.unit}
                                                            </span>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </Grid>
                                                  )
                                                )}
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                          <Box className="w-100">
                                            {addPoliciesdataWarn
                                              .benefitsDetailsflat.features ===
                                              true ? (
                                              <span
                                                className="inputField"
                                                style={{
                                                  color: "#eb5757",
                                                  fontSize: "11px",
                                                  padding: "0 16px",
                                                  // position: "relative",
                                                  // right: "0",
                                                  // bottom: "-20px",
                                                }}
                                              >
                                                Add features
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </Box>

                                          <Grid xs={12}>
                                            <div className="sectionsHeading">
                                              <h6>Exclusion</h6>
                                              <div className="actionBtns ">
                                                <Link
                                                  className="rounded_add_icons mr-3 addfile blueBtn"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setShowLoader(true);
                                                    setAddExclusion(true);
                                                  }}
                                                >
                                                  Add/Edit
                                                </Link>
                                              </div>
                                            </div>
                                          </Grid>
                                          <Box className="exclusionDetail px-0">
                                            <Grid container spacing={3}>
                                              <Grid xs={12}>
                                                <div className="features_list">
                                                  <ul>
                                                    {addPoliciesdata.benefitsDetailsflat.exclusion && addPoliciesdata.benefitsDetailsflat.exclusion?.length > 0 && (addPoliciesdata.benefitsDetailsflat.exclusion[0].title || addPoliciesdata.benefitsDetailsflat.exclusion[0].name) && addPoliciesdata.benefitsDetailsflat.exclusion?.map(
                                                      (data: any) => {
                                                        return (
                                                          <li>
                                                            {data?.title ||
                                                              data?.name}
                                                            <span>
                                                              {data.description}
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                          <Box className="w-100">
                                            {addPoliciesdataWarn
                                              .benefitsDetailsflat.exclusion ===
                                              true ? (
                                              <span
                                                className="inputField"
                                                style={{
                                                  color: "#eb5757",
                                                  fontSize: "11px",
                                                  padding: "0 16px",
                                                  position: "unset",
                                                  // right: "0",
                                                  // bottom: "-20px",
                                                }}
                                              >
                                                Add exclusion
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                        </Grid>
                                      </>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            </Box>
                          </>
                        ) : planType === 1 || planType === 2 ? (
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("EmployeesDetails");
                            }}
                            ref={EmployeesDetails}
                          >
                            <div className="sectionTitle">
                              <h4>SI/SA, Features & Exclusions Details</h4>
                              <div className="actionBtns ">
                                <Link
                                  className="rounded_add_icons mr-3"
                                  sx={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let data: quotesDTONew = JSON.parse(
                                      JSON.stringify(addPoliciesdata)
                                    );
                                    let dataWarn: any = {
                                      ...addPoliciesdataWarn,
                                    };
                                    data.benefitsDetailsGrade = [
                                      ...data.benefitsDetailsGrade,
                                      {
                                        gradeDivisions: [{ id: "", label: "" }],
                                        name: "",
                                        deductible: "",
                                        sumInsured: data.benefitsDetailsGrade[
                                          data.benefitsDetailsGrade.length - 1
                                        ]
                                          ? (
                                            Number(
                                              extractNumbersFromString(
                                                data.benefitsDetailsGrade[
                                                  data.benefitsDetailsGrade
                                                    .length - 1
                                                ].sumInsured
                                              )
                                            ) + 1
                                          ).toString()
                                          : "0",
                                        features:
                                          data.benefitsDetailsGrade[
                                            data.benefitsDetailsGrade.length - 1
                                          ]?.features,
                                        exclusion:
                                          data.benefitsDetailsGrade[
                                            data.benefitsDetailsGrade.length - 1
                                          ]?.exclusion,
                                      },
                                    ];
                                    data.benefits_detail_graded = [
                                      ...data.benefits_detail_graded,
                                      JSON.parse(
                                        JSON.stringify({
                                          grade_name: `Grade ${data.benefits_detail_graded.length +
                                            1
                                            }`,
                                          riders: [
                                            ...data.benefits_detail_graded[
                                              data.benefits_detail_graded
                                                .length - 1
                                            ].riders,
                                          ],
                                        })
                                      ),
                                    ];

                                    dataWarn.benefitsDetailsGrade = [
                                      ...dataWarn.benefitsDetailsGrade,
                                      {
                                        designation: false,
                                        deductible: false,
                                        name: false,
                                        sumInsured: false,
                                        features: [
                                          {
                                            name: false,
                                            value: false,
                                            featureId: false,
                                            groupId: false,
                                          },
                                        ],
                                        exclusion: [
                                          {
                                            name: false,
                                            description: false,
                                            exclusionId: false,
                                          },
                                        ],
                                      },
                                    ];
                                    setAddPoliciesdata(data);
                                    setExpanded([
                                      ...expanded.map((item) => {
                                        return { expanded: false };
                                      }),
                                      { expanded: true },
                                    ]);
                                    setAddPoliciesdataWarn(dataWarn);
                                    gradedAddData(featuresListdata);
                                    exclusionsgradedAddData(exclusionsListdata);
                                    filterDropDownData();
                                    setlivesSelectedGrade((prev) => [
                                      ...prev,
                                      { selectedGrade: 0 },
                                    ]);
                                  }}
                                >
                                  Add Grade (Graded)
                                </Link>
                                <p className="lives">
                                  {/* Total Lives - <span> 300</span> */}
                                </p>
                              </div>
                            </div>
                            <Box className="employeeDetail">
                              {addPoliciesdata.benefitsDetailsGrade.map(
                                (data, index: any) => (
                                  <Accordion
                                    className="Accordion_section gradedSection gradepadding"
                                    onChange={() => {
                                      let newExpanded = [...expanded];

                                      newExpanded[index].expanded =
                                        !expanded[index].expanded;

                                      setExpanded(newExpanded);
                                    }}
                                    expanded={expanded[index].expanded}
                                  >
                                    <Grid container spacing={0}>
                                      <Grid xs={4} className="px-0">
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          style={{
                                            display: "inline-block",
                                            width: "110px",
                                          }}
                                        >
                                          <Typography
                                            className="title"
                                            style={{ width: "150px" }}
                                          >
                                            <h5>Grade {index + 1} :-</h5>
                                          </Typography>
                                        </AccordionSummary>
                                      </Grid>
                                      <Grid
                                        xs={8}
                                        style={{
                                          alignSelf: "Center",
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <h4>Grade {index + 1} </h4>
                                        {index === 0 ? null : (
                                          <div
                                            className="text-right"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <Link
                                              className="redBtn delete ml-3"
                                              underline="none"
                                              onClick={() => {
                                                let data = {
                                                  ...addPoliciesdata,
                                                };
                                                let dataExpand = [...expanded];
                                                data.benefitsDetailsGrade.splice(
                                                  index,
                                                  1
                                                );
                                                dataExpand.splice(index, 1);

                                                // data.benefitsDetailsGrade = data.benefitsDetailsGrade.filter((_,i)=>{return  i !== index});
                                                setAddPoliciesdata(data);
                                                setExpanded(dataExpand);
                                              }}
                                            >
                                              Remove
                                            </Link>
                                          </div>
                                        )}
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography></Typography>
                                        </AccordionSummary>
                                      </Grid>
                                    </Grid>

                                    <AccordionDetails style={{ padding: "0" }}>
                                      <Grid container spacing={0}>
                                        <Grid xs={12} className="pb-3">
                                          <>
                                            <Grid container spacing={3}>
                                              <Grid
                                                xs={3}
                                                style={{ alignSelf: "center" }}
                                              >
                                                <p>
                                                  {" "}
                                                  {addPoliciesdata?.productType
                                                    ?.name ===
                                                    "Group Super TopUp"
                                                    ? "Select Super Top-Up Sum Insured"
                                                    : addPoliciesdata
                                                      ?.productType?.name ===
                                                      "Group Term"
                                                      ? "Sum Assured"
                                                      : "Sum Insured"}
                                                </p>
                                              </Grid>

                                              <Grid xs={12}>
                                                <RKTextField
                                                  class_name="inputField"
                                                  title={
                                                    addPoliciesdata?.productType
                                                      ?.name === "Group Term"
                                                      ? "Sum Assured"
                                                      : "Sum Insured"
                                                  }
                                                  value={formatCurrencyAccToUser(
                                                    Number(data?.sumInsured),
                                                    getCurrencyCodeRegex(
                                                      userState.localInformation
                                                        .currency
                                                    ),
                                                    userState.localInformation
                                                      .countryCurrencyCode,
                                                    0
                                                  )}
                                                  attrName={[
                                                    "addPoliciesdata",
                                                    "benefitsDetailsGrade",
                                                    "sumInsured",
                                                    "input_fields",
                                                    index,
                                                  ]}
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                  warn_status={
                                                    addPoliciesdataWarn
                                                      .benefitsDetailsGrade[
                                                      index
                                                    ].sumInsured
                                                  }
                                                  // error_messg="Enter Sum Insured amount"
                                                  error_messg = {
                                                    index === 0
                                                      ? ""
                                                      : data?.sumInsured
                                                      ? "Sum insured value cannot be less than or equal to previous grade."
                                                      : "Enter Sum Insured amount"
                                                  }
                                                  required
                                                />
                                              </Grid>
                                              {addPoliciesdata?.productType
                                                ?.name ===
                                                "Group Super TopUp" ? (
                                                <Grid
                                                  xs={6}
                                                  className="super_topup"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <h6 className="mb-0">
                                                    {" "}
                                                    Deductible
                                                  </h6>
                                                  <div
                                                    className="ml-3"
                                                    style={{ width: "300px" }}
                                                  >
                                                    <RKTextField
                                                      class_name="inputField"
                                                      title="Enter value for Deductible"
                                                      value={
                                                        data?.deductible
                                                          ? formatCurrency(
                                                            Number(
                                                              data?.deductible
                                                            )
                                                          )
                                                          : formatCurrency(
                                                            Number(0)
                                                          )
                                                      }
                                                      attrName={[
                                                        "addPoliciesdata",
                                                        "benefitsDetailsGrade",
                                                        "deductible",
                                                        "input_fields",
                                                        index,
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        addPoliciesdataWarn
                                                          .benefitsDetailsGrade[
                                                          index
                                                        ].deductible
                                                      }
                                                      error_messg="Enter deductible amount"
                                                      required
                                                      validation_type="numeric"
                                                    />
                                                  </div>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                              {addPoliciesdata.productType
                                                .name === "Group Term" && (
                                                  <Grid xs={12}>
                                                    {addPoliciesdata.is_rider &&
                                                      addPoliciesdata.benefits_detail_graded[
                                                        index
                                                      ].riders?.map(
                                                        (
                                                          riders: any,
                                                          RiderIndex: number
                                                        ) =>
                                                          riders.label && (
                                                            <Grid
                                                              container
                                                              spacing={3}
                                                            >
                                                              <Grid
                                                                xs
                                                                lg={4}
                                                                className="switchFields"
                                                                display={"flex"}
                                                                alignItems="center"
                                                                sx={{
                                                                  height: "70px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "14px",
                                                                    marginLeft:
                                                                      "12px",
                                                                    fontWeight: 600,
                                                                  }}
                                                                >
                                                                  {riders?.label}
                                                                </span>
                                                                <input
                                                                  type="checkbox"
                                                                  className="switch ml-3"
                                                                  checked={
                                                                    riders.enable
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    toggleEnableRidersFeatureGrade(
                                                                      e,
                                                                      index,
                                                                      RiderIndex
                                                                    );
                                                                  }}
                                                                />
                                                              </Grid>
                                                              {riders.enable && (
                                                                <Grid xs lg={4}>
                                                                  <RKTextField
                                                                    class_name="inputField"
                                                                    title={
                                                                      "Rider SA"
                                                                    }
                                                                    value={formatCurrency(
                                                                      Number(
                                                                        riders.amount
                                                                      )
                                                                    )}
                                                                    attrName={[
                                                                      "addPoliciesdata",
                                                                      "benefits_detail_graded",
                                                                      "amount",
                                                                      "input_fields",
                                                                      index,
                                                                      RiderIndex,
                                                                    ]}
                                                                    value_update={
                                                                      updateMasterState
                                                                    }
                                                                    warn_status={
                                                                      addPoliciesdataWarn
                                                                        .benefits_detail_flat[
                                                                        index
                                                                      ]?.amount
                                                                    }
                                                                  />
                                                                </Grid>
                                                              )}
                                                            </Grid>
                                                          )
                                                      )}
                                                  </Grid>
                                                )}

                                              <Grid xs={12}>
                                                <div className="sectionsHeading">
                                                  <h6>Features</h6>
                                                  <div className="actionBtns ">
                                                    <Link
                                                      className="rounded_add_icons mr-3"
                                                      sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setAddFeature(true);
                                                        setShowLoader(true);
                                                        setAddFeatureExclusionIndex(
                                                          index
                                                        );
                                                        localStorage.setItem(
                                                          "selectedAddPolicy",
                                                          JSON.stringify(
                                                            featuresListdataGraded[
                                                            index
                                                            ]
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      Add/Edit
                                                    </Link>
                                                  </div>
                                                </div>
                                              </Grid>

                                              <Box className="featuresDetail px-0">
                                                <Grid container spacing={3}>
                                                  {addPoliciesdata.benefitsDetailsGrade[
                                                    index
                                                  ].features?.map(
                                                    (
                                                      data: any,
                                                      indexx: any
                                                    ) => (
                                                      <>
                                                        <Grid xs={6}>
                                                          <div className="features_list">
                                                            <ul>
                                                              {addPoliciesdataWarn
                                                                ?.benefitsDetailsGrade[
                                                                index
                                                              ]?.features[
                                                                indexx
                                                              ]?.name ===
                                                                true ? (
                                                                <span
                                                                  className="inputField"
                                                                  style={{
                                                                    color:
                                                                      "#eb5757",
                                                                    fontSize:
                                                                      "11px",
                                                                    position:
                                                                      "relative",
                                                                    right: "0",
                                                                    bottom:
                                                                      "-20px",
                                                                  }}
                                                                >
                                                                  Please Update
                                                                  features
                                                                </span>
                                                              ) : (
                                                                ""
                                                              )}
                                                              {addPoliciesdata
                                                                .benefitsDetailsGrade[
                                                                index
                                                              ].features[indexx]
                                                                .value ? (
                                                                <li>
                                                                  {data.name}
                                                                  <span>
                                                                    {data.value}{" "}
                                                                    {data.unit ===
                                                                      "Percentage"
                                                                      ? "%"
                                                                      : data.unit ===
                                                                        "Currency"
                                                                        ? ""
                                                                        : data.unit}
                                                                  </span>
                                                                </li>
                                                              ) : null}
                                                            </ul>
                                                          </div>
                                                        </Grid>
                                                      </>
                                                    )
                                                  )}
                                                </Grid>
                                              </Box>

                                              {/* Exclusion */}
                                              <Grid xs={12}>
                                                <div className="sectionsHeading">
                                                  <h6>Exclusion</h6>
                                                  <div className="actionBtns ">
                                                    <Link
                                                      className="rounded_add_icons mr-3"
                                                      sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setShowLoader(true);
                                                        setAddExclusion(true);
                                                        let updated_data: any =
                                                          [];
                                                        updated_data = [
                                                          ...Array(
                                                            addPoliciesdata
                                                              .benefitsDetailsGrade
                                                              .length + 1
                                                          ),
                                                        ].map(
                                                          () =>
                                                            exclusionsListdata
                                                        );

                                                        localStorage.setItem(
                                                          "addPolicy",
                                                          JSON.stringify(
                                                            updated_data
                                                          )
                                                        );

                                                        setexclusionsListdataGradedCopy(
                                                          updated_data
                                                        );

                                                        localStorage.setItem(
                                                          "selectedAddPolicy",
                                                          JSON.stringify(
                                                            exclusionsListdataGraded[
                                                            index
                                                            ]
                                                          )
                                                        );

                                                        setAddFeatureExclusionIndex(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      Add/Edit
                                                    </Link>
                                                  </div>
                                                </div>
                                              </Grid>
                                              <Box className="exclusionDetail px-0">
                                                <Grid container spacing={3}>
                                                  <Grid xs={12}>
                                                    <div className="features_list">
                                                      <ul>
                                                        {addPoliciesdata.benefitsDetailsGrade[
                                                          index
                                                        ].exclusion?.map(
                                                          (
                                                            data: any,
                                                            indexx: any
                                                          ) => (
                                                            <>
                                                              {addPoliciesdataWarn
                                                                ?.benefitsDetailsGrade[
                                                                index
                                                              ]?.exclusion[
                                                                indexx
                                                              ]?.name ===
                                                                true ? (
                                                                <span
                                                                  className="inputField"
                                                                  style={{
                                                                    color:
                                                                      "#eb5757",
                                                                    fontSize:
                                                                      "11px",
                                                                    position:
                                                                      "relative",
                                                                    right: "0",
                                                                    bottom:
                                                                      "-20px",
                                                                  }}
                                                                >
                                                                  Please Update
                                                                  features
                                                                </span>
                                                              ) : (
                                                                ""
                                                              )}

                                                              {data.name ? (
                                                                <li>
                                                                  {data.name}
                                                                  <span>
                                                                    {
                                                                      data.description
                                                                    }
                                                                  </span>
                                                                </li>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Grid>
                                          </>
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )}
                            </Box>
                          </Box>
                        ) : null}
                        {addFeature ? (
                          <SelectFeature
                            setShowLoader={setShowLoader}
                            open_status={addFeature}
                            attrName={setAddFeature}
                            value_update={updateMasterState}
                            data_list={
                              addPoliciesdata.planType.name === "FLAT"
                                ? featuresListdata
                                : featuresListdataGraded[
                                AddFeatureexclusionIndex
                                ]
                            }
                            component_name={addPoliciesdata.planType.name}
                            index_value={AddFeatureexclusionIndex}
                            leng={addPoliciesdata.benefitsDetailsGrade.length}
                            productType={addPoliciesdata.productType.name}
                          />
                        ) : null}
                        {Addexclusion ? (
                          <Exclusion
                            open_status={Addexclusion}
                            attrName={setAddExclusion}
                            value_update={updateMasterState}
                            data_list={
                              addPoliciesdata.planType.name === "FLAT"
                                ? exclusionsListdata
                                : exclusionsListdataGraded[
                                AddFeatureexclusionIndex
                                ]
                            }
                            component_name={addPoliciesdata.planType.name}
                            index_value={AddFeatureexclusionIndex}
                            productType={addPoliciesdata.productType.name}
                            setShowLoader={setShowLoader}
                          />
                        ) : null}

                        <SelectvoluntryPlans
                          open_status={selectvoluntryPlans}
                          attrName={setSelectVoluntryPlans}
                          value_update={updateMasterState}
                        />
                      </Grid>
                    </Grid>

                    {/* premium Details */}
                    {/* <Grid
                    ref={premiumdetails}
                    container
                    spacing={3}
                    className="px-0"
                  >
                    <Grid xs={12}>
                      <Box className="detailSection mb-0">
                        <div className="sectionTitle">
                          <h4>Premium Details</h4>
                        </div>
                        <Grid container spacing={3}>
                          <Grid xs={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Net Premium"
                              value={formatCurrencyAccToUser(
                                addPoliciesdata.premiumDetails.netPremium,
                                getCurrencyCodeRegex(
                                  userState.localInformation.currency
                                ),
                                userState.localInformation.countryCurrencyCode,
                                0
                              )}
                              //value={addPoliciesdata.premiumDetails.netPremium}
                              attrName={[
                                "addPoliciesdata",
                                "premiumDetails",
                                "netPremium",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                addPoliciesdataWarn.premiumDetails.netPremium
                              }
                              validation_type="numeric"
                              error_messg="Enter Net Premium amount"
                              required
                            />
                          </Grid>
                          <Grid xs={3}>
                            <MultipleSearchDropdown
                              class_name="inputField"
                              title="Select Applicable Tax/Taxes"
                              value={
                                addPoliciesdata.premiumDetails.taxPercentage
                              }
                              attrName={[
                                "addPoliciesdata",
                                "premiumDetails",
                                "taxPercentage",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={true}
                              multiple_data={taxDropDown}
                            />
                          </Grid>
                          <Grid xs={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Tax Amount"
                              //value={formatCurrencyAccToUser(Number(addPoliciesdata.premiumDetails.taxAmount)?Number(addPoliciesdata.premiumDetails.taxAmount):"",getCurrencyCodeRegex(userState.localInformation.currency), userState.localInformation.countryCurrencyCode, 0)}
                              value={formatCurrencyAccToUser(
                                addPoliciesdata.premiumDetails.taxAmount,
                                getCurrencyCodeRegex(
                                  userState.localInformation.currency
                                ),
                                userState.localInformation.countryCurrencyCode,
                                0
                              )}
                              //value={addPoliciesdata.premiumDetails.taxAmount}
                              attrName={[
                                "addPoliciesdata",
                                "premiumDetails",
                                "taxAmount",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={false}
                              validation_type="numeric"
                              required
                              disabled={true}
                            />
                          </Grid>
                          <Grid xs={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Gross Premium"
                              value={formatCurrencyAccToUser(
                                addPoliciesdata.premiumDetails.grossPremium,
                                getCurrencyCodeRegex(
                                  userState.localInformation.currency
                                ),
                                userState.localInformation.countryCurrencyCode,
                                0
                              )}
                              //value={addPoliciesdata.premiumDetails.grossPremium}
                              attrName={[
                                "addPoliciesdata",
                                "premiumDetails",
                                "grossPremium",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={false}
                              validation_type="numeric"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid> */}
                    {/* PolicyDetails */}
                    {/* <Grid container spacing={3} className="px-0">
                    <Grid xs={12}>
                      <Box className="detailSection mb-0" ref={detailspolicy}>
                        <div className="sectionTitle">
                          <h4>Policy Details</h4>
                        </div>
                        <Grid container spacing={3}>
                          <Grid xs={4}>
                            <DatePicker
                              class_name="inputField"
                              title="Policy Start Date"
                              value={addPoliciesdata.policyDetails.startDate}
                              attrName={[
                                "addPoliciesdata",
                                "policyDetails",
                                "startDate",
                                "date",
                              ]}
                              value_update={updateMasterState}
                              error_message="Please enter start Date"
                              warn_status={
                                addPoliciesdataWarn.policyDetails.startDate
                              }
                              show_future={false}
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <DatePicker
                              class_name="inputField"
                              title="Policy End Date"
                              value={addPoliciesdata.policyDetails.endDate}
                              attrName={[
                                "addPoliciesdata",
                                "policyDetails",
                                "endDate",
                                "date",
                              ]}
                              value_update={updateMasterState}
                              error_message="Please enter End Date"
                              warn_status={false}
                              show_future={false}
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <RKTextField
                              class_name="inputField"
                              title="CD Balance"
                              value={addPoliciesdata.policyDetails.cdBalance}
                              attrName={[
                                "addPoliciesdata",
                                "policyDetails",
                                "cdBalance",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                addPoliciesdataWarn.policyDetails.cdBalance
                              }
                              validation_type="numeric"
                              required
                              error_messg="Please enter CD balance"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid> */}
                    {/* Plan Settings */}
                    <Grid container spacing={3} className="px-0 ">
                      <Grid xs={12}>
                        <Box
                          className="detailSection mb-0"
                          onMouseEnter={() => {
                            SetSectionStatus("PlansSettings");
                          }}
                          ref={PlansSettings}
                        >
                          <div className="sectionTitle">
                            <h4>Plans Settings</h4>
                          </div>
                          {/* <Grid container spacing={3}>
                          <Grid xs={4}>
                            <RKTextField
                              class_name="inputField"
                              title="Critical CD Balance Amount"
                              value={addPoliciesdata.policy.criticalCd}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "criticalCd",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                addPoliciesdataWarn.policy.criticalCd
                              }
                              validation_type="numeric"
                              required
                              error_messg="Please enter CD balance amount"
                            />
                          </Grid>
                          <Grid xs={4}>
                            <RKTextField
                              class_name="inputField"
                              title="Critical CD Balance - % of CD Balance"
                              value={addPoliciesdata.policy?.criticalPercentage}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "criticalPercentage",
                                "input_fields",
                              ]}
                              value_update={updateMasterState}
                              warn_status={false}
                              validation_type="numeric"
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <DatePicker
                              class_name="inputField"
                              title="Enrolment Start Date"
                              value={
                                addPoliciesdata?.policy?.enrolmentStartDate
                              }
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "enrolmentStartDate",
                                "date_picker",
                              ]}
                              value_update={updateMasterState}
                              error_message="Please enter Start Date"
                              warn_status={
                                addPoliciesdataWarn.policy.enrolmentStartDate
                              }
                              min_date={addPoliciesdata.policyDetails.startDate}
                              max_date={addPoliciesdata.policyDetails.endDate}
                              required
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xs={4}>
                            <DatePicker
                              class_name="inputField"
                              title="Enrolment End Date"
                              value={addPoliciesdata.policy?.enrolmentEndDate}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "enrolmentEndDate",
                                "date_picker",
                              ]}
                              value_update={updateMasterState}
                              error_message="Please enter End Date"
                              warn_status={
                                addPoliciesdataWarn.policy.enrolmentEndDate
                              }
                              min_date={
                                addPoliciesdata?.policy?.enrolmentStartDate
                                  ? addPoliciesdata?.policy?.enrolmentStartDate
                                  : new Date()
                              }
                              max_date={addPoliciesdata.policyDetails.endDate}
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <RKTextField
                              class_name="inputField"
                              title="Enrolment Peroid"
                              value={
                                addPoliciesdata.policy.period
                                  ? `${addPoliciesdata.policy.period} Days`
                                  : ""
                              }
                              attrName={[ let test = res.data.data[0].livesInfoFlat;
                                "addPoliciesdata",
                                "policy",
                                "period",
                                "input_disabled",
                              ]}
                              value_update={updateMasterState}
                              warn_status={false}
                              disabled
                            />
                          </Grid>
                          <Grid xs={4}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Grace Period"
                              value={addPoliciesdata.policy.gracePeriod}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "gracePeriod",
                                "drop_down_common",
                              ]}
                              value_update={updateMasterState}
                              dropdown_data={one_to_thirty_days_data}
                              warn_status={false}
                              required
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xs={4}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Maximum Members per Family"
                              value={addPoliciesdata.policy.maxMemberPerFamily}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "maxMemberPerFamily",
                                "drop_down_common",
                              ]}
                              value_update={updateMasterState}
                              dropdown_data={one_to_ten_data}
                              warn_status={false}
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Total Members per Family"
                              value={
                                addPoliciesdata.policy.totalMemberPerFamily
                              }
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "totalMemberPerFamily",
                                "drop_down_common",
                              ]}
                              value_update={updateMasterState}
                              dropdown_data={one_to_ten_data}
                              warn_status={false}
                              required
                            />
                          </Grid>
                          <Grid xs={4}>
                            <SelectDropdown
                              class_name="inputField"
                              title="New Employee Enrolment Period"
                              value={addPoliciesdata.policy.newMemberPeriod}
                              attrName={[
                                "addPoliciesdata",
                                "policy",
                                "newMemberPeriod",
                                "drop_down_common",
                              ]}
                              value_update={updateMasterState}
                              dropdown_data={one_to_thirty_days_data}
                              warn_status={false}
                              required
                            />
                          </Grid>
                        </Grid> */}
                          <Grid container spacing={3}>
                            <Grid
                              xs={4}
                              className="switchFields"
                              display={"flex"}
                              alignItems="center"
                              sx={{ height: "70px" }}
                            >
                              <span>Nomination</span>
                              <input
                                type="checkbox"
                                className="switch ml-3"
                                onClick={(e) => {
                                  toggleShowNominee(e);
                                }}
                              />

                              {showNominee ? (
                                <div
                                  className="ml-2"
                                  style={{ width: "300px" }}
                                >
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Maximum No. of Nominee"
                                    value={
                                      addPoliciesdata?.policy?.maxNomination
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "policy",
                                      "maxNomination",
                                      "drop_down_common",
                                    ]}
                                    value_update={updateMasterState}
                                    dropdown_data={one_to_ten_data}
                                    warn_status={
                                      addPoliciesdataWarn.policy.maxNomination
                                    }
                                  />
                                </div>
                              ) : null}
                            </Grid>
                            <Grid
                              xs={4}
                              className="switchFields"
                              display={"flex"}
                              alignItems="center"
                              sx={{ height: "70px" }}
                            >
                              <span>Health Card</span>
                              <input
                                type="checkbox"
                                className="switch ml-3"
                                onClick={(e) => {
                                  toggleShowhealth(e);
                                }}
                              />

                              {showhealth ? (
                                <div
                                  className="ml-2"
                                  style={{ width: "300px" }}
                                >
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Card Type"
                                    value={addPoliciesdata.policy.cardType}
                                    attrName={[
                                      "addPoliciesdata",
                                      "policy",
                                      "cardType",
                                      "drop_down_common",
                                    ]}
                                    value_update={updateMasterState}
                                    dropdown_data={cardType_data}
                                    warn_status={
                                      addPoliciesdataWarn.policy.cardType
                                    }
                                  />
                                </div>
                              ) : null}
                            </Grid>
                            <Grid xs={4}>
                              <SelectDropdown
                                class_name="inputField"
                                title="Health Card Issued"
                                value={addPoliciesdata.policy.healthCardIssued}
                                attrName={[
                                  "addPoliciesdata",
                                  "policy",
                                  "healthCardIssued",
                                  "drop_down_common",
                                ]}
                                value_update={updateMasterState}
                                dropdown_data={healthcardIssued_data}
                                warn_status={
                                  addPoliciesdataWarn.policy.healthCardIssued
                                }
                                disabled={
                                  addPoliciesdata.policy.cardType &&
                                    addPoliciesdata.policy.healthCard
                                    ? false
                                    : true
                                }
                              />
                            </Grid>

                            <Grid xs={12} className="px-0">
                              <>
                                <Grid container spacing={3}>
                                  <Grid xs={4}>
                                    <SelectDropdown
                                      class_name="inputField"
                                      title="Pro- Rata Premium Pricing By"
                                      value={addPoliciesdata.policy.proreta_by}
                                      attrName={[
                                        "addPoliciesdata",
                                        "policy",
                                        "proreta_by",
                                        "drop_down_common",
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={prorata}
                                      warn_status={false}
                                      required
                                    />
                                  </Grid>
                                  {/* <Grid sm={12} md={7} lg={4}>
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Health Card Issued"
                                    value={
                                      addPoliciesdata.policy.healthCardIssued
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "policy",
                                      "healthCardIssued",
                                      "drop_down_common",
                                    ]}
                                    value_update={updateMasterState}
                                    dropdown_data={healthcardIssued_data}
                                    warn_status={
                                      addPoliciesdataWarn.policy
                                        .healthCardIssued
                                    }
                                    disabled={
                                      addPoliciesdata.policy.cardType &&
                                        addPoliciesdata.policy.healthCard
                                        ? false
                                        : true
                                    }
                                  />
                                </Grid> */}

                                  {/* <Grid xs={2}></Grid> */}
                                  {/* <Grid
                                  sm={12}
                                  md={8}
                                  lg={6}
                                  className="switchFields"
                                  display={"flex"}
                                  alignItems="center"
                                  sx={{ height: "70px" }}
                                >
                                  <span>Corporate Buffer</span>
                                  <input
                                    type="checkbox"
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowcorporate(e);
                                    }}
                                  />
                                  {showcorporate ? (
                                    <div
                                      className="ml-2"
                                      style={{ width: "350px" }}
                                    >
                                      <RKTextField
                                        class_name="inputField"
                                        title="Corporate Buffer Value"
                                        value={
                                          formatCurrency(Number(addPoliciesdata.policy.corporateBuffer))
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "policy",
                                          "corporateBuffer",
                                          "drop_down_common",
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.policy
                                            .corporateBuffer
                                        }
                                        validation_type="numeric"
                                        required
                                        error_messg="Please enter corporate buffer value"
                                      />
                                    </div>
                                  ) : null}
                                </Grid> */}
                                </Grid>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* INSURER Details */}
                    <Grid container spacing={3} className="px-0 ">
                      <Grid xs={12}>
                        <Box
                          className="detailSection"
                          onMouseEnter={() => {
                            SetSectionStatus("InsurerDetails");
                          }}
                          ref={InsurerDetails}
                        >
                          <div className="sectionTitle">
                            <h4>Insurer Details</h4>
                            <div className="actionBtns">
                              <Link
                                className="blueBtn addfile"
                                sx={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let data: quotesDTONew = {
                                    ...addPoliciesdata,
                                  };
                                  let dataWarn: any = {
                                    ...addPoliciesdataWarn,
                                  };
                                  data.insurerDetails = [
                                    ...data.insurerDetails,
                                    {
                                      id: "",
                                      name: "",
                                      office: "",
                                      email: "",
                                    },
                                  ];
                                  dataWarn.insurerDetails = [
                                    ...data.insurerDetails,
                                    {
                                      name: false,
                                      office: false,
                                    },
                                  ];
                                  setAddPoliciesdataWarn(dataWarn);
                                  setAddPoliciesdata(data);
                                }}
                              >
                                Add Insurer
                              </Link>
                            </div>
                          </div>

                          <>
                            {addPoliciesdata?.insurerDetails?.map(
                              (data, index) => (
                                <Grid container spacing={3}>
                                  <Grid xs={3}>
                                    <SearchDropdown
                                      class_name="inputField"
                                      title="Select Insurer"
                                      value={
                                        addPoliciesdata?.insurerDetails[index]
                                          ?.name
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "insurerDetails",
                                        "name",
                                        "dropdown",
                                        index,
                                      ]}
                                      value_update={updateMasterState}
                                      data={insurerList}
                                      warn_status={
                                        addPoliciesdataWarn.insurerDetails[
                                          index
                                        ].name
                                      }
                                      error_message="Select insurer"
                                    />
                                  </Grid>
                                  <Grid xs={3}>
                                    <SelectDropdown
                                      class_name="inputField"
                                      title="Location"
                                      value={
                                        addPoliciesdata?.insurerDetails[index]
                                          ?.office
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "insurerDetails",
                                        "office",
                                        "dropdown",
                                        index,
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={
                                        dynamicLocationOffice[index]
                                      }
                                      warn_status={
                                        addPoliciesdataWarn.insurerDetails[
                                          index
                                        ].office
                                      }
                                      error_messg="Select office address"
                                    />
                                  </Grid>
                                  <Grid xs={3}>
                                    <RKTextField
                                      class_name="inputField"
                                      title="Email"
                                      value={
                                        addPoliciesdata?.insurerDetails[index]
                                          ?.email
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "insurerDetails",
                                        "email",
                                        "dropdown",
                                        index,
                                      ]}
                                      value_update={updateMasterState}
                                      warn_status={
                                        addPoliciesdataWarn.insurerDetails[
                                          index
                                        ].email
                                      }
                                      error_messg={
                                        !addPoliciesdata?.insurerDetails[index]
                                          ?.email?.length
                                          ? "Enter Email"
                                          : "Enter Valid Email"
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{
                                      alignSelf: "Center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    {index === 0 ? null : (
                                      <div
                                        className="text-right"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <Link
                                          className="redBtn delete ml-3"
                                          underline="none"
                                          onClick={() => {
                                            let data = { ...addPoliciesdata };
                                            let dataWarn = {
                                              ...addPoliciesdataWarn,
                                            };

                                            let dataLocation = [
                                              ...dynamicLocationOffice,
                                            ];

                                            data.insurerDetails.splice(
                                              index,
                                              1
                                            );
                                            dataWarn.insurerDetails.splice(
                                              index,
                                              1
                                            );

                                            dataLocation.splice(index, 1);
                                            setdynamicLocationOffice(
                                              dataLocation
                                            );
                                            setAddPoliciesdataWarn(dataWarn);
                                            setAddPoliciesdata(data);
                                          }}
                                        >
                                          Remove
                                        </Link>
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* "addPoliciesdata",
                  "policy",
                  "proreta_by",
                  "drop_down_common", */}
                    <Box
                      className={`detailSection ${addPoliciesdata.is_rider ? "lastsection" : ""
                        } `}
                      onMouseEnter={() => {
                        SetSectionStatus("RelationshipLives");
                      }}
                      ref={RelationshipLives}
                    >
                      <div className="sectionTitle">
                        <h4>Relationship-Lives Information</h4>
                        <div className="total_lives">
                          <p>
                            {indivisualGradeLives ? indivisualGradeLives : 0}{" "}
                            <span>Total Lives</span>
                          </p>
                        </div>
                      </div>

                      <div className="policy_lives">
                        <Grid container spacing={3}>
                          {addPoliciesdata?.planType?.name === "FLAT" ? (
                            <>
                              <Grid xs={12}>
                                <div className="table">
                                  <div className="livesinformation-table">
                                    <ul className="heading">
                                      <li>Age Band (in years)</li>
                                      <li>
                                        Premium Pricing for Addition/Deletion
                                        per Member
                                      </li>
                                      {addPoliciesdata?.planType?.coverageType?.map(
                                        (data: any) => (
                                          <li>{titleCase(data.name)}</li>
                                        )
                                      )}

                                      <li>Total Lives Count/Age Band</li>
                                    </ul>

                                    {modifiedArrayAgeBand?.map(
                                      (data: any, ageindex: any) => (
                                        <ul className="subheading">
                                          <li>
                                            {data.min}-{data.max}
                                          </li>
                                          {data.coverageTypeData?.map(
                                            (dataArr: any, index: number) => (
                                              <li>
                                                <RKTextField
                                                  validation_type="numeric"
                                                  class_name="inputField"
                                                  title={
                                                    index === 0
                                                      ? "Amount"
                                                      : "Lives"
                                                  }
                                                  // value={
                                                  //   dataArr.name === "amount"
                                                  //     ? formatCurrency(
                                                  //       Number(dataArr.value)
                                                  //     )
                                                  //     : dataArr.value>0 
                                                  // }
                                                  value={
                                                    dataArr.name === "amount"
                                                      ? formatCurrency(Number(dataArr.value))
                                                      : Number(dataArr.value) > 0
                                                        ? String(Number(dataArr.value))  // Removes leading zeros and converts back to string
                                                        : dataArr.value
                                                  }
                                                  attrName={[
                                                    ageindex,
                                                    index,
                                                    "flatData",
                                                  ]}
                                                  value_update={
                                                    dataArr.name ===
                                                      "total Live"
                                                      ? () => { }
                                                      : updateMasterStateLives
                                                  }
                                                  warn_status={false}
                                                  charcterlimit={
                                                    dataArr.name === "amount"
                                                      ? 12
                                                      : dataArr.name ===
                                                        "total Live"
                                                        ? 10
                                                        : 5
                                                  }
                                                  disabled={
                                                    dataArr.name ===
                                                      "total Live" || dataArr.name ===
                                                      "KIDS" && !(Number(extractNumbersFromString(data.min)) <= Number(extractNumbersFromString(addPoliciesdata.maxChildAge ?? "0")) && Number(extractNumbersFromString(data.max)) >= Number(extractNumbersFromString(addPoliciesdata.maxChildAge ?? "0")))
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            </>
                          ) : addPoliciesdata.planType.name === "GRADED" ? (
                            <Grid sm={12} md={12} lg={12}>
                              <Grid container spacing={3}>
                                {modifiedArrayAgeBand.length > 0 && (
                                  <>
                                    <Grid xs={8}>
                                      <ul className="coverageAmt mb-4">
                                        {modifiedArrayAgeBand &&
                                          modifiedArrayAgeBand.map(
                                            (data: any, mainIndex: any) => (
                                              <li key={mainIndex}>
                                                <div className="radioBox ">
                                                  <input
                                                    type="radio"
                                                    name="coveragesd"
                                                    onClick={() =>
                                                      setliveGraded(mainIndex)
                                                    }
                                                    checked={
                                                      liveGraded === mainIndex
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <label>
                                                    Grade {mainIndex + 1}
                                                  </label>
                                                </div>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </Grid>

                                    <Grid xs={12}>
                                      <div className="table">
                                        <div className="livesinformation-table">
                                          <ul className="heading">
                                            <li>Age Band (in years)</li>
                                            <li>
                                              Premium Pricing for
                                              Addition/Deletion per Member
                                            </li>
                                            {addPoliciesdata?.planType?.coverageType?.map(
                                              (data: any) => (
                                                <li>{titleCase(data.name)}</li>
                                              )
                                            )}
                                            <li>Total Lives Count/Age Band</li>
                                          </ul>

                                          {modifiedArrayAgeBand[liveGraded]
                                            ?.length > 0 &&
                                            modifiedArrayAgeBand[
                                              liveGraded
                                            ]?.map(
                                              (data: any, ageindex: any) => (
                                                <ul
                                                  key={ageindex}
                                                  className="subheading"
                                                >
                                                  <li>
                                                    {data.min}-{data.max}
                                                  </li>
                                                  {data?.coverageTypeData?.map(
                                                    (
                                                      dataArr: any,
                                                      index: number
                                                    ) => (
                                                      <li>
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            // data
                                                            //   ?.coverageTypeData
                                                            //   .length -
                                                            //   2 ===
                                                            index === 0
                                                              ? "Amount"
                                                              : "Lives"
                                                          }
                                                          value={
                                                            index === 0
                                                              ? formatCurrencyAccToUser(
                                                                dataArr?.value,
                                                                getCurrencyCodeRegex(
                                                                  userState
                                                                    .localInformation
                                                                    .currency
                                                                ),
                                                                userState
                                                                  .localInformation
                                                                  .countryCurrencyCode,
                                                                0
                                                              )
                                                              : dataArr?.value
                                                          }
                                                          attrName={[
                                                            ageindex,
                                                            index,
                                                            "gradeData",
                                                          ]}
                                                          value_update={
                                                            dataArr?.name ===
                                                              "total Live"
                                                              ? () => { }
                                                              : updateMasterStateLives
                                                          }
                                                          warn_status={false}
                                                          charcterlimit={
                                                            dataArr?.name ===
                                                              "amount"
                                                              ? 12
                                                              : dataArr?.name ===
                                                                "total Live"
                                                                ? 10
                                                                : 5
                                                          }
                                                          disabled={
                                                            dataArr?.name ===
                                                              "total Live" || dataArr.name ===
                                                              "KIDS" && !(Number(extractNumbersFromString(data.min)) <= Number(extractNumbersFromString(addPoliciesdata.maxChildAge ?? "0")) && Number(extractNumbersFromString(data.max)) >= Number(extractNumbersFromString(addPoliciesdata.maxChildAge ?? "0")))
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </div>
                    </Box>

                    {/* prorata rates for riders in group Term */}
                    {addPoliciesdata.is_rider && (
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection lastsection"
                            onMouseEnter={() => {
                              SetSectionStatus("RiderDetail");
                            }}
                            ref={RiderDetail}
                          >
                            <div className="sectionTitle">
                              <h4>Rider Details</h4>
                            </div>

                            {addPoliciesdata?.planType?.name ===
                              PolicyCoverageType.FLAT &&
                              addPoliciesdata.rider_premium_flat.map(
                                (
                                  rider_premium_flat: any,
                                  riderIndex: number
                                ) => (
                                  <>
                                    <Grid xs={12}>
                                      <h5 className="subHeading">
                                        {rider_premium_flat.rider_name}
                                      </h5>
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={3}
                                      className="ml-1 mr-1"
                                    >
                                      <Grid xs={12}>
                                        <Box className="detailSection">
                                          <div className="sectionTitle">
                                            <h4>
                                              Rider Premium Split Configuration
                                              & Rider Premium Pricing
                                            </h4>
                                          </div>

                                          <div className="policy_lives">
                                            <Grid container spacing={3}>
                                              <>
                                                <Grid xs={12}>
                                                  <Grid xs={4}>
                                                    <Grid xs={12}>
                                                      <h5 className="subHeadingh">
                                                        Premium value
                                                      </h5>
                                                    </Grid>
                                                    <SelectDropdown
                                                      class_name="inputField"
                                                      title="Pro- Rata Premium Pricing By"
                                                      value={
                                                        rider_premium_flat.proreta_by
                                                      }
                                                      attrName={[
                                                        "addPoliciesdata",
                                                        "rider_premium_flat",
                                                        "proreta_by",
                                                        riderIndex,
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      dropdown_data={prorata}
                                                      warn_status={false}
                                                      required
                                                    />
                                                  </Grid>
                                                  <Grid container spacing={3}>
                                                    <Grid
                                                      sm={6}
                                                      md={6}
                                                      lg={3}
                                                      xl={3}
                                                    >
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium Split
                                                          Configuration
                                                        </h5>
                                                      </Grid>
                                                      <div className="payment_inner_section">
                                                        <RKTextField
                                                          class_name="inputField"
                                                          // type="number"
                                                          title={"Employee"}
                                                          value={
                                                            rider_premium_flat.premium_split
                                                          }
                                                          attrName={[
                                                            "addPoliciesdata",
                                                            "rider_premium_flat",
                                                            "premium_split",
                                                            riderIndex,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            addPoliciesdataWarn
                                                              .rider_premium_flat[
                                                              riderIndex
                                                            ].premium_split
                                                          }
                                                          inputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                %
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          error_messg="Enter Split amount"
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>

                                                  <div className="table mt-2">
                                                    <div className="livesinformation-table">
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium Pricing
                                                        </h5>
                                                      </Grid>
                                                      <ul className="heading">
                                                        {rider_premium_flat?.livesInfoFlat?.map(
                                                          (
                                                            data: any,
                                                            ageindex: any
                                                          ) => (
                                                            <li>
                                                              {data.min}-
                                                              {data.max}
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>

                                                      <ul className="subheading">
                                                        {rider_premium_flat?.livesInfoFlat?.map(
                                                          (
                                                            data: any,
                                                            ageindex: any
                                                          ) => (
                                                            <li>
                                                              <RKTextField
                                                                validation_type="numeric"
                                                                class_name="inputField"
                                                                title={"Amount"}
                                                                value={formatCurrency(
                                                                  Number(
                                                                    data
                                                                      .coverageTypeData[0]
                                                                      .value
                                                                  )
                                                                )}
                                                                attrName={[
                                                                  "addPoliciesdata",
                                                                  "rider_premium_flat",
                                                                  riderIndex,
                                                                  ageindex,
                                                                ]}
                                                                value_update={
                                                                  updateMasterState
                                                                }
                                                                warn_status={
                                                                  addPoliciesdataWarn
                                                                    .rider_premium_flat[
                                                                    riderIndex
                                                                  ]
                                                                    ?.livesInfoFlat[
                                                                    ageindex
                                                                  ]
                                                                    ?.coverageTypeData[0]
                                                                    ?.value
                                                                }
                                                              />
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </>
                                            </Grid>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              )}

                            {addPoliciesdata?.planType?.name ===
                              PolicyCoverageType.GRADED &&
                              addPoliciesdata.rider_premium_graded.map(
                                (
                                  rider_premium_flat: any,
                                  riderIndex: number
                                ) => (
                                  <>
                                    <Grid xs={12}>
                                      <h5 className="subHeading">
                                        {rider_premium_flat.rider_name}
                                      </h5>
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={3}
                                      className="ml-1 mr-1"
                                    >
                                      <Grid xs={12}>
                                        <Box className="detailSection lastsection">
                                          <div className="sectionTitle">
                                            <h4>
                                              Rider Premium Split Configuration
                                              & Rider Premium Pricing
                                            </h4>
                                          </div>

                                          <div className="policy_lives">
                                            <Grid container spacing={3}>
                                              <Grid sm={12} md={12} lg={12}>
                                                <Grid container spacing={3}>
                                                  <Grid
                                                    sm={6}
                                                    md={6}
                                                    lg={4}
                                                    xl={4}
                                                    width={"12%"}
                                                  >
                                                    <Grid xs={12}>
                                                      <h5 className="subHeadingh">
                                                        Premium value
                                                      </h5>
                                                    </Grid>
                                                    <div className="payment_inner_section">
                                                      <SelectDropdown
                                                        class_name="inputField"
                                                        title="Pro- Rata Premium Pricing By"
                                                        value={
                                                          rider_premium_flat.proreta_by
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "rider_premium_graded",
                                                          "proreta_by",
                                                          riderIndex,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        dropdown_data={prorata}
                                                        warn_status={false}
                                                        required
                                                      />
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                  <Grid
                                                    sm={6}
                                                    md={6}
                                                    lg={3}
                                                    xl={3}
                                                  >
                                                    <Grid xs={12}>
                                                      <h5 className="subHeadingh">
                                                        Premium Split
                                                        Configuration
                                                      </h5>
                                                    </Grid>
                                                    <div className="payment_inner_section">
                                                      <RKTextField
                                                        class_name="inputField"
                                                        // type="number"
                                                        title={"Employee"}
                                                        value={
                                                          rider_premium_flat.premium_split
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "rider_premium_graded",
                                                          "premium_split",
                                                          riderIndex,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          addPoliciesdataWarn
                                                            .rider_premium_flat[
                                                            riderIndex
                                                          ].premium_split
                                                        }
                                                        inputProps={{
                                                          endAdornment: (
                                                            <InputAdornment position="end">
                                                              %
                                                            </InputAdornment>
                                                          ),
                                                        }}
                                                        error_messg="Enter Split amount"
                                                      />
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                  <>
                                                    <Grid xs={8}>
                                                      <ul
                                                        className="coverageAmt mb-4"
                                                        key={riderIndex}
                                                      >
                                                        {rider_premium_flat.livesInfoGrade &&
                                                          rider_premium_flat.livesInfoGrade.map(
                                                            (
                                                              data: any,
                                                              mainIndex: any
                                                            ) => (
                                                              <li
                                                                key={`${riderIndex}-${mainIndex}`}
                                                              >
                                                                <div
                                                                  className="radioBox"
                                                                  key={`${riderIndex}-${mainIndex}`}
                                                                >
                                                                  <input
                                                                    key={`${riderIndex}-${mainIndex}`}
                                                                    type="radio"
                                                                    name={`abc${riderIndex}-${mainIndex}`}
                                                                    onClick={() => {
                                                                      setlivesSelectedGrade(
                                                                        (
                                                                          prev
                                                                        ) => {
                                                                          const updatedGrades =
                                                                            [
                                                                              ...prev,
                                                                            ];
                                                                          updatedGrades[
                                                                            riderIndex
                                                                          ] = {
                                                                            selectedGrade:
                                                                              mainIndex,
                                                                          };
                                                                          return updatedGrades;
                                                                        }
                                                                      );
                                                                    }}
                                                                    checked={
                                                                      livesSelectedGrade[
                                                                        riderIndex
                                                                      ]
                                                                        ?.selectedGrade ==
                                                                      mainIndex
                                                                    }
                                                                  />
                                                                  <label
                                                                    htmlFor={`abc${mainIndex}`}
                                                                  >
                                                                    Grade
                                                                    {mainIndex +
                                                                      1}
                                                                  </label>
                                                                </div>
                                                              </li>
                                                            )
                                                          )}
                                                      </ul>
                                                    </Grid>

                                                    <Grid xs={12}>
                                                      <div className="table">
                                                        <div className="livesinformation-table">
                                                          <Grid xs={12}>
                                                            <h5 className="subHeadingh">
                                                              Premium Pricing
                                                            </h5>
                                                          </Grid>
                                                          <ul className="heading">
                                                            {rider_premium_flat?.livesInfoGrade[
                                                              livesSelectedGrade[
                                                                riderIndex
                                                              ]?.selectedGrade
                                                            ]?.data?.map(
                                                              (data: any) => (
                                                                <li>
                                                                  {data.min}-
                                                                  {data.max}
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>

                                                          <ul className="subheading">
                                                            {rider_premium_flat?.livesInfoGrade[
                                                              livesSelectedGrade[
                                                                riderIndex
                                                              ]?.selectedGrade
                                                            ]?.data?.map(
                                                              (
                                                                data: any,
                                                                ageindex: any
                                                              ) => (
                                                                <li>
                                                                  <RKTextField
                                                                    validation_type="numeric"
                                                                    class_name="inputField"
                                                                    title={
                                                                      "Amount"
                                                                    }
                                                                    value={formatCurrency(
                                                                      Number(
                                                                        data
                                                                          .coverageTypeData[0]
                                                                          .value
                                                                      )
                                                                    )}
                                                                    attrName={[
                                                                      "addPoliciesdata",
                                                                      "rider_premium_graded",
                                                                      riderIndex,
                                                                      livesSelectedGrade[
                                                                        riderIndex
                                                                      ]
                                                                        ?.selectedGrade,
                                                                      ageindex,
                                                                    ]}
                                                                    value_update={
                                                                      updateMasterState
                                                                    }
                                                                    warn_status={
                                                                      addPoliciesdataWarn
                                                                        .rider_premium_flat[
                                                                        riderIndex
                                                                      ]
                                                                        ?.livesInfoFlat[
                                                                        ageindex
                                                                      ]
                                                                        ?.coverageTypeData[0]
                                                                        ?.value
                                                                    }
                                                                  />
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </Grid>
                                                  </>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </>
                </Grid>

                {/* Footer Section */}
                <Grid container spacing={3} className="footerSection">
                  <Grid xs={12} className="ctaBtn">
                    <Button
                      onClick={() => navigate(-1)}
                      variant="outlined"
                      className="borderBtn"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      className="submitBtn"
                      disabled={disableSave}
                      onClick={() => {
                        submithandle();
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AddNewQuotes;
