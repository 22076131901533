import axios from "axios";
import Session from "../Session/Session";
import { toast } from "react-toastify";

export const GenerateTokken = async (api: string) => {
  return axios
    .post(
      api,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //username: "master@evervent.in", // super@evervent.in // sahil.dhami@evervent.in
          //password: "qawsed",
        },
      }
    )
    .then((res: any) => {
      // handle success
      let results = res.data;
      let status = results.status;
      let message = results.message;
      let response = results.data;

      if (status === "failed") {
        // console.log(message);
      }
      return [status, response];
    })
    .catch((error) => {
      return [error, {}];
    });
};

export const Post = async (api: string, params: {}, authToken?: any) => {
  let access_token = sessionStorage.getItem("access_tokken");
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Token": access_token,
    auth: authToken || user_token,
  };
  return axios
    .post(api, params, {
      headers: header_data,
    })
    .then((res) => {
      // handle success

      let results = res?.data;
      let status = results?.status;
      let message = results?.message;
      let response = results?.data;

      if (status === "failed") {
        alert(message);
      }

      return [status, response];
    })
    .catch((error) => {
      let data = error?.response?.data && error?.response?.data;
      let status = data?.status;
      let errors = data?.errors[0]?.param + " " + data?.errors[0]?.message;
      if (status === "failed") {
        console.log(errors);
      }
      if (error?.code !== "ERR_BAD_REQUEST") {
        console.log(error?.code);
      }
      return [data, {}];
    });
};

export const Patch = async (api: string, params: {}) => {
  let access_token = sessionStorage.getItem("access_tokken");
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Token": access_token,
    auth: user_token,
  };
  return axios
    .patch(api, params, {
      headers: header_data,
    })
    .then((res) => {
      // handle success

      let results = res.data;
      let status = results.status;
      let message = results.message;
      let response = results.data;
      //console.log(results);
      return [status, response];
    })
    .catch((error) => {
      let data = error?.response?.data;
      let status = data?.status;
      let errors = data.errors[0].param + " " + data.errors[0].message;

      if (status === "failed") {
        console.log(errors);
      }
      if (error.code !== "ERR_BAD_REQUEST") {
        console.log(error.code);
      }
      // toast.error(errors);
      return [error, {}];
    });
};

export const Get = async (api: string, token?: any) => {
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    auth: token ? token : user_token,
  };
  return axios.get(api, {
    headers: header_data,
  });
  // .then((res) => {
  //   let results = res.data;
  //   let error = results.error;
  //   let message = results.message;
  //   let response = results.response;
  //   console.log("eeeee",results)
  //   if (error === true) {
  //     alert(message);
  //   }

  //   return [error, response];
  // })

  // .catch((error) => {

  //   alert(error);

  //   return [error, {}];
  // });
};

export const GetCheck = async (api: string, token: any) => {
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    auth: token,
  };
  return axios.get(api, {
    headers: header_data,
  });
  // .then((res) => {
  //   let results = res.data;
  //   let error = results.error;
  //   let message = results.message;
  //   let response = results.response;
  //   console.log("eeeee",results)
  //   if (error === true) {
  //     alert(message);
  //   }

  //   return [error, response];
  // })

  // .catch((error) => {

  //   alert(error);

  //   return [error, {}];
  // });
};





export const Delete = async (api: string) => {
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    auth: user_token,
  };
  return axios.delete(api, {
    headers: header_data,
  });
};

export const Delete_Multiple = async (api: string, params: {}) => {
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    auth: user_token,
  };
  return axios.delete(api, {
    data: params,
    headers: header_data,
  });
};

export const Put = async (api: string, params: any) => {
  let user_token = sessionStorage.getItem("usertoken");
  var header_data = {
    "Content-Type": "application/json",
    Accept: "application/json",
    auth: user_token,
  };
  return axios.put(api, params, {
    headers: header_data,
  });
};
