import { Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import React from "react";
import { GROUP_TERM_SERVICES } from "../../../../../Services/ProductConfigurator/GroupTermServices";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";
interface Props {
  attrName: any;
  open_status: boolean;
  value_update: Function;
  ids?: any;
  get_api_url?: string | undefined;
  refetchData?:Function;
}

const RemoveCategoryPopup: React.FC<Props> = ({
  attrName,
  open_status,
  value_update,
  ids,
  get_api_url,
  refetchData
}) => {
  const [deleteStatusStep, setDeleteStatusStep] = React.useState(1);

  // delete multiple catageory
  const delete_catageory = () => {
    // success delete
    let data_delete = { ids };
    const onSuccess = (res: any) => {
    refetchData && refetchData()

      // value_update(attrName, false);
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    GROUP_SUPER_TOPUP.deleteCatageory(
      get_api_url === undefined ? "" : get_api_url,
      data_delete,
      onSuccess,
      {},
      onError
    );
    setDeleteStatusStep(2);
  };
  const deleteditem = () => {
    value_update(attrName, false);
    setDeleteStatusStep(1);
  };
  return (
    <Modal open={open_status} className="modalWrapper">
      <>
        {deleteStatusStep === 1 ? (
          <Box className="modalInner deletepopup">
            <Grid container spacing={1}>
              <Grid xs={12} sx={{ width: "100%" }}>
              
                <Link
                  className="close-button"
                  onClick={() => {
                  
                    value_update(attrName, false);
                  }}
                />
                <div className="img-prt">
                  <img
                    src="/images/deletepopup_deleteicon.svg"
                    alt=""
                    className="img"
                  />
                </div>
                {ids?.length === 0 ? (
                  ""
                ) : (
                  <h5 className="popup-heading">
                    Are you sure, you want
                    <br /> to remove{" "}
                    {ids?.length > 1 ? "these categories?" : "this category?"}
                  </h5>
                )}
              </Grid>
              <Grid xs={12} sx={{ width: "100%" }}>
                <Link className="md-link mb-10" underline="none">
                  {ids?.length === 0
                    ? "Please select the required category to remove from the existing list."
                    : `You have selected ${ids?.length}`}
                </Link>
              </Grid>
              {ids?.length === 0 ? (
                ""
              ) : (
                <Grid
                  xs={12}
                  className="ctaBtn"
                  sx={{ width: "100%" }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={delete_catageory}
                  >
                    Yes, Remove
                  </Button>
                  <Button variant="contained" className="cancelBtn" onClick={()=>  value_update(attrName, false)}>
                    No, Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        ) : deleteStatusStep === 2 ? (
          <Box className="modalInner deletepopup">
            <Grid container spacing={1}>
              <Grid xs={12} sx={{ width: "100%" }}>
                <Link
                  className="close-button"
                  onClick={() => {
                    value_update(attrName, false);
                  }}
                />
                <div className="img-prt">
                  <img
                    src="/images/deletePopup_tickicon.svg"
                    alt=""
                    className="img"
                  />
                </div>
                <h5 className="popup-heading">
                  The selected category has been removed Successfully!
                </h5>
              </Grid>
              <Grid
                xs={12}
                sx={{ width: "100%" }}
                className="ctaBtn"
                style={{ textAlign: "center" }}
              >
                <Button
                  onClick={deleteditem}
                  variant="contained"
                  className="submitBtn"
                >
                  Okay
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </>
    </Modal>
  );
};

export default RemoveCategoryPopup;
