//used
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";
import { USER_TYPE } from "../../../Services/Enum/UserType";
import { POLICIES_SERVICES } from "../../../Services/Policies/PoliciesServices";
import { RootState } from "../../../Store/Store";
import { useAppSelector } from "../../../Store/hooks";
import { useFormatCurrency } from "../../../Supporting files/CustomHooks";
import { date_time_format } from "../../../Supporting files/HelpingFunction";
import AddCdBalance from "../AddCdBalance/AddCdBalance";
import "../CDLedger/CDLedger.scss";
import { toast } from "react-toastify";
interface Props {
  intialCdBalance?: string;
}

const CDLedger: React.FC<Props> = ({ intialCdBalance }) => {
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const policy_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Policies
  );
  const [addCdBalance, setAddCdBalance] = useState(false);
  const [cdLedgerdata, setCdLedgerdata] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("CdLedger");
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const { formatCurrency } = useFormatCurrency();
  const userType = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [downloadCsvPermission, setDownloadCsvPermission] = useState(false);
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const [editPolicyPermission, setEditPolicyPermission] = useState<any>(null);
  const [csvPolicyPermission, setCsvPolicyPermission] = useState<any>(null);

  // for scrolling
  const CdLedger = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (userType === "BROKER") {
      setDownloadCsvPermission(
        policy_permission?.csv === undefined ? false : policy_permission?.csv
      );
    } else {
      setDownloadCsvPermission(
        EMPLOYER?.Policies?.csv === undefined ? false : EMPLOYER?.Policies?.csv
      );
    }
    setEditPolicyPermission(
      policy_permission?.edit === undefined ? false : policy_permission?.edit
    );
    setCsvPolicyPermission(
      policy_permission?.csv === undefined ? false : policy_permission?.csv
    );
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setAddCdBalance") {
      setAddCdBalance(value);
    }
  };

  const get_cd_ledger = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {}, 1500);
      }
      setCdLedgerdata(res.data.data.cdLedger);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    userType === "BROKER"
      ? POLICIES_SERVICES.get_cd_ledger(
          `${URL_CONSTANTS.get_cd_ledger}${id}`,
          onSuccess,
          onError
        )
      : POLICIES_SERVICES.get_cd_ledger(
          `${EMPLOYER_URL_CONSTANTS.get_cd_ledger}${id}`,
          onSuccess,
          onError
        );
  };

  useEffect(() => {
    get_cd_ledger();
  }, [addCdBalance]);

  const downloadCsv = () => {
    const onSuccess = (res: any) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "cd-ledger.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    const updatedArr = cdLedgerdata?.map((item: any) => {
      return {
        "Transaction ID": item?.transactionId || "--",
        "Member ID": item?.memberId || "--",
        Date: item?.date
          ? date_time_format(item?.date, dateFormat, timeZone)
          : "--",
        Description: item.riderName
          ? item?.description + "/" + item.riderName
          : item?.description || "--",
        "Opening Balance": item?.openBalance
          ? formatCurrency(item?.openBalance)?.replace(/₹/g, "Rs")
          : formatCurrency(0)?.replace(/₹/g, "Rs"),
        Debit: item?.debit
          ? formatCurrency(item?.debit)?.replace(/₹/g, "Rs")
          : formatCurrency(0)?.replace(/₹/g, "Rs"),
        Credit: item?.credit
          ? formatCurrency(item?.credit)?.replace(/₹/g, "Rs")
          : formatCurrency(0)?.replace(/₹/g, "Rs"),
        "CD Bal/Closing Balance": item?.balance
          ? formatCurrency(item?.balance)?.replace(/₹/g, "Rs")
          : formatCurrency(0)?.replace(/₹/g, "Rs"),
        "Payment Mode": item?.paymentMode || "--",
      };
    });
    const col = [
      "Transaction ID",
      "Member ID",
      "Date",
      "Description",
      "Opening Balance",
      "Debit",
      "Credit",
      "CD Bal/Closing Balance",
      "Payment Mode",
    ];
    let columns = col?.map((item: any) => {
      return {
        check_status: true,
        field: item,
        headerName: item,
        width: 150,
      };
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType.toLowerCase(),
      "cdledger"
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid
        xs
        style={{
          maxWidth: "260px",
          position: "relative",
          top: "-75px",
          paddingLeft: "0",
        }}
      >
        <div
          className="leftSection h-100"
          style={{ position: "fixed", paddingLeft: "8px" }}
        >
          <Link
            className={
              sectionStatus === "CdLedger"
                ? "active sectionLink CdLedger"
                : "sectionLink CdLedger"
            }
            onClick={() => {
              scrollToSection(CdLedger);
              SetSectionStatus("CdLedger");
            }}
          >
            <span> Cd Ledger</span>
          </Link>
        </div>
      </Grid>
      <Grid xs>
        <div>
          <Grid container spacing={3} style={{ textAlign: "right" }}>
            <Grid xs={12}>
              {userType === USER_TYPE.BROKER && (
                <Link
                  underline="none"
                  className="blueBtn addfile"
                  onClick={() => {
                    if (!editPolicyPermission)
                      return toast.error("Edit permission not allowed");
                    setAddCdBalance(true);
                  }}
                >
                  Add CD Balance
                </Link>
              )}
              {cdLedgerdata?.length > 0 && downloadCsvPermission && (
                <Link
                  underline="none"
                  className="blueBtn download"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!csvPolicyPermission)
                      return toast.error("Edit permission not allowed");
                    downloadCsv();
                  }}
                >
                  Download CSV
                </Link>
              )}
            </Grid>
          </Grid>
          <Box className="detailSection" ref={CdLedger}>
            <div className="sectionTitle">
              <h4>CD Ledger </h4>
            </div>

            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="cdledger">
                  <div className="cdledger-table">
                    <ul className="heading">
                      <li>
                        Member ID
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Date
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Description
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Opening Balance
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Debit
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Credit
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        CD Bal/Closing Balance
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Endorsement ID
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Remarks
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Transaction ID
                        <button className="sorting-btn"></button>
                      </li>
                      <li>
                        Payment Mode
                        <button className="sorting-btn"></button>
                      </li>
                    </ul>

                    {cdLedgerdata?.map((data: any, index: any) => (
                      <ul className="subheading">
                        <li>{data?.memberId}</li>
                        <li>
                          {date_time_format(data?.date, dateFormat, timeZone)}{" "}
                        </li>
                        <li>
                          {data?.riderName
                            ? data?.description + "/" + data.riderName
                            : data?.description || "--"}
                        </li>
                        <li>{formatCurrency(data?.openBalance)}</li>
                        <li>{formatCurrency(data?.debit)}</li>
                        <li>{formatCurrency(data?.credit)}</li>
                        <li>{formatCurrency(data?.balance)}</li>
                        <li>{data?.endorsementId === "ED-undefined" ? "--" :data?.endorsementId  ?? "--"}</li>
                        <li>{data?.remark}</li>
                        <li>{data.transactionId}</li>
                        <li>{data?.paymentMode}</li>
                      </ul>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <AddCdBalance
            attrName={setAddCdBalance}
            open_status={addCdBalance}
            value_update={updateMasterState}
            currentCdBal={
              cdLedgerdata[cdLedgerdata.length - 1]?.balance
                ? cdLedgerdata[cdLedgerdata.length - 1]?.balance
                : intialCdBalance
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default CDLedger;
