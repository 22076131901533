export enum E_BE_FILTER_CODE {
  CONTACT = 31,
  EMPLOYEE = 1,
  CLIENT = 2,
  CLAIMS = 3,
  POLICIES = 4,
  QUOTES = 5,
  RENEWALS = 6,
  ENDORSEMENT = 7,
  GROUP_HEALTH_CATEGORIES = 8,
  GROUP_HEALTH_FEATURES = 9,
  GROUP_HEALTH_EXCLUSIONS = 10,
  GROUP_TERM_CATEGORIES = 11,
  GROUP_TERM_FEATURES = 12,
  GROUP_TERM_EXCLUSIONS = 13,
  GROUP_PERSONAL_ACCIDENT_CATEGORIES = 14,
  GROUP_PERSONAL_ACCIDENT_FEATURES = 15,
  GROUP_PERSONAL_ACCIDENT_EXCLUSIONS = 16,
  GROUP_CRITICAL_ILLNESS_CATEGORIES = 17,
  GROUP_CRITICAL_ILLNESS_FEATURES = 18,
  GROUP_CRITICAL_ILLNESS_EXCLUSIONS = 19,
  GROUP_TRAVEL_INSURANCE_CATEGORIES = 20,
  GROUP_TRAVEL_INSURANCE_FEATURES = 21,
  GROUP_TRAVEL_INSURANCE_EXCLUSIONS = 22,
  GENERAL_INSURANCE = 23,
  LIFE_INSURANCE = 24,
  SETTINGS = 25,
  USERS = 26,
  ACCESS_CONTROL = 27,
  DEPARTMENT = 28,
  BRANCHES = 29,
  CURRENCIES = 30,
  CLIENTDETAILCLAIM = 31,
  CLIENTDETAILENDORSEMENT = 32,
  CLIENTDETAILMEMBER = 33,
  POLICIESDETAILSMEMBER = 34,
  EMPOYEEDETAILSENDORSEMENT =35,
  EMPOYEEDETAILSCLAIMS =36,
  CLIENTDETAILCONTACTS= 31
}
