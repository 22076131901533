import {
  Box,
  Button
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { AddKycDTO } from "../../../../../../Services/DTO/ProfileDTO";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../../Services/Employee/EmployeePolicyService";
import { PROFILE_SERVICE } from "../../../../../../Services/Employee/ProfileService";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import { calculateAgeFromDob } from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";


// this is used in broker - profile- family details
// this is used in Employer - profile- family details


interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  callApiFromParent?: Function;
  apiUrl: string;
  policyIdEmployee?: string;
  filterDropDown?: boolean;
  addedMemberData?: any[];
}

const AddFamily: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  callApiFromParent,
  apiUrl,
  policyIdEmployee,
  filterDropDown,
  addedMemberData,
}) => {
  // const [disableSave, setDisableSave] = useState<boolean>(false);
  const usertypeinfo: any = useAppSelector((state: RootState) => state.userDetailsSlice.userType);
  const [selectFamily_data, setSelectFamily_data] = useState([]);
  const [family_detail, setFamily_detail] = useState<AddKycDTO>({
    fullName: "",
    relationShip: "",
    memId: "",
    dob: null,
    warnFullName: false,
    warnRelationShip: false,
    warnDob: false,
  });
  const [toggle, setToggle] = useState(false);
  const [selectedMemberId, setselectedMemberId] = useState('');
  const [formValue, setFormValue] = useState({
    fullName: { value: "", warning: false },
    relationEmployee: { value: "", warning: false },
    date: { value: null, warning: false },
    familyMember: { value: "", warning: false },
  });
  const [allRelations, setAllRelations] = useState<any[]>([
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Father-In-Law", value: "Father-In-Law" },
    { key: "Mother-In-Law", value: "Mother-In-Law" },
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
  ]);


  useEffect(() => {
    get_family_list()
    if (allRelations && addedMemberData) {
      const filteredData = allRelations.filter((listData) => {
        // if (listData.key?.toLowerCase() === "son" || listData.key?.toLowerCase() === "daughter") {
        //   return true;
        // }
        if (["Son", "Daughter", "Brother", "Sister"].includes(listData?.key)) {
          return true;
        }
        const hasWife = addedMemberData?.some((mem: any) => (mem.relationWithEmployee?.toLowerCase() ||
          mem.relationShip?.toLowerCase() || mem.relation?.toLowerCase()) === "wife");
        const hasHusband = addedMemberData?.some((mem: any) => (mem.relationWithEmployee?.toLowerCase() ||
          mem.relationShip?.toLowerCase() || mem.relation?.toLowerCase()) === "husband");
        if (hasWife && listData.key?.toLowerCase() === "husband") return false;
        if (hasHusband && listData.key?.toLowerCase() === "wife") return false;

        return (
          Array.isArray(addedMemberData) &&
          !addedMemberData.some(
            (memberData) =>
              memberData.relationWithEmployee?.toLowerCase() === listData.key?.toLowerCase() ||
              memberData.relationShip?.toLowerCase() === listData.key?.toLowerCase() ||
              memberData.relation?.toLowerCase() === listData.key?.toLowerCase()
          )
        )

      });
      setAllRelations(filteredData);
    }
  }, [open_status]);

  const get_family_list = () => {
    const onSucces = (res: any) => {
      let data = res.data.data[0].family?.map((element: any) => ({
        key: element._id,
        value: element.fullName,
        relationWithEmployee: element.relationWithEmployee,
        dob: element.dob,
        id: element._id
      }));
      setSelectFamily_data(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_POLICY_SERVICES.get_member_list(onSucces, onError, usertypeinfo.toLowerCase());
  };

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    if (mainState === "family_detail") {
      setFamily_detail({
        ...family_detail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else if (attrName[1] === "familyMember") {
      selectFamily_data.forEach((el: any) => {
        if (el.key === value) {
          setselectedMemberId(value)
          setFamily_detail((prev) => ({
            ...prev,
            relationShip: el.relationWithEmployee,
            fullName: el.value,
            memId: el.id,
            dob: el.dob,
            warnFullName: false,
            warnRelationShip: false,
            warnDob: false,
          }));
        }
      });
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
    // if (disableSave) return;
    let data: any = family_detail;
    data.warnFullName = family_detail.fullName.length === 0 ? true : false;
    data.warnRelationShip = family_detail.relationShip.length === 0 ? true : false;
    data.warnDob = family_detail.dob === null ? true : false;

    setFamily_detail({ ...data });
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    if (
      data.warnFullName === false &&
      data.warnRelationShip === false &&
      data.warnDob === false
    ) {
      let param = {
        employeeId: policyIdEmployee ?? id,
        member: {
          fullName: family_detail.fullName,
          relationWithEmployee: family_detail.relationShip,
          dob: family_detail.dob,
        },
      };
      const onSuccess = (res: any) => {
        value_update(attrName, false);
        setFamily_detail({
          fullName: "",
          relationShip: "",
          dob: null,
          warnFullName: false,
          warnRelationShip: false,
          warnDob: false,
        });
        toast.success(res.data.message);
        // setDisableSave(false)
      };
      const onError = (err: any) => {
        // setDisableSave(false);
        toast.error(err.response.data.errors[0].message);
      };
      if (checkRelationExist(family_detail.relationShip)) {
        // setDisableSave(true);
        PROFILE_SERVICE.create_family_detail(
          param,
          onSuccess,
          onError,
          usertypeinfo.toLocaleLowerCase()
        );
      }

    }
  };

  const checkRelationExist = (relation: string) => {
    if (relation?.toLowerCase() === "wife" || relation?.toLowerCase() === "husband") {
      const isExist = addedMemberData?.some(
        (member) => member?.relationWithEmployee?.toLowerCase() === "wife" || member?.relationWithEmployee?.toLowerCase() === "husband"
      );
      if (isExist) {
        toast.warning("Spouse already exists");
      }
      return !isExist;
    } else {
      return true;
    }
  };

  return (
    <Box>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Add Family Member</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setFamily_detail({
                    fullName: "",
                    relationShip: "",
                    dob: null,
                    warnFullName: false,
                    warnRelationShip: false,
                    warnDob: false,
                  });
                  // setDisableSave(false);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12}>
              <div className="scrollable_area">
                {toggle === true ? (
                  <>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Family Member"
                        value={selectedMemberId}
                        attrName={["formValue", "familyMember"]}
                        value_update={updateMasterState}
                        dropdown_data={selectFamily_data}
                        warn_status={family_detail.warnRelationShip}
                        error_messg="Select Family Member"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Relationship with Employee
                        <span>{formValue.relationEmployee.value}</span>
                      </p>
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={3}>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Full Name"}
                        value={family_detail.fullName}
                        attrName={["family_detail", "fullName"]}
                        value_update={updateMasterState}
                        warn_status={family_detail.warnFullName}
                        error_messg="Enter Full Name"
                        validation_type="name"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Relationship with employee"
                        value={family_detail.relationShip}
                        attrName={["family_detail", "relationShip"]}
                        value_update={updateMasterState}
                        dropdown_data={allRelations}
                        warn_status={family_detail.warnRelationShip}
                        error_messg="Select Relationship with employee"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField"
                        title="DOB"
                        value={family_detail.dob}
                        attrName={["family_detail", "dob"]}
                        max_date={new Date()}
                        value_update={updateMasterState}
                        error_message="Enter DOB"
                        warn_status={
                          family_detail.warnDob === undefined
                            ? false
                            : family_detail.warnDob
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Age
                        <span>{calculateAgeFromDob(family_detail.dob)}</span>
                      </p>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="footer_sec">
                <Button
                  // disabled={disableSave}
                  variant="contained"
                  className="save_btn"
                  onClick={() => {
                    if (callApiFromParent) {
                      let data: any = family_detail;
                      data.warnFullName =
                        family_detail.fullName.length === 0 ? true : false;
                      data.warnRelationShip =
                        family_detail.relationShip.length === 0 ? true : false;
                      data.warnDob = family_detail.dob === null ? true : false;
                      setFamily_detail({ ...data });
                      if (
                        data.warnFullName === false &&
                        data.warnRelationShip === false &&
                        data.warnDob === false
                      ) {
                        callApiFromParent(data);
                        setFamily_detail({
                          fullName: "",
                          relationShip: "",
                          dob: null,
                          warnFullName: false,
                          warnRelationShip: false,
                          warnDob: false,
                        });
                      }
                    } else {
                      form_submit();
                    }
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </Box>
  );
};

export default AddFamily;
