import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  format,
  addYears,
  addMonths,
} from "date-fns";

import moment from "moment";
import { URL_CONSTANTS } from "../APIVar/URLConstants";
import { GetAPI } from "../Services/API";
import { Get } from "./CallAPI";
import { TPA_TYPE } from "../Services/Enum/tpaType";
import staticNetworklist from "./StaticNetworkHospitals.json";
import CryptoJS from "crypto-js";
import { GLOBAL_SERVICES } from "../Services/global/GlobalServices";
import { toast } from "react-toastify";
import { useEffect, useRef } from "react";
const crypto_key = process.env.REACT_APP_CRYPTO_KEY || "3D5E8F3C2F4A9B6D7E1C4F2A9B6D7E1C"

export const proRetaRateCalcFLat = (liveInfoFlat: any, period: any) => {
  if (period === "YEARLY") {
    liveInfoFlat.map((liveInfo: any) => {
      liveInfo.coverageTypeData.map((data: any) => {
        if (data.name === "amount") {
          const value = +data.value;
          const perDayRate = value / 365;
          data.value = perDayRate;
        }
      });
    });
  } else if (period === "MONTHLY") {
    liveInfoFlat.map((liveInfo: any) => {
      liveInfo.coverageTypeData.map((data: any) => {
        if (data.name === "amount") {
          const value = +data.value;
          const perDayRate = value / 30;
          data.value = perDayRate;
        }
      });
    });
  } else if (period === "WEEKLY") {
    liveInfoFlat.map((liveInfo: any) => {
      liveInfo.coverageTypeData.map((data: any) => {
        if (data.name === "amount") {
          const value = +data.value;
          const perDayRate = value / 7;
          data.value = perDayRate;
        }
      });
    });
  }

  return liveInfoFlat;
};
interface AgeBand {
  min: string;
  max: string;
  name: string;
}
// Function to convert string ages to numbers
export function parseAge(age: string): number {
  if (age.includes("Up to")) {
    return 0;
  }
  return parseInt(age);
}

export const checkPasswordStrength = (
  password: string,
  confirmPassword: string,
  username: string,
  mobileNumber: string,
  oldPassword: string,
  oldPasswordRequired: boolean
) => {
  const minLength = 8;
  const hasNumber = /\d/;
  const hasUppercase = /[A-Z]/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  const forbiddenSequence = /12345678/;

  return {
    lengthValid: {
      isValid: (password?.length || 0) >= minLength,
      message: "Password must be at least 8 characters long",
    },
    hasNumber: {
      isValid: hasNumber.test(password || ""),
      message: "Password must contain at least one number",
    },
    hasUppercase: {
      isValid: hasUppercase.test(password || ""),
      message: "Password must contain at least one uppercase letter",
    },
    hasSpecialChar: {
      isValid: hasSpecialChar.test(password || ""),
      message: "Password must contain at least one special character",
    },
    noForbiddenSequence: {
      isValid: password ? !forbiddenSequence.test(password) : false,
      message: 'Password should not contain the sequence "12345678"',
    },
    noUsername: {
      isValid: !(
        username && password.toLowerCase().includes(username.toLowerCase())
      ),
      message: "Password should not contain your username",
    },
    noMobileNumber: {
      isValid: password
        ? !(mobileNumber && password.includes(mobileNumber))
        : false,
      message: "Password should not contain your mobile number",
    },
    passwordsMatch: {
      isValid: password ? password === confirmPassword : false,
      message: "Passwords do not match",
    },
    noOldPasswordMatch: {
      isValid: oldPasswordRequired ? password ? !(oldPassword && password === oldPassword) : false : true,
      message: 'New password cannot be the same as the old password',
    },
  };
};

export function areAgeBandsConsistent(bands: AgeBand[]): boolean {
  if (bands.length <= 1) {
    return true;
  }

  for (let i = 0; i < bands.length - 1; i++) {
    const currentMax = parseAge(bands[i].max);
    const nextMin = parseAge(bands[i + 1].min);

    // Check if there's a gap between the max of current and min of next
    if (nextMin !== currentMax + 1) {
      return false;
    }
  }
  return true;
}
export const proretaRateCalcGrade = (livesInfoGrade: any, period: any) => {
  let days = 1;
  if (period === "YEARLY") {
    days = 365;
  } else if (period === "MONTHLY") {
    days = 30;
  } else if (period === "WEEKLY") {
    days = 7;
  }
  livesInfoGrade.map((grade: any) => {
    grade.data.map((gradeData: any) => {
      gradeData.coverageTypeData.map((data: any) => {
        if (data.name === "amount") {
          const value = +data.value;
          const perDayRate = value / days;
          data.value = perDayRate;
        }
      });
    });
  });
  return livesInfoGrade;
};

export const finalProrateCalcFlat = (livesInfoFlat: any, period: any) => {
  let days = 1;
  if (period === "YEARLY") {
    days = 365;
  } else if (period === "MONTHLY") {
    days = 30;
  } else if (period === "WEEKLY") {
    days = 7;
  }

  livesInfoFlat.map((liveInfo: any) => {
    liveInfo.coverageTypeData.map((data: any) => {
      if (data.name === "amount") {
        const value = +data.value;
        const finalRate = value * days;
        data.value = finalRate;
      }
    });
  });
  return livesInfoFlat;
};

export function capitalize(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export function calculateTotalTax(netPremium: number, taxes: any[]): number {
  let totalStandardTax: number = 0;
  let surchargeTaxes: any[] = [];

  taxes.forEach((tax: any) => {
    if (!tax.key) {
      tax.key = tax.label || tax.value;
    }
    if (tax.type === "Standard Tax" && tax.key) {
      const taxValue: number = parseFloat(tax.key.split("-").pop().trim());
      const taxAmount: number = (taxValue / 100) * netPremium;
      totalStandardTax += taxAmount;
    } else if (tax.type === "Surcharge") {
      surchargeTaxes.push(tax);
    }
  });

  let totalSurcharge: number = 0;

  surchargeTaxes.forEach((surcharge: any) => {
    const surchargeValue: number = parseFloat(
      surcharge.key.split("-").pop().trim()
    );
    totalSurcharge += (surchargeValue / 100) * totalStandardTax;
  });

  // Calculate total tax amount
  const totalTax: number = totalStandardTax + totalSurcharge;
  return totalTax;
}

export const finalProrateCalcGrade = (livesInfoGrade: any, period: any) => {
  let days = 1;
  if (period === "YEARLY") {
    days = 365;
  } else if (period === "MONTHLY") {
    days = 30;
  } else if (period === "WEEKLY") {
    days = 7;
  }

  livesInfoGrade.map((grade: any) => {
    grade.data.map((gradeData: any) => {
      gradeData.coverageTypeData.map((data: any) => {
        if (data.name === "amount") {
          const value = +data.value;
          const perDayRate = value * days;
          data.value = perDayRate;
        }
      });
    });
  });
  return livesInfoGrade;
};

export function validateEmail(email: string) {
  var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return re.test(email);
}

export function titleCase(str: string) {
  var splitStr = str?.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function validatePincode(pincode: string) {
  if (pincode !== undefined && pincode !== null) {
    if (pincode.toString() === "111111" || pincode.toString() === "000000") {
      return false;
    } else if (pincode != undefined) {
      if (pincode.toString().length === 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function calculateDaysDifference(startDate: any, endDate: any) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const timeDiff = end.getTime() - start.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff.toString();
}

export function validateRegno(reg_no: string) {
  var re = /^[A-Z]{2,2}[0-9]{2}[A-Z]{0,3}[0-9]{4,4}$/;
  var re_dl = /^[A-Z]{2,2}[0-9]{1,2}[A-Z]{0,3}[0-9]{4,4}$/;
  if (reg_no.substring(0, 2) === "DL") {
    return re_dl.test(reg_no);
  }
  return re.test(reg_no);
}

export function hexToRgb(hex: string): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = result ? parseInt(result[1], 16) : 0;
  const g = result ? parseInt(result[2], 16) : 0;
  const b = result ? parseInt(result[3], 16) : 0;
  return `{${r}, ${g}, ${b}}`;
}

export function validateFullName(full_name: string) {
  if (full_name != undefined) {
    var name = full_name.toString().trim();
    if (name.includes(" ")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateMobileNumber(mobile_no: string) {
  if (mobile_no != undefined && mobile_no != null) {
    var mobile = mobile_no.toString().trim();
    if (mobile.toString().length >= 7 && mobile.toString().length < 16) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const formatAmountWithComma = (value: any) => {
  const nf = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return nf.format(value);
};

// currrently this function is commented because we need space between currency and value

// export const formatCurrencyAccToUser = (
//   value: number,
//   currencyCode: string,
//   countryCode: string,
//   decimalPlaces: number
// ) => {
//   // Set a default language tag if countryCode is empty or undefined
//   const languageTag = countryCode ? `en-${countryCode}` : 'en';

//   const amount = new Intl.NumberFormat(languageTag, {
//     style: "currency",
//     currency: currencyCode,
//     maximumFractionDigits: decimalPlaces,
//   })?.format(value);
//   return amount?.toString();
// };

export const formatCurrencyAccToUser = (
  value: number,
  currencyCode: string,
  countryCode: string,
  decimalPlaces: number
) => {
  // Set a default language tag if countryCode is empty or undefined
  const languageTag = countryCode ? `en-${countryCode}` : "en";

  const formatter = new Intl.NumberFormat(languageTag, {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: decimalPlaces,
  });

  // Format the amount using the formatter
  const amount = formatter.format(value);

  // Extract the currency symbol
  const currencySymbol = formatter.formatToParts()[0].value;

  // Manually add a space between the currency symbol and the value
  const formattedAmount = amount.replace(currencySymbol, currencySymbol + " ");

  return formattedAmount;
};

export function add_day_in_date(date: string | Date | null, days: string) {
  let new_date = moment(date, "DD-MM-YYYY")
    .add(days, "day")
    .format("DD-MM-YYYY");
  return new_date;
}

export function add_days_in_date_getDateObject(
  date: string | Date | null,
  days: string
) {
  let new_date = moment(date, "DD-MM-YYYY").add(days, "day");
  return new_date;
}

export function isLeapYear(startDate: any, endDate: any) {
  const sDate = new Date(startDate) as any;
  const eDate = new Date(endDate) as any;
  if (sDate.getDate() === eDate.getDate() + 1) {
    return true;
  }
  return false;
}
export function containsLeapYearFebruary(
  sDate: Date,
  daysToAdd: number = 365
): boolean {
  const eDate = new Date(sDate);
  eDate.setDate(eDate.getDate() + daysToAdd);

  const startYear = sDate.getFullYear();
  const endYear = eDate.getFullYear();

  for (let year = startYear; year <= endYear; year++) {
    if (isLeapYears(year)) {
      const feb29 = new Date(year, 1, 29); // February 29th of the leap year
      if (feb29 > sDate && feb29 <= eDate) {
        // Ensure that February 29 is within the range
        return true;
      }
    }
  }
  return false;
}

export function isLeapYears(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function subtract_days_in_date_getDateObject(
  date: string,
  days: string
) {
  let new_date = moment(date, "DD-MM-YYYY").subtract(days, "day");
  return new_date;
}

export function add_months_in_date(date: string, months: string) {
  let new_date = moment(date, "DD-MM-YYYY")
    .add(months, "month")
    .format("DD-MM-YYYY");
  return new_date;
}

export function add_years_in_date(date: string, years: string) {
  let new_date = moment(date, "DD-MM-YYYY")
    .add(years, "year")
    .format("DD-MM-YYYY");
  return new_date;
}
//
export function add_date_format(value: any) {
  let new_date = moment(value).format("DD-MM-YYYY");
  return new_date;
}
//
export function isDateString(str: string) {
  const date = new Date(str);
  return !isNaN(date.getTime());
}

// extarct numbers from string
export function extractNumbersFromString(str: string) {
  return str?.replace(/[^0-9]/g, "");
}

export function extractAlphabetsFromString(str: string) {
  return str.replace(/[^a-z]/gi, "");
}

export function countOccurrencesInCoverageConfigString(
  mainStr: string,
  subStr: string
): number {
  // Split the string by both space and '+'
  let words = mainStr.split(/[\s+]+|\+/);
  let count = 0;

  for (let i = 0; i < words.length; i++) {
    if (extractAlphabetsFromString(words[i]) === subStr) {
      count++;
    }
  }

  return count;
}

// created time format && updated time format

// export function date_time_format(value: string | Date, format?: string) {
//   let new_date = moment(value).utcOffset(330).format(`${format} hh:mm A`);
//   return new_date;
// }

// export function date_time_format(
//   value: string | Date,
//   format: string = "DD-MM-YYY hh:mm A",
//   timeZone: string = "Asia/Kolkata"
// ) {
//   let tz = timeZone.split(" ")[1];
//   // let new_date = moment(value).tz(tz ? tz : timeZone)?.format(format ? `${format}` : "DD-MM-YYY hh:mm A");
//   let new_date = moment(value).subtract(1).tz(tz ? tz : timeZone)?.format(`${format}` ?? "DD-MM-YYY hh:mm A");

//   return new_date;
// }

// export function date_time_format(
//   value: string | Date,
//   format: string = "DD-MM-YYYY hh:mm A",
//   timeZone: string = "Asia/Kolkata"
// ) {
//   // Format the date according to the specified time zone
//   let new_date = moment(value).tz(timeZone).format(format);

//   return new_date;
// }

export function date_time_format(
  value: string | Date,
  format: string = "DD-MM-YYYY hh:mm A",
  timeZone: string = "Asia/Kolkata"
) {
  let new_date;

  // Check if the value is a string in DD-MM-YYYY format
  if (typeof value === "string" && /^\d{2}-\d{2}-\d{4}$/.test(value)) {
    new_date = moment(value, "DD-MM-YYYY").tz(timeZone).format(format);
  } else {
    new_date = moment(value).tz(timeZone).format(format);
  }

  return new_date;
}

// date format function as per the set on organisation page
export function updateTimeFormat(value: string, format?: string) {
  // Parse the input date
  let new_date = moment(value);

  // Adjust the time to the desired UTC offset (330 minutes for IST)
  new_date = new_date.utcOffset(330);

  // Format the date in the desired format
  if (format) {
    return new_date.format(format);
  } else {
    return new_date.toString(); // or any default format you prefer
  }
}

// validate url
export function validateUrl(value: string) {
  var re =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return re.test(value);
}

// industry city list
export const get_industry_list = (cb: any) => {
  let data: Array<{ label: string; id: string }> = [];
  Get(URL_CONSTANTS.get_industry_list)
    .then((res: any) => {
      let response = res.data.data;

      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].value,
          id: response[i].label.toString(),
        });
      }
      return cb(data);
    })
    .catch((error: any) => {
      console.log(error);
      return [error, {}];
    });
};

// time zone list
export const time_zone_list = (cb: any) => {
  let data: Array<{ label: string; id: string }> = [];
  Get(URL_CONSTANTS.time_zone_list)
    .then((res: any) => {
      let response = res.data.data;

      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].label,
          id: response[i].id.toString(),
        });
      }
      return cb(data);
    })
    .catch((error: any) => {
      console.log(error);
      return [error, {}];
    });
};

// currency  list
export const currency_list = (cb: any) => {
  let data: Array<{ label: string; id: string; iso2: string }> = [];
  Get(URL_CONSTANTS.currency_list)
    .then((res: any) => {
      let response = res.data.data;

      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].label,
          id: response[i].id.toString(),
          iso2: response[i].iso2,
        });
      }
      return cb(data);
    })
    .catch((error: any) => {
      console.log(error);
      return [error, {}];
    });
};

export const getCurrencyCodeRegex = (stringToMatch: string): string => {
  const pattern = /[A-Z]{3}/g;
  const matches = stringToMatch.match(pattern);
  //console.log("Matches... ", matches)
  if (matches) {
    return matches[0];
  } else {
    return "INR";
  }
};

// export const convertMultipleNumbersToCurrencyFormat = (
//   stringToCovert: string,
//   currencyCode: string,
//   countryCode: string
// ): string=>{
//   //for testing pupose
//   //const data = "1000000-2000000,2000000-3000000,3000000-4000000,4000000-5000000,5000000-600000";

//   // Function to format a number as currency
//   function formatCurrency(number:number) {
//     return new Intl.NumberFormat(`en-${countryCode}`, { style: 'currency', currency: currencyCode }).format(number);
//   }

//   // Split the data into individual values
//   const values = stringToCovert.split(', ');

//   // Convert each value to currency format
//   const formattedValues = values.map(value => {
//     const [start, end] = value.split('-');
//     const formattedStart = formatCurrency(parseInt(start));
//     const formattedEnd = formatCurrency(parseInt(end));
//     return `${formattedStart}-${formattedEnd}`;
//   });

//   // Join the formatted values back into a string
//   const formattedData = formattedValues.join(',');

//   return formattedData
// }

export const convertMultipleNumbersToCurrencyFormat = (
  stringToCovert: string,
  currencyCode: string,
  countryCode: string,
  multiple?: boolean,
  index?: number,
  lastIndex?: boolean
): string => {
  //for testing pupose
  //const data = "1000000-2000000,2000000-3000000,3000000-4000000,4000000-5000000,5000000-600000";
  // Function to format a number as currency
  function formatCurrency(number: number) {
    return new Intl.NumberFormat(`en-${countryCode}`, {
      style: "currency",
      currency: currencyCode,
    }).format(number);
  }

  // Split the data into individual values
  let values = [];
  if (multiple) {
    values = stringToCovert.split(", ");
  }
  values = [stringToCovert];
  // Convert each value to currency format
  const formattedValues = values.map((value) => {
    const [start, end] = value.split("-");
    const formattedStart = formatCurrency(parseInt(start));
    let formattedEnd;
    if (end.trim() !== "above") {
      formattedEnd = formatCurrency(parseInt(end));
    } else {
      formattedEnd = end;
    }
    return `${formattedStart}-${formattedEnd}`;
  });

  // this is for first and last value which contain below and above string
  if (index === 0 || lastIndex === true) {
    const [start, end] = values[0].split(" ");
    const formattedStart = start;
    const formattedEnd = formatCurrency(parseInt(end));

    return `${formattedStart}-${formattedEnd}`;
  }

  // Join the formatted values back into a string
  const formattedData = formattedValues.join(",");
  return formattedData;
};

export const convertSingleBracketToCurrencyFormat = (
  stringToCovert: string,
  currencyCode: string,
  countryCode: string,
  multiple?: boolean,
  index?: number,
  lastIndex?: boolean
): string => {
  //console.log("str.replace(/[^a-zA-Z0-9 ,\-]/g, '')", stringToCovert, "   --> ", stringToCovert.replace(/[^a-zA-Z0-9 ,\-]/g, '').replace(/,/g, ''), countryCode, currencyCode)
  //for testing pupose
  const data = stringToCovert
    .replace(/[^a-zA-Z0-9 ,\-]/g, "")
    .replace(/,/g, "");
  //Function to format a number as currency

  function formatCurrency(number: number) {
    return new Intl.NumberFormat(`en-${countryCode}`, {
      style: "currency",
      currency: currencyCode,
    }).format(number);
  }

  // // Split the data into individual values
  let values = [data];
  // if (multiple) {
  //   values = stringToCovert.split(", ");
  // }
  // values = [stringToCovert];
  // console.log(values)
  // Convert each value to currency format
  const formattedValues = values.map((value) => {
    const [start, end] = value.split("-");
    const formattedStart = formatCurrency(parseInt(start));
    let formattedEnd;
    if (end?.toLowerCase()?.trim() !== "above") {
      formattedEnd = formatCurrency(parseInt(end));
    } else {
      formattedEnd = end;
    }
    return `${formattedStart}-${formattedEnd}`;
  });

  // this is for first and last value which contain below and above string
  if (index === 0 || lastIndex === true) {
    const [start, end] = values[0].split(" ");
    const formattedStart = start;
    const formattedEnd = formatCurrency(parseInt(end));

    return `${formattedStart}-${formattedEnd}`.replace(/₹/g, "Rs");
  }

  // Join the formatted values back into a string
  const formattedData = formattedValues.join(",");
  return formattedValues[0];
};

export const get_subindustry_list = (value: any, cb: any) => {
  Get(`${URL_CONSTANTS.get_subindustry_list}${value?.id}`)
    .then((res: any) => {
      let response = res.data.data;
      let data: Array<{ label: string; id: string }> = [];
      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].value,
          id: response[i].label.toString(),
        });
      }
      return cb(data);
    })
    .catch((error) => {
      alert(error);
      return [error, {}];
    });
};
// country state city list
export const get_country_list = (cb: any) => {
  let data: Array<{ label: string; id: string }> = [];
  Get(URL_CONSTANTS.get_country_list)
    .then((res: any) => {
      let response = res.data.data;

      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].value,
          id: response[i].label.toString(),
        });
      }
      return cb(data);
    })
    .catch((error: any) => {
      console.log(error);
      return [error, {}];
    });
};
export const get_state_list = (value: any, cb: any) => {
  Get(`${URL_CONSTANTS.get_state_list}${value?.label || value}`)
    .then((res: any) => {
      let response = res.data.data;
      let data: Array<{ label: string; id: string }> = [];
      if (response.length === 0) {
        data.push({
          label: "N.A.",
          id: "N.A.",
        });
      } else {
        for (var i = 0; i < response.length; i++) {
          data.push({
            label: response[i].value,
            id: response[i].label.toString(),
          });
        }
      }

      return cb(data);
    })
    .catch((error) => {
      alert(error);
      return [error, {}];
    });
};
export const get_city_list_via_name = (country: any, state: any, cb: any) => {
  Get(
    `${URL_CONSTANTS.get_city_list}state=${state?.label || state}&country=${country?.label || country
    }`
  )
    .then((res) => {
      let response = res.data.data;
      let data: Array<{ label: string; id: string }> = [];
      if (response.length === 0) {
        data.push({
          label: "N.A.",
          id: "N.A.",
        });
      } else {
        for (var i = 0; i < response.length; i++) {
          data.push({
            label: response[i].value,
            id: response[i].label.toString(),
          });
        }
      }

      return cb(data);
    })
    .catch((error) => {
      alert(error);
      return [error, {}];
    });
};

export const get_city_list = (value: any, cb: any) => {
  Get(`${URL_CONSTANTS.get_city_list}${value?.label}`)
    .then((res) => {
      let response = res.data.data;
      let data: Array<{ label: string; id: string }> = [];
      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].value,
          id: response[i].label.toString(),
        });
      }
      return cb(data);
    })
    .catch((error) => {
      alert(error);
      return [error, {}];
    });
};

export const get_pincode = (value: string, country_code: string) => {
  Get(`${URL_CONSTANTS.get_city_list}`)
    .then((res) => {
      let response = res.data.data;
      let data: Array<{ label: string; id: string }> = [];
      for (var i = 0; i < response.length; i++) {
        data.push({
          label: response[i].value,
          id: response[i].label.toString(),
        });
      }
      // return cb(data);
    })
    .catch((error) => {
      alert(error);
      return [error, {}];
    });
};

// get client list
export const get_client_list = (
  user: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_client_list(user), onSuccess, onError);
};
export const get_employer_client_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_employer_client_list, onSuccess, onError);
};

// convert base64 function
export function uploadImage(file: any, callback: any) {
  const reader = new FileReader();
  if (reader) {
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      // var base64Image = base64String.replace(
      //   /^data:(image\/[a-z]+|application\/pdf);base64,/,
      //   ""
      // );
      var base64Image = base64String;
      callback(base64Image, base64String);
    };
  }
}

interface TStatusCode {
  key: string;
  value: string;
  color: string;
}

// get Color Value for Status
export function getColorForValue(
  value: string | null,
  statusCode: TStatusCode[]
): {
  background: string;
  border: string;
} {
  if (!value) {
    return { background: "transparent", border: "#000000" };
  }
  const color = statusCode.find((c) => c.key === value)?.color;
  if (!color) {
    return { background: "transparent", border: "#000000" };
  }
  const background = `${color}30`; // Return color with 50% opacity for background
  const border = `${color}ff`; // Return color with 100% opacity for border
  return { background, border };
}

export function isStringifiedObject(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (err) {
    return false;
  }
}

export function customFormatDate(value: string | Date) {
  return value ? format(new Date(value), "dd-MM-yyyy") : "";
}

export function customFormatTime(value: string | Date) {
  return value ? format(new Date(value), "p") : "";
}

// export function calculateAgeFromDob(value: string | Date | null) {
//   if (!value) {
//     return "";
//   }

//   const dayVal = differenceInCalendarDays(new Date(), new Date(value || ""));
//   const monthVal = differenceInCalendarMonths(
//     new Date(),
//     new Date(value || "")
//   );
//   const yearVal = differenceInCalendarYears(new Date(), new Date(value || ""));

//   if (dayVal <= 31) {
//     return dayVal.toString() + " days";
//   } else if (monthVal < 12) {
//     return monthVal.toString() + " months";
//   } else {
//     return yearVal.toString() + " years";
//   }
// }
export function calculateAgeFromDob(value: string | Date | null) {
  if (!value) {
    return "";
  }

  const birthDate = new Date(value);
  const currentDate = new Date();

  let years = differenceInCalendarYears(currentDate, birthDate);
  let months = differenceInCalendarMonths(
    currentDate,
    addYears(birthDate, years)
  );
  let days = differenceInCalendarDays(
    currentDate,
    addMonths(addYears(birthDate, years), months)
  );

  // Correct the calculation for days and months
  if (days < 0) {
    months--;
    const previousMonth = addMonths(addYears(birthDate, years), months);
    days = differenceInCalendarDays(currentDate, previousMonth);
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  let ageString = "";
  if (years > 0) ageString += `${years} years `;
  if (months > 0) ageString += `${months} months `;
  if (days > 0) ageString += `${days} days`;

  return ageString.trim();
}

export function generateModuleId(str: string, str1: string) {
  return (str + str1.slice(-5)).toUpperCase();
}

// export function parseConfigurations(basicConfig: string, parentConfig: string): string {
//   const counts: { [key: string]: number } = {};

//   // Splitting and processing basicConfig
//   const basicElements: string[] = basicConfig.split('+');
//   basicElements.forEach(element => {
//     const [count, type] = element.split('');
//     const typeName = getTypeName(type);
//     counts[typeName] = (counts[typeName] || 0) + parseInt(count, 10);
//   });

//   // Splitting and processing parentConfig
//   const parentElements: string[] = parentConfig.split('+');
//   parentElements.forEach(element => {
//     const [count, type] = element.split('');
//     const typeName = getTypeName(type);
//     counts[typeName] = (counts[typeName] || 0) + parseInt(count, 10);
//   });

//   // Generate output string
//   let output = '';
//   for (const type in counts) {
//     if (counts.hasOwnProperty(type)) {
//       output += `${counts[type]} ${type},`;
//     }
//   }

//   return output.trim();
// }
export function parseConfigurations(
  basicConfig: string,
  parentConfig: string
): string {
  const counts: { [key: string]: number } = {
    Employee: 0,
    Spouse: 0,
    Child: 0,
    Parent: 0,
    "Parent in law": 0,
  };

  // Process basicConfig
  basicConfig?.split("+").forEach((element) => {
    const [count, type] = element.split("");
    counts[getTypeName(type)] += parseInt(count, 10);
  });

  // Process parentConfig
  parentConfig?.split("+").forEach((element) => {
    const countStr = element.slice(0, -1);
    const typeStr = element.slice(-1);
    let type = getTypeName(typeStr);
    if (type === "" && typeStr === "PIL") {
      type = "Parent in law";
    }
    counts[type] += parseInt(countStr, 10);
  });

  // Generate output string
  let output = Object.entries(counts)
    .filter(([type, count]) => count > 0)
    .map(([type, count]) => {
      if (type === "Child" && count > 1) {
        return `${count} Children`;
      } else if (type === "Parent" && count > 1) {
        return `${count} Parents`;
      } else if (type === "Parent in law" && count > 1) {
        return `${count} Parents-In-Law`;
      } else if (type === "Parent in law" && count === 1) {
        return `${count} Parent-In-Law`;
      } else {
        return `${count} ${type}`;
      }
      // console.log("type", type)
      // if (type === "Child" && count > 1) {
      //   return `${count} Children`;
      // } else {
      //   return `${count} ${type}`;
      // }
    })
    .join(", ");

  // Adjust output for "Parent" and "Parent in law"
  if (counts["Parent"] > 0 && counts["Parent in law"] > 0) {
    output = output.replace("Parent,", "Parent &");
  }

  return output;
}

function getTypeName(type: string): string {
  switch (type) {
    case "E":
      return "Employee";
    case "S":
      return "Spouse";
    case "C":
      return "Child";
    case "L":
      return "Parent in law";
    case "P":
      return "Parent";
    default:
      return "";
  }
}

export const getPaytoEmployeeforFlat = (policyDetail: any, dob: any) => {
  //console.log(policyDetail.policyData.payType)

  const to_pay_for = {
    name: policyDetail.policyData.productType.name,
    amount: 0,
    cd_detuction: 0,
  };
  const raiders_payout_data: any = [];

  //return

  if (policyDetail.policyData.payType.type === "SPLIT") {
    //test
    policyDetail.policyData.livesInfoFlat.find((ab: any) => {
      const min = Number(extractNumbersFromString(ab.min)) as number;
      const max = Number(extractNumbersFromString(ab.max)) as number;

      const noOfYears = Math.ceil(
        ((new Date() as any) - (new Date(dob) as any)) /
        (1000 * 60 * 60 * 24 * 365)
      );
      if (noOfYears >= min && noOfYears <= max) {
        to_pay_for.amount =
          (policyDetail.policyData.payType.data[0].value / 100) *
          ab.coverageTypeData[0].value;
        //to_pay_for.cd_detuction = ab.coverageTypeData[0].value - (policyDetail.policyData.payType.data[0].value / 100) * ab.coverageTypeData[0].value
      }
    });
  }

  if (policyDetail.policyData.rider_premium_flat.length !== 0) {
    policyDetail.policyData.rider_premium_flat.map((e: any) => {
      e.livesInfoFlat.find((ab: any) => {
        const min = Number(extractNumbersFromString(ab.min)) as number;
        const max = Number(extractNumbersFromString(ab.max)) as number;

        const noOfYears = Math.ceil(
          ((new Date() as any) - (new Date(dob) as any)) /
          (1000 * 60 * 60 * 24 * 365)
        );
        if (noOfYears >= min && noOfYears <= max) {
          raiders_payout_data.push({
            amount: (e.premium_split / 100) * ab.coverageTypeData[0].value,
            name: e.rider_name,
            id: e._id,
          });
        }
      });
    });
  }

  return {
    to_pay_for,
    raiders_payout_data,
    policyId: policyDetail.policyData._id,
  };
};

export const formatSumInsured = (item: any, formatCurrency: Function) => {
  const sumInsured = item;
  if (sumInsured !== "--" && sumInsured !== undefined) {
    if (Array.isArray(sumInsured)) {
      return sumInsured
        .map((si) => formatCurrency(si).replace(/₹/g, "Rs"))
        .join(", ");
    } else {
      return formatCurrency(sumInsured).replace(/₹/g, "Rs");
    }
  }
  return "--";
};

// check integrated tpas
export const isIntegratedTpa = (tpaName: string | null) => {
  if (
    tpaName?.toLowerCase() === TPA_TYPE.PARAMOUNT ||
    tpaName?.toLowerCase() === TPA_TYPE.MEDIASSIST ||
    tpaName?.toLowerCase() === TPA_TYPE.HEALTHINDIA ||
    tpaName?.toLowerCase() === TPA_TYPE.ERICSSON ||
    tpaName?.toLowerCase() === TPA_TYPE.VOLO ||
    tpaName?.toLowerCase() === TPA_TYPE.VIDAL ||
    tpaName?.toLowerCase() === TPA_TYPE.MDINDIA
  ) {
    return true;
  } else {
    return false;
  }
};

export const getStaticNetworkList = () => {
  return staticNetworklist;
};


export const getProductCode = (value: string) => {
  const num =
    value.includes("term")
      ? 0
      : value.includes("health")
        ? 1
        : value.includes("travel")
          ? 2
          : value.includes("accident")
            ? 3
            : value.includes("illness")
              ? 4
              : value.includes("topup")
                ? 5
                : null;
  return num
}
export function isValidDate(dateString: string) {
  // Check if the input is a string
  if (typeof dateString !== "string") {
    return false;
  }

  // Regular expressions to match different date formats
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  const ddMMyyyyRegex = /^\d{2}-\d{2}-\d{4}$/;

  if (iso8601Regex.test(dateString)) {
    // Try to create a Date object from the string
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  } else if (ddMMyyyyRegex.test(dateString)) {
    // Parse DD-MM-YYYY format
    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day); // month is 0-based in JS Date

    // Check if the date is valid
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  // If the string doesn't match any format
  return false;
}

export async function getFileFrombucket(filepath: string, usertype: string) {
  try {
    const body = {
      path: filepath
    }

    const responseData = await new Promise((resolve, reject) => {
      const onSuccess = (res: any) => {
        console.log(res);
        resolve(res.data?.data?.link);  // Resolve the Promise with response data
      };

      const onError = (err: any) => {
        console.log("err", err);
        reject(err);
      };

      GLOBAL_SERVICES.getSignedUrl(onSuccess, onError, body, usertype.toLowerCase());
    });
    if (typeof responseData === "string") {
      return responseData
    } else {
      return null;
    }
  } catch (error) {
    console.log(error)
  }
}

export async function getFileSignedUrl(path: string, usertype: string) {
  const filepath = await getFileFrombucket(path, usertype)
  if (filepath) {
    const bytes = CryptoJS.AES.decrypt(filepath, crypto_key);
    const decrypted: string = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted
  }
  return null
}
 
export const downloadAttachement = async (
  path: string,
  userType: string,
  filename?: string
) => {
  const fileUrl = path?.includes("http")
    ? path
    : await getFileSignedUrl(path, userType);
 
  if (fileUrl) {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
 
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = filename || path.split("/").pop() || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  } else {
    toast.error("Failed to get a valid file URL.");
  }
};

export const allowFileExtention = (file: any) => {
  const allowedExtensions = ["pdf", "csv", "xls", "xlsx", "doc", "docx", "jpeg", "jpg", "png", "gif", "bmp", "webp", "jfif", "svg"];
  const fileExtension = file.name.split('.').pop().toLowerCase();
  if (!allowedExtensions.includes(fileExtension)) {
    return false;
  }
  return true;
};

export const allowFileExtentionZip = (file: any) => {
  const allowedExtensions = ["zip"];
  const fileExtension = file.name.split(".").pop().toLowerCase();
  if (!allowedExtensions.includes(fileExtension)) {
    return false;
  }
  return true;
};
