//used
import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { downloadAttachement } from "../../../../Supporting files/HelpingFunction";
import { useAppSelector } from "../../../../Store/hooks";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data: Array<any>;
}

const ViewDocuments: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
}) => {
  const { userType } = useAppSelector((state) => state.userDetailsSlice);
  console.log("data", data);
  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="sliding-panel"
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3">Documents</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
              }}
            />
          </Grid>
          <Grid xs={12} className="pt-0">
            <hr />
          </Grid>
        </Grid>
        <div>
          {data.length > 0 && data?.map((e: any, index: number) => (
            <Grid
              key={index}
              container
              spacing={2}
              className="mt-4 mb-2"
              alignItems={"center"}
            >
              <Grid xs={9}>
                <p className="fileName_text">
                  {e.docName ||
                    e.doc_name ||
                    e.ecardNumber ||
                    `${e.title} - ${e.subType}`}
                </p>
              </Grid>
              <Grid xs={3} textAlign={"end"}>
                <Link
                  className="blueBtn download ml-0"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                    height: "20px",
                  }}
                  // href={e.downloadLink}
                  onClick={
                    () =>
                      downloadAttachement(e.downloadLink, userType, e?.doc_name)
                    // console.log("e", e)
                  }
                ></Link>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
          ))}
        </div>
        {data.length === 0 && (
          <div style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <span
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
            }}
          >
            You don't have any Document
          </span>
          </div>
        )}      </div>
    </SlidingPanel>
  );
};

export default ViewDocuments;
