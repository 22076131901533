import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import { checkPasswordStrength } from "../../../../../../../Supporting files/HelpingFunction";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  update_api_url?: any;
  employerUserId?: string;
}

const ChangePassword: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  update_api_url,
  employerUserId,
}) => {
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordWarnMessage, setPasswordWarnMessage] = useState("");
  const [passwordCheck, setPasswordCheck] = useState({
    lengthValid: {
      isValid: false,
      message: "",
    },
    hasNumber: {
      isValid: false,
      message: "",
    },
    hasUppercase: {
      isValid: false,
      message: "",
    },
    hasSpecialChar: {
      isValid: false,
      message: "",
    },
    noForbiddenSequence: {
      isValid: false,
      message: "",
    },
    noUsername: {
      isValid: false,
      message: "",
    },
    noMobileNumber: {
      isValid: false,
      message: "",
    },
    passwordsMatch: {
      isValid: false,
      message: "",
    },
    noOldPasswordMatch: {
      isValid: false,
      message: "",
    },
  });
  const [passwordWarn, setPasswordWarn] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [pageStatus, setPageStatus] = useState<number>(1);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    let mainkey = attrName[1];
    if (key === "password") {
      setPassword({ ...password, [mainkey]: value });
      setPasswordWarn({ ...passwordWarn, [mainkey]: false });
    }
  };
  useEffect(() => {
    let report = checkPasswordStrength(
      password.newPassword,
      password.confirmPassword,
      "",
      "",
      password.oldPassword,
      false
    );
    setPasswordCheck(report);
  }, [password]);
  //change password
  // const update_password = () => {
  //   let data: any = passwordWarn;
  //   data.oldPassword = password.oldPassword ? false : true;
  //   data.newPassword = (password.newPassword === password.oldPassword || !password.newPassword) ? true : false;
  //   // data.newPassword = password.newPassword ? false : true;
  //   data.confirmPassword = password.confirmPassword ? false : true;
  //   data.confirmPassword = password.confirmPassword === password.newPassword ? false : true;

  //   setPasswordWarn({ ...data });
  //   if (password.newPassword === password.confirmPassword) {
  //     setPasswordWarnMessage("Please enter confirm password");
  //   } else {
  //     setPasswordWarnMessage("Password and confirm password should be same");
  //   }
  //   if (
  //     data.oldPassword === false &&
  //     data.newPassword === false &&
  //     data.confirmPassword === false &&
  //     password.newPassword === password.confirmPassword
  //   ) {
  //     let params: any = {
  //       old: password.oldPassword,
  //       new: password.newPassword,
  //       confirm: password.confirmPassword,
  //     };

  //     const onSuccess = (res: any) => {
  //       setPageStatus(pageStatus + 1);
  //       setTimeout(() => {
  //         setPageStatus(1);
  //       }, 2000);
  //       setTimeout(() => {
  //         value_update(attrName, false);
  //       }, 2000);
  //       setPassword({
  //         oldPassword: "",
  //         newPassword: "",
  //         confirmPassword: "",
  //       });
  //     };
  //     const onError = (err: any) => {
  //       toast.error(err.response.data.errors[0].message);
  //     };
  //     update_api_url(params, onSuccess, onError);
  //   }
  // };
  const update_password = () => {
    const allValid =
      passwordCheck.lengthValid.isValid &&
      passwordCheck.hasNumber.isValid &&
      passwordCheck.hasUppercase.isValid &&
      passwordCheck.hasSpecialChar.isValid &&
      passwordCheck.noForbiddenSequence.isValid &&
      passwordCheck.noUsername.isValid &&
      passwordCheck.noMobileNumber.isValid &&
      passwordCheck.passwordsMatch.isValid &&
      passwordCheck.noOldPasswordMatch.isValid;
    if (
      allValid
    ) {
      const params = {
        old: password.oldPassword,
        new: password.newPassword,
        // confirm: password.confirmPassword,
      };

      const onSuccess = (res: any) => {
        setPageStatus(pageStatus + 1);
        setTimeout(() => {
          setPageStatus(1);
        }, 2000);
        setTimeout(() => {
          value_update(attrName, false);
        }, 2000);
        setPassword({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      };

      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
      };

      if (typeof update_api_url === "function") {
        update_api_url(onSuccess, onError, params, employerUserId);
      } else {
        console.error("update_api_url function is not provided");
      }
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="slidingPanel"
    >
      <Box className="pt-3 pl-3 pr-3">
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={8}>
              <h4 className="mb-0 text-center">Change Password</h4>
              <Link
                className="close-button"
                onClick={() => {
                  value_update(attrName, false);
                  setPassword({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  });
                }}
              />
            </Grid>
            <Grid xs={4} className="pt-3 text-right">
              <CloseIcon
                onClick={() => {
                  value_update("setChangePassword", false);
                  setPasswordWarnMessage("");
                  setPassword({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  });
                  setPasswordWarn({
                    oldPassword: false,
                    newPassword: false,
                    confirmPassword: false,
                  });
                }}
              />
            </Grid>
            <Grid xs={12} className="mb-5">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {pageStatus !== 2 ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                className="mb-3"
              >
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Old Password"}
                    value={password.oldPassword}
                    attrName={["password", "oldPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.oldPassword}
                    password_status
                    type="password"

                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"New Password"}
                    value={password.newPassword}
                    attrName={["password", "newPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.newPassword}
                    error_messg={
                      password.newPassword?.length > 0 &&
                        password.newPassword === password.oldPassword
                        ? "Old Password & New Password should't be same"
                        : "Enter New Password"
                    }
                    password_status
                    type="password"

                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Confirm Password"}
                    value={password.confirmPassword}
                    attrName={["password", "confirmPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.confirmPassword}
                    error_messg={passwordWarnMessage}
                    password_status
                    type="password"

                  />
                </Grid>
                <Grid xs={12}>
                  <div className="confirmation_content">
                    <ul>
                      <li
                        className={
                          passwordCheck.lengthValid.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        Password must be at least 8 characters long
                      </li>
                      <li
                        className={
                          passwordCheck.hasNumber.isValid &&
                            passwordCheck.hasUppercase.isValid &&
                            passwordCheck.hasSpecialChar.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        Password must contain at least one uppercase letter,
                        number, and special character
                      </li>
                      <li
                        className={
                          passwordCheck.noForbiddenSequence.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        Password should not contain the sequence "12345678"
                      </li>
                      <li
                        className={
                          passwordCheck.noMobileNumber.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        Password should not contain your mobile number
                      </li>
                      <li
                        className={
                          passwordCheck.passwordsMatch.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        Password and Confirm Password must be same
                      </li>

                      <li
                        className={
                          passwordCheck.noOldPasswordMatch.isValid
                            ? "success"
                            : "error"
                        }
                      >
                        New password cannot be the same as the old password
                      </li>

                    </ul>
                  </div>
                </Grid>
              </Grid>
            ) : null}
            {pageStatus === 2 ? (
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid xs={12}>
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <p className="quote_success text-center">
                    Your Password has been added updated successfully!
                  </p>
                </Grid>
              </Grid>
            ) : null}
            {pageStatus !== 2 ? (
              <Grid container spacing={3}>
                <Grid xs={12} className="ctaBtn text-center">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      update_password();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </div>
      </Box>
    </SlidingPanel>
  );
};

export default ChangePassword;
